<template>
  <div class="home-page">
    <!-- <div id="uid-param" v-show="false">{{ $route.params.uid }}</div> -->
    <div class="HomePageTop-nav">
      <div class="nav-left">
        <div class="logo-div">
          <img class="" src="../assets/home_top_logo.png" />
        </div>
        <div
          class="nav-label"
          v-for="(item, index) in navList"
          :class="tabCls(item)"
          :key="index"
          @click="handleChange(index)"
        >
          <div>{{ item.name }}</div>
        </div>
      </div>
      <div class="nav-right" v-show="showRight">
        <div class="icon">
          <img src="../assets/icon_share.png" />
        </div>
        <div class="icon icon-info">
          <img src="../assets/icon_info.png" />
          <!-- <div class="icon-circle"><img src="../assets/icon_circle.png" /></div> -->
        </div>
        <div class="profile">
          <div class="account-modal" v-if="connected" @click="handleShowPopup">
            <div class="account-text">
              {{
                this.account.substring(0, 2) +
                "..." +
                this.account.substring(
                  this.account.length - 4,
                  this.account.length
                )
              }}
            </div>
            <!-- <div class="icon-down"><img src="../assets/top_icon_dowm.png" /></div> -->
            <EntrancePopup
              class="entrance-popup"
              v-show="showPopup"
              :resetApp="resetApp"
            />
          </div>
          <div class="account-modal" @click="handleWalletConnect" v-else>
            <div class="account-text">Connect</div>
          </div>
          <div class="head-photo">
            <img src="../assets/profile.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { ref } from "vue";
import axios from "axios";
import useWallet from "../hooks/useWallet";
import qs from "qs";
import { getBindAddress, isUidInvalid } from "../hooks/nftplatform";
export default {
  name: "HomePageTop",
  data() {
    return {
      showPopup: false,
    };
  },
  props: {
    defaultKey: {
      type: [String, Number],
      default: 1,
    },
    top_type: {
      type: String,
      default: "default",
    },
    connectWallet: {
      type: Function,
      default() {
        return "Default function";
      },
    },
  },
  computed: {
    account() {
      // return this.$store.getters.getAccount;
      return this.$store.getters.getWeb3Value.userAddress;
    },
    connected() {
      return this.$store.getters.getWeb3Value.connected;
    },
  },
  setup(props, context) {
    const { onConnect, connected, userAddress, resetApp } = useWallet();
    let currentKey = ref(props.defaultKey);
    let showRight = ref(true);
    if (props.top_type == "browse") {
      showRight.value = false;
    }
    const navList = [
      {
        name: "Identity",
        key: 1,
      },
      {
        name: "NFT",
        key: 2,
      },
      {
        name: "Wallet",
        key: 3,
      },
      {
        name: "Dapps",
        key: 4,
      },
      {
        name: "Settings",
        key: 5,
      },
    ];
    const routeValue = [
      "nft",
      "dapp",
      "wallet",
      "identity",
      "settings",
      "collections",
      "collection",
      "asset",
    ];
    const router = useRouter();
    const store = useStore();
    let url = document.URL.split(document.domain)[1].split("/")[1];
    // 全数字
    let reg = new RegExp("^[0-9]*$");
    if (reg.test(url)) {
      router.push({ name: "NotFound" });
    }
    const verifyUidParam = async (axiosUrl) => {
      debugger
      await axios
        .get(axiosUrl)
        .then(
          async (response) => {
            debugger
            if (!response.data.errors && response.data !== "") {
              console.log(response.data);
              const uid = response.data.data.id;
              await isUidInvalid("twitter_" + uid).then((res) => {
                debugger;
                res = res.filter((item) => item.status !== "rejected");
                let list = [];
                res.forEach((item) => list.push(item.value));
                let adresses = list.reduce((pre, cur) => {
                  if (!pre.includes(cur)) {
                    return pre.concat(cur);
                  } else {
                    return pre;
                  }
                }, []);
                adresses = adresses.filter(
                  (item) =>
                    item !== "" &&
                    item !== "0x0000000000000000000000000000000000000000"
                );
                if (adresses.length !== 0) {
                  router.push({ name: "BrowseWallet" });
                }
                console.log(`bound uid is ${adresses}`);
              });
              // this.$store.commit("setUidParam", input);
            } else {
              router.push({ name: "NotFound" });
            }
          },
          (error) => {
            console.log(error, axiosUrl);
          }
        )
        .catch((error) => {
          console.log(error);
        });
    };
    let uidParam;
    if (url?.startsWith("twitter_")) {
      debugger;
      uidParam = url.split("_")[1];
      let axiosUrl;
      // const frontUrl = "https://api.twitter.com/2/users/";
      const frontUrl =
        "https://bk9g9xe5x7.execute-api.ca-central-1.amazonaws.com/test/forward/2/users/";
      if (uidParam && reg.test(uidParam)) {
        // axios
        //   .get("https://api.twitter.com/2/users/" + uidParam, {
        //     headers: {
        //       "Authorization":
        //         "Bearer AAAAAAAAAAAAAAAAAAAAACF7bwEAAAAAMX45ml7Q2fww6gC6rIRue7YErYE%3D1Cyal2yhHr1f3cztrHBZuyp0hFiVEaUOaql1gNYeRz8GZQLsma",
        //     },
        axiosUrl = frontUrl + uidParam;
      } else {
        axiosUrl = frontUrl + "/by/username/" + uidParam;
        // uidParam = "@" + uidParam;
      }
      // axios
      //   .post(axiosUrl, qs.stringify({ uidParam }))
      verifyUidParam(axiosUrl);
    } else if (routeValue.indexOf(url) == -1) {
      router.push({ name: "NotFound" });
    }
    store.commit("setUidParam", uidParam);
    console.log("uidParam", uidParam, url);

    const handleChange = (index) => {
      var nav = navList[index];
      var key = nav.key;
      // 更新当前选择的tab
      currentKey = key;
      if (key == 1) {
        if (uidParam) {
          router.push({
            name: "BrowseIdentity",
          });
        } else {
          router.push({
            name: "identity",
          });
        }
      } else if (key == 2) {
        if (uidParam) {
          router.push({
            name: "BrowseHome",
          });
        } else {
          router.push({
            name: "nft",
          });
        }
      } else if (key == 3) {
        if (uidParam) {
          router.push({
            name: "BrowseWallet",
          });
        } else {
          router.push({
            name: "wallet",
          });
        }
      } else if (key == 4) {
        if (uidParam) {
          router.push({
            name: "BrowseDapp",
          });
        } else {
          router.push({
            name: "dapp",
          });
        }
      } else if (key == 5) {
        if (uidParam) {
          router.push({
            name: "BrowseSettings",
          });
        } else {
          router.push({
            name: "settings",
          });
        }
      }
    };
    function tabCls(item) {
      var active = "";
      if (item.key === currentKey.value) {
        active = "nav-label-active";
      }
      return [active];
    }
    const handleWalletConnect = async () => {
      await onConnect();
    };
    return {
      navList,
      handleChange,
      tabCls,
      showRight,
      handleWalletConnect,
      userAddress,
      resetApp,
    };
  },
  watch: {},
  methods: {
    handleShowPopup() {
      if (this.showPopup) {
        this.showPopup = false;
      } else {
        this.showPopup = true;
      }
    },
  },
  mounted() {},
  created() {},
};
</script>

<style lang="scss" scoped>
.home-page {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.07);
  // border: 1px solid yellow;
  .HomePageTop-nav {
    width: 1201px;
    height: 88px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    // border: 1px solid yellow;
    justify-content: space-between;
    .nav-left {
      width: 894px;
      // border: 1px solid green;
      display: flex;
      .logo-div {
        margin: 23px 151px 22px 0;
        display: flex;
        width: 101px;
        height: 43px;
      }
      .nav-label {
        width: 109px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #606060;
        height: 88px;
        text-align: center;
        line-height: 88px;
        // border: 1px solid red;
        cursor: pointer;
      }
      .nav-label-active {
        color: #4100ff;
      }
      .nav-label-active:after {
        content: "";
        height: 3px;
        display: block;
        width: 60px;
        background: #4100ff;
        margin: 0 24.5px;
      }
    }
    .nav-right {
      // border: 1px solid black;
      width: 250px;
      display: flex;
      .icon {
        margin: 30px 15px 31px 0;
        cursor: pointer;
        img {
          width: 30px;
          height: 27px;
        }
      }
      .icon-info {
        position: relative;
        cursor: pointer;
        width: 30px;
        height: 27px;
        .icon-circle {
          top: 0;
          left: 16px;
          position: absolute;
          cursor: pointer;
          img {
            width: 14px;
            height: 14px;
          }
        }
      }
      .profile {
        cursor: pointer;
        display: flex;
        .account-modal {
          width: 110px;
          height: 36px;
          border-radius: 12px;
          border: 2px solid #9d9d9d;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #62517e;
          margin: 24px 0 24px 0px;
          padding: 0px 0px 0 21px;
          position: relative;

          .account-text {
            width: 71px;
            height: 22px;
            line-height: 22px;
            // overflow: hidden;
            // text-overflow: ellipsis;
            white-space: nowrap;
            margin: 7px 0;
          }
          .icon-down {
            position: absolute;
            top: 5px;
            left: 136px;
            img {
              width: 7px;
              height: 5px;
            }
          }
        }
        .head-photo {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          margin: 20px 0 20px -20px;
          cursor: pointer;
          z-index: 99;
          img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .entrance-popup {
    position: absolute;
    top: 62px;
    left: 0;
    z-index: 99999;
  }
}
</style>