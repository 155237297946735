
//计算两个时间之间的时间差 多少天时分秒
export const intervalTime = (startTime, endTime) => {
    // var timestamp = new Date().getTime(); //计算当前时间戳(毫秒级)
    var timestamp = (Date.parse(new Date())) / 1000;//计算当前时间戳 (秒级)
    var date1 = startTime; //开始时间
    var date2 = timestamp; //结束时间
    // var date3 = date2.getTime() - date1.getTime(); //时间差的毫秒数
    //时间差的毫秒数
    var date3 = (date2 - date1) * 1000;
    //计算出相差天数
    var days = Math.floor(date3 / (24 * 3600 * 1000));
    if (days) {
        return days + " days"
    }
    //计算出小时数
    //计算天数后剩余的毫秒数
    var leave1 = date3 % (24 * 3600 * 1000);
    var hours = Math.floor(leave1 / (3600 * 1000));
    if (hours) {
        return hours + " hours"
    }
    //计算相差分钟数
    //计算小时数后剩余的毫秒数
    var leave2 = leave1 % (3600 * 1000);
    var minutes = Math.floor(leave2 / (60 * 1000));
    if (minutes) {
        return minutes + " minutes"
    }
    //计算相差秒数
    //计算分钟数后剩余的毫秒数
    var leave3 = leave2 % (60 * 1000);
    var seconds = Math.round(leave3 / 1000);
    if (seconds) {
        return seconds + " seconds"
    }
    return date3 + " milliseconds";
    // return days + "天 " + hours + "小时 " + minutes + " 分钟" + seconds + " 秒"

}