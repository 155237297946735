const supportedChains = [
    {
        order: 0,
        isConnect: false,
        name: 'Binance Smart Chain Testnet',
        short_name: 'bsc',
        moralis_name: "bsc testnet",
        chain: 'smartchain',
        network: 'testnet',
        chain_id: 97,
        network_id: 97,
        // rpc_url: 'https://data-seed-prebsc-1-s1.binance.org:8545',
        rpclist: [
            "https://data-seed-prebsc-1-s1.binance.org:8545/",
            "https://data-seed-prebsc-2-s1.binance.org:8545/",
            // "http://data-seed-prebsc-1-s2.binance.org:8545/",
            // "http://data-seed-prebsc-2-s2.binance.org:8545/",
            "https://data-seed-prebsc-1-s3.binance.org:8545/",
            "https://data-seed-prebsc-2-s3.binance.org:8545/"
        ],
        explore_url: "https://testnet.bscscan.com/",
        short_icon: "",
        big_icon: "https://img1.imgtp.com/2022/05/11/PfASGxq4.png",
        native_currency: {
            symbol: 'tBNB',
            name: 'BNB Chain',
            decimals: '18',
            contractAddress: '0x93B0CD88AE34CA7a0759Ea0f774bcaa6E8e09051',
            balance: '',
        },
    },
    {
        order: 1,
        isConnect: false,
        name: 'Mumbai',
        short_name: 'Mumbai',
        moralis_name: "mumbai",
        chain: 'Mumbai',
        network: 'testnet',
        chain_id: 80001,
        network_id: 80001,
        rpclist: ['https://matic-mumbai.chainstacklabs.com'],
        explore_url: "https://mumbai.polygonscan.com/",
        short_icon: "",
        big_icon: "https://img1.imgtp.com/2022/05/11/jyPDjwKo.png",
        native_currency: {
            symbol: 'MATIC',
            name: 'Polygon',
            decimals: '18',
            contractAddress: '0xFeA17665a35288AA14b51e8BF93bA8e3910507cF',
            balance: '',
        },
    },
    {
        order: 2,
        isConnect: false,
        name: 'Avalanche Fuji Testnet',
        short_name: 'Mumbai',
        moralis_name: "avalanche testnet",
        chain: 'Mumbai',
        network: 'testnet',
        chain_id: 43113,
        network_id: 43113,
        rpclist: ['https://api.avax-test.network/ext/bc/C/rpc'],
        explore_url: "https://testnet.snowtrace.io/",
        short_icon: "",
        big_icon: "https://img1.imgtp.com/2022/05/11/4qReLqQq.jpg",
        native_currency: {
            symbol: 'AVAX',
            name: 'Avalanche',
            decimals: '18',
            contractAddress: '0x76A1Cf9d47De661A6fc661F6fd6dF2041B59077a',
            balance: '',
        },
    },
    {
        order: 3,
        isConnect: false,
        name: 'Ethereum Görli',
        short_name: 'gor',
        moralis_name: "goerli",
        chain: 'ETH',
        network: 'goerli',
        chain_id: 5,
        network_id: 5,
        rpclist: ['https://goerli.infura.io/v3/fa805045d27e475cabb9a10ddd409ce8'],
        explore_url: "https://goerli.etherscan.io/",
        short_icon: "",
        big_icon: "https://img1.imgtp.com/2022/05/11/NMTMM0pk.png",
        native_currency: {
            symbol: 'ETH',
            name: 'Ethereum',
            decimals: '18',
            contractAddress: '0xA952777140E1fAe3A39DA359aAEE60522B419B35',
            balance: '',
        },
    },

]

export default supportedChains;