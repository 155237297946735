<template>
  <div class="item-list">
    <div class="item-card" v-for="(item, index) in list" :key="index">
      <div class="item-img">
        <img :src="item.token_uri" onerror="../assets/img_Placeholder.png" />
      </div>
      <div class="item-name-id">
        <div class="item-name">{{ item.token_name }}</div>
        <div class="item-id">#{{ item.token_id }}</div>
      </div>
      <div class="item-price">
        <div class="item-price-span">Price</div>
        <div class="price-eth-icon">
          <img src="../assets/icon_eth.png" />
        </div>
        <div class="item-price-value">11.02</div>
        <div class="item-eth-text">ETH</div>
      </div>
      <div class="item-footer">
        <div class="item-footer-eth">
          <img :src="chainIcon" />
        </div>
        <div class="item-dot-div" @click="handleShowRemoveButton(index)">
          <img src="../assets/icon_more.png" />
          <div class="item-dot-modal" v-show="item.showRemoveButton">
            Remove
          </div>
          <!-- <div class="item-dot"></div>
                    <div class="item-dot"></div>
                    <div class="item-dot"></div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { signMessage } from "../util/sign";
import axios from "axios";
export default {
  name: "NFTList",
  data() {
    return {
      chains: {
        80001: {
          icon: require("../assets/matic.jpg"),
        },
        43113: {
          icon: require("../assets/avax.jpg"),
        },
        97: {
          icon: require("../assets/bnb.jpg"),
        },
        5: {
          icon: require("../assets/logo_eth19x19@2x.png"),
        },
      },
    };
  },
  computed: {
    chainIcon() {
      return this.chains[this.list ? this.list[0].block_chain : 97].icon;
    },
  },
  props: {
    type: {
      type: String,
      default: "default",
    },
    account: String,
    list: Array,
    showRemove: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async removeData(id) {
      const signature = await signMessage(" remove item " + id);
      console.log(signature);
      if (!signature) return;
      const url =
        "https://nd9gajq3lj.execute-api.us-east-1.amazonaws.com/star/collectionItems/remove/";
      await axios.get(url + id).then(
        (res) => {
          debugger;
          if (res.data.statusCode == 200) {
            location.replace(location);
          }
        },
        (err) => console.log(err)
      );
    },
    handleShowRemoveButton(index) {
      // debugger;
      if (this.list[index].showRemoveButton) {
        debugger;
        this.removeData(this.list[index].collection_item_id);
        this.list[index].showRemoveButton = false;
      } else {
        this.list[index].showRemoveButton = true;
      }
    },
  },
  mounted() {
    if (this.type == "showcase") {
    } else if (this.type == "collected") {
    }
  },
};
</script>

<style lang="scss" scoped>
.item-list {
  position: relative;
  // border: 1px solid red;
  .item-card {
    display: inline-block;
    width: 156px;
    height: 225px;
    background: #ffffff;
    border-radius: 17px;
    border: 1px solid #dedede;
    margin: 8px 8px;
    // border: 1px solid red;
    .item-img img {
      width: 156px;
      height: 157px;
      border-radius: 17px 17px 0px 0px;
      object-fit: contain;
    }
    .item-name-id {
      margin: 0px 0 0px 5px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      height: 20px;
      line-height: 20px;
      display: flex;
      .item-name {
        // border: 1px solid red;
        // width: 155px;
        width: 83px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .item-id {
        //   width: 45px;
        width: 63px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .item-price {
      // border: 1px solid red;
      display: flex;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 17px;
      padding-left: 5px;
      padding-right: 2px;
      border-bottom: 1px solid rgba(222, 222, 222, 0.32);
      .item-price-span {
        margin-right: 55px;
      }
      .price-eth-icon img {
        width: 7px;
        height: 10px;
        object-fit: contain;
      }
      .item-price-value {
        width: 58px;
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #666666;
        line-height: 17px;
        margin-left: 2px;
      }
      .item-price-text {
        margin-right: 2px;
      }
    }
    .item-footer {
      display: flex;
      justify-content: space-between;
      padding: 0 8px 0 12px;
      // border: 1px solid red;
      .item-footer-eth img {
        margin-top: 6px;
        width: 13px;
        height: 13px;
      }
      .item-dot-div {
        display: flex;
        margin-top: 10px;
        cursor: pointer;
        position: relative;
        width: 15px;
        height: 4px;
        .item-dot-modal {
          position: absolute;
          right: -4px;
          bottom: 13px;
          width: 81px;
          height: 23px;
          background: #ffffff;
          box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
          border-radius: 6px;
          line-height: 23px;
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          padding-left: 15px;
        }
        .item-dot {
          margin-left: 3px;
          width: 3px;
          height: 3px;
          background: #a6a6a6;
        }
      }
    }
  }
  .add-showcase-button {
    position: fixed;
    right: 5%;
    bottom: 5%;
    width: 54px;
    height: 54px;
    background: #ffffff;
    box-shadow: 0px 4px 11px 0px rgba(101, 48, 255, 0.28);
    border-radius: 50%;
    cursor: pointer;
    .add-showcase-button-img {
      // width: 28.8px;
      // height: 28.8px;
      position: absolute;
      top: 25%;
      left: 25%;
      img {
        width: 28.8px;
        height: 28.8px;
      }
    }
    .add-showcase-button-after {
      position: absolute;
      top: 0;
      right: 0;
      width: 70px;
      height: 54px;
      background: #6530ff;
      border-radius: 28px;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 54px;
      padding-left: 58.8px;
      transition: all 1s linear;
      .add-showcase-button-after-img {
        width: 28.8px;
        height: 28.8px;
        position: absolute;
        top: 13%;
        left: 25px;
      }
      .add-showcase-button-after-img img {
        width: 28.8px;
        height: 28.8px;
      }
    }
  }
}
</style>