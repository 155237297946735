import { getCollectedList } from "../util/moralis.js";
import axios from "axios";
export const getHandledCollectedList = async (account) => {
    /* eslint-disable */
    // debugger
    let collectedList;
    const data = await getCollectedList(account);
    /* eslint-disable */
    // debugger
    let list;
    for (let index in data) {
        if (data[index].total !== 0) {
            if (list) {
                let datalist = data[index].result;
                for (let i in datalist) {
                    if (datalist[i].token_uri || datalist[i].metadata) {
                        datalist[i].isChecked = false
                        list.push(datalist[i]);
                    }
                }
            } else {
                list = [...data[index].result];
                for (let i = 0; i < list.length; i++) {
                    list[i].isChecked = false;
                    if (!list[i].token_uri && !list[i].metadata) {
                        list.splice(i, 1);
                        i -= 1;
                    }
                }
            }
        }
    }
    await Promise.all(
        list.map(async (i) => {
            if (i.metadata) {
                const json = JSON.parse(i.metadata);
                if (json.image) {
                    i.token_uri = json.image;
                } else if (json.image_url) {
                    i.token_uri = json.image_url;
                } else if (json.animation_url) {
                    i.token_uri = json.animation_url;
                }
            } else {
                if (i.token_uri && i.token_uri.includes("0.json")) {
                    //   $.ajax({
                    //     url: "https://pop-art-cats.s3.us-east-2.amazonaws.com/pre-reveal/gift.json",
                    //     type: "GET",
                    //     success: function (res) {
                    //       console.log(res);
                    //       // eslint-disable-next-line
                    //       debugger;
                    //     },
                    //   });
                    i.token_uri =
                        "https://www.thehighapesclub.com/assets/nft/worldpass/1.gif";
                }
                await axios
                    .get(i.token_uri)
                    .then((res) => {
                        if (res.data.image) {
                            i.token_uri = res.data.image;
                        } else if (res.data.image_url) {
                            i.token_uri = res.data.image_url;
                        } else if (res.data.animation_url) {
                            i.token_uri = res.data.animation_url;
                        }
                    })
                    .catch((e) => {
                        console.log("请求图片error", e.message);
                    });
            }

            if (i.token_uri && i.token_uri.includes("ipfs")) {
                let n = i.token_uri.indexOf("ipfs");
                if (n === 0) {
                    i.token_uri =
                        "https://ipfs.io/" + i.token_uri.replace("://", "/");
                    let index = i.token_uri.charAt(i.token_uri.length - 1);
                    if (index === "/") {
                        i.token_uri = i.token_uri.substring(0, i.token_uri.length - 1);
                    }
                }
            }
        })
    );

    for (let i = 0; i < list.length; i++) {
        if (list[i].token_uri && list[i].token_address) {
            if (
                (!list[i].metadata && list[i].token_uri.includes(".json")) ||
                list[i].token_address ==
                "0x28472a58a490c5e09a238847f66a68a47cc76f0f" ||
                list[i].token_address ==
                "0xda22422592ee3623c8d3c40fe0059cdecf30ca79"
            ) {
                list.splice(i, 1);
                i -= 1;
            }
        }
    }
    //   for (let i = 0; i < list.length; i++) {
    //     list.splice(i, 1);
    //     i -= 1;
    //     if (list.length <= 6) {
    //       break;
    //     }
    //   }
    collectedList = list.reverse();
    console.log("collectedList", collectedList);
    return collectedList;
}