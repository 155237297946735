<template>
  <div class="item-panel">
    <div class="item-amount-text">{{ showcaseLength }} Items</div>
    <div class="item-list">
      <div class="item-card" v-for="(item, index) in showcaseList" :key="index">
        <div class="item-img">
          <img :src="item.token_uri" onerror="../assets/img_Placeholder.png" />
        </div>
        <div class="item-name-id">
          <div class="item-name">{{ item.token_name }}</div>
          <div class="item-id">#{{ item.token_id }}</div>
        </div>
        <div class="item-price">
          <div class="item-price-span">Price</div>
          <div class="price-eth-icon">
            <img src="../assets/icon_eth.png" />
          </div>
          <div class="item-price-value">11.02</div>
          <div class="item-eth-text">ETH</div>
        </div>
        <div class="item-footer">
          <div class="item-footer-eth">
            <img src="../assets/icon_eth_big.png" />
          </div>
          <div class="item-dot-div" @click="handleShowRemoveButton(index)">
            <img src="../assets/icon_more.png" />
            <div class="item-dot-modal" v-show="item.showRemoveButton">
              Remove
            </div>
            <!-- <div class="item-dot"></div>
                    <div class="item-dot"></div>
                    <div class="item-dot"></div> -->
          </div>
        </div>
      </div>

      <div
        v-show="showAddButton"
        class="add-showcase-button"
        @mouseover="handleShowAddShowcaseButton"
        @mouseout="hideAddShowcaseButton"
      >
        <div class="add-showcase-button-img">
          <img src="../assets/icon_add_n.png" />
        </div>
        <div
          v-show="showAddShowcaseButton"
          class="add-showcase-button-after"
          @click="handleShowPreviewModal"
        >
          Add
          <div class="add-showcase-button-after-img">
            <img src="../assets/icon_add_s.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import md5 from "blueimp-md5/js/md5.min.js";
export default {
  name: "Showcase",
  data() {
    return {
      // account: "0x6B38425037f8b127FC4300DDeCdbC189d21FF6f3".toLowerCase(),
      showcaseLength: 0,
      showcaseList: [],
      showAddShowcaseButton: false,
      showAddButton: !this.$props.hideAddButton,
    };
  },
  props: {
    hideAddButton: {
      type: Boolean,
      defalut: false,
    },
  },
  watch: {
    account(val) {
      this.handleShowcaseList(val);
    },
  },
  computed: {
    account() {
      return this.$store.getters.getWeb3Value.userAddress;
    },
  },
  methods: {
    getMetaMaskAccount() {
      console.log("account", this.account);
      if (this.account.length === 0) {
        // MetaMask is locked or the user has not connected any accounts
        console.log("Please connect to MetaMask.");
      } else {
        this.handleShowcaseList(this.account);
      }
    },
    async handleShowcaseList(account) {
      debugger;
      await axios
        .get(
          "https://r5uyhu7s5c.execute-api.us-east-1.amazonaws.com/test/showcase/list/" +
            account
        )
        .then((res) => {
          this.showcaseList = [...res.data.Items];
          /* eslint-disable */
          console.log("showcaselist", md5(this.showcaseList + "add"));
          this.showcaseLength = this.showcaseList.length;
          this.showcaseList = this.showcaseList.sort(
            (a, b) => a.order - b.order
          );
        })
        .catch((e) => {
          console.log(e);
        });
    },
    handleShowRemoveButton(index) {
      if (this.showcaseList[index].showRemoveButton) {
        this.showcaseList[index].showRemoveButton = false;
      } else {
        this.showcaseList[index].showRemoveButton = true;
      }
    },
    handleShowAddShowcaseButton() {
      this.showAddShowcaseButton = true;
    },
    hideAddShowcaseButton() {
      this.showAddShowcaseButton = false;
    },
    handleShowPreviewModal() {
      const showPreviewModal = this.$store.getters.getShowPreviewModal;
      if (showPreviewModal) {
        this.$store.commit("handlePreviewModal", false);
      } else {
        this.$store.commit("handlePreviewModal", true);
      }
    },
  },
  mounted() {
    this.getMetaMaskAccount();
    console.log("this.props.hideAddButton", this);
  },
};
</script>

<style lang="scss" scoped>
.item-panel {
  // border: 1px solid black;
  .item-amount-text {
    height: 25px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 25px;
    margin-bottom: 4px;
    margin-left: 8px;
  }
  .item-list {
    position: relative;
    // border: 1px solid red;
    .item-card {
      display: inline-block;
      width: 156px;
      height: 225px;
      background: #ffffff;
      border-radius: 17px;
      border: 1px solid #dedede;
      margin: 8px 8px;
      .item-img img {
        width: 156px;
        height: 157px;
        border-radius: 17px 17px 0px 0px;
        object-fit: contain;
      }
      .item-name-id {
        margin: 0px 0 0px 5px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        height: 20px;
        line-height: 20px;
        display: flex;
        .item-name {
          // border: 1px solid red;
          // width: 155px;
          width: 83px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .item-id {
          //   width: 45px;
          width: 63px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .item-price {
        // border: 1px solid red;
        display: flex;
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 17px;
        padding-left: 5px;
        padding-right: 2px;
        border-bottom: 1px solid rgba(222, 222, 222, 0.32);
        .item-price-span {
          margin-right: 55px;
        }
        .price-eth-icon img {
          width: 7px;
          height: 10px;
          object-fit: contain;
        }
        .item-price-value {
          width: 58px;
          height: 17px;
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #666666;
          line-height: 17px;
          margin-left: 2px;
        }
        .item-price-text {
          margin-right: 2px;
        }
      }
      .item-footer {
        display: flex;
        justify-content: space-between;
        padding: 0 8px 0 12px;
        // border: 1px solid red;
        .item-footer-eth img {
          margin-top: 6px;
          width: 8px;
          height: 12px;
        }
        .item-dot-div {
          display: flex;
          margin-top: 10px;
          cursor: pointer;
          position: relative;
          width: 15px;
          height: 4px;
          .item-dot-modal {
            position: absolute;
            right: -4px;
            bottom: 13px;
            width: 81px;
            height: 23px;
            background: #ffffff;
            box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
            border-radius: 6px;
            line-height: 23px;
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            padding-left: 15px;
          }
          .item-dot {
            margin-left: 3px;
            width: 3px;
            height: 3px;
            background: #a6a6a6;
          }
        }
      }
    }
    .add-showcase-button {
      position: fixed;
      right: 5%;
      bottom: 5%;
      width: 54px;
      height: 54px;
      background: #ffffff;
      box-shadow: 0px 4px 11px 0px rgba(101, 48, 255, 0.28);
      border-radius: 50%;
      cursor: pointer;
      .add-showcase-button-img {
        // width: 28.8px;
        // height: 28.8px;
        position: absolute;
        top: 25%;
        left: 25%;
        img {
          width: 28.8px;
          height: 28.8px;
        }
      }
      .add-showcase-button-after {
        position: absolute;
        top: 0;
        right: 0;
        width: 70px;
        height: 54px;
        background: #6530ff;
        border-radius: 28px;
        font-size: 22px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 54px;
        padding-left: 58.8px;
        transition: all 1s linear;
        .add-showcase-button-after-img {
          width: 28.8px;
          height: 28.8px;
          position: absolute;
          top: 13%;
          left: 25px;
        }
        .add-showcase-button-after-img img {
          width: 28.8px;
          height: 28.8px;
        }
      }
    }
  }
}
</style>