<template>
  <div class="main2 flex-col">
    <div class="group1 flex-col">
      <div class="mod1 flex-row">
        <div class="section2 flex-col"></div>
        <div class="section3 flex-col">
          <div class="section4 flex-col"></div>
        </div>
        <div class="section5 flex-col justify-between">
          <div class="outer1 flex-col">
            <div class="layer1 flex-col"></div>
            <div class="layer2 flex-col"></div>
          </div>
          <div class="outer2 flex-col"></div>
        </div>
        <div class="section6 flex-col">
          <div class="section7 flex-col"></div>
        </div>
        <div class="section8 flex-col"></div>
      </div>
      <div class="ImageText1 flex-col">
        <div class="wrap1 flex-col justify-between">
          <div class="outer3 flex-col"></div>
          <div class="TextGroup1 flex-col">
            <span class="info1"
              >Uh&nbsp;oh！Page&nbsp;does&nbsp;not&nbsp;exist</span
            >
          </div>
        </div>
      </div>
      <div class="mod2 flex-col"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style lang="scss" scoped>
.page {
  position: relative;
  width: 1920px;
  height: 1080px;
  overflow: hidden;
}

.bd1 {
  height: 1080px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge28c2c4da8e23871b7ac8d1f0184686c336d7d875d18b46f55a967cf541d88e7)
    100% no-repeat;
  width: 1920px;
}

.section1 {
  width: 1919px;
  height: 1036px;
  margin: 44px 0 0 1px;
}

.main1 {
  background-color: rgba(255, 255, 255, 1);
  height: 15px;
  margin-left: 1534px;
  width: 19px;
}

.label1 {
  width: 14px;
  height: 14px;
  margin: 1px 0 0 1px;
}

.main2 {
  background-color: rgba(255, 255, 255, 1);
  height: 985px;
  margin-top: 36px;
  width: 1919px;
}

.group1 {
  width: 518px;
  height: 370px;
  margin: 74px 0 0 694px;
}

.mod1 {
  width: 518px;
  height: 154px;
  margin-top: 88px;
}

.section2 {
  width: 88px;
  height: 89px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng36ed8a8c35c434850e5fbd3ed957a5b29b8b6ace3fbc17cf494810860b1fb68e)
    100% no-repeat;
  margin-top: 7px;
}

.section3 {
  height: 115px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng202a59c0ed47d4e89e0387955609410a2e285ca836b8e7310e52f76dfbca10f2) -1px -1px
    no-repeat;
  width: 95px;
  margin: 4px 0 0 27px;
}

.section4 {
  width: 92px;
  height: 111px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng19eb8bc8b48aa62409deb0a8a10967f2a6ba4d5b74003d3a85463a630079d738)
    0px -1px no-repeat;
  margin: 1px 0 0 -1px;
}

.section5 {
  width: 69px;
  height: 94px;
  margin: 8px 0 0 18px;
}

.outer1 {
  background-color: rgba(226, 228, 237, 1);
  border-radius: 50%;
  z-index: 24;
  position: relative;
  width: 69px;
  height: 69px;
}

.layer1 {
  z-index: 25;
  position: absolute;
  left: 67px;
  top: -1px;
  width: 11px;
  height: 11px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngd4b374c0cce4cf1792879f950cabc8d8b7aa73298a4ce3e0fa7fe087fedcb989) -1px -1px
    no-repeat;
}

.layer2 {
  z-index: 26;
  position: absolute;
  left: -11px;
  top: 25px;
  width: 91px;
  height: 30px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngddac7ca472651c76c79d42f493e100386354aa775f8bd16c99b0fa4ddcd21a48) -1px -2px
    no-repeat;
}

.outer2 {
  width: 45px;
  height: 8px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng3631a03daa98d6b401c2d21ea261b902b024ac2f3696c54329e278457c7e579d)
    0px -1px no-repeat;
  margin: 17px 0 0 12px;
}

.section6 {
  height: 109px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng3f760ce1d9fca4f62f2108557e57d0b674bc6788fdb1c207548c4693d2b73bcf)
    0px -1px no-repeat;
  margin-left: 15px;
  width: 92px;
}

.section7 {
  width: 88px;
  height: 108px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngd10789ed24334809d208f2ab7249105325e529ea4fbc233856556b8520b4e8aa) -1px -1px
    no-repeat;
  margin: -2px 0 0 1px;
}

.section8 {
  width: 88px;
  height: 88px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga851bd193fdb402858db6f7f7d7c2003769e773a916ee40463fb071fbaad7246)
    100% no-repeat;
  margin: 66px 0 0 26px;
}

.ImageText1 {
  height: 147px;
  width: 478px;
  margin: -19px 0 0 26px;
}

.wrap1 {
  width: 478px;
  height: 147px;
}

.outer3 {
  width: 478px;
  height: 103px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng8de5236d71754f2b8507ee347d95d31de2a116e4d78321d6620dca41816c29e4)
    0px -1px no-repeat;
}

.TextGroup1 {
  height: 40px;
  width: 371px;
  margin: 4px 0 0 55px;
}

.info1 {
  width: 371px;
  height: 40px;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 28px;
  font-family: PingFangSC-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 40px;
  display: block;
}

.mod2 {
  width: 88px;
  height: 89px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng76efee95113b9d10a18dfae23160b6e3780890f23cbc1deafc37961c4cbf3e34)
    100% no-repeat;
  margin: -370px 0 281px 402px;
}

body * {
  box-sizing: border-box;
  flex-shrink: 0;
}
body {
  font-family: PingFangSC-Regular, Roboto, Helvetica Neue, Helvetica, Tahoma,
    Arial, PingFang SC-Light, Microsoft YaHei;
}
button {
  margin: 0;
  padding: 0;
  border: 1px solid transparent;
  outline: none;
  background-color: transparent;
}

button:active {
  opacity: 0.6;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.justify-start {
  display: flex;
  justify-content: flex-start;
}
.justify-center {
  display: flex;
  justify-content: center;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}
.justify-evenly {
  display: flex;
  justify-content: space-evenly;
}
.justify-around {
  display: flex;
  justify-content: space-around;
}
.justify-between {
  display: flex;
  justify-content: space-between;
}
.align-start {
  display: flex;
  align-items: flex-start;
}
.align-center {
  display: flex;
  align-items: center;
}
.align-end {
  display: flex;
  align-items: flex-end;
}
</style>