<template >
  <div class="preview-modal-mask" v-show="showModal">
    <div class="preview-modal-wrapper">
      <div class="preview-modal">
        <ShowcaseModal
          :enableSaveBtn="enableSaveBtn"
          :showDragText="showDragText"
          @save="saveShowcase"
          @close="closeModal"
        >
          <template #content-title> Showcase </template>
          <template #content-icon>
            <img
              class="content-icon"
              src="../assets/preview_btn_list.png"
              @click="handleCheckModal"
              v-show="showEditBtn"
            />
            <div class="select-all" v-show="showSelectAllCheck">
              <div
                class="select-all-check-modal"
                :class="computedClass"
                @click="handleSelectCheck"
              ></div>
              <div class="select-all-text">Select all</div>
              <div class="select-all-delete" @click="removeItem">
                <img src="../assets/preview_btn_del.png" />
              </div>
              <div
                class="select-all-check-icon"
                v-show="showSelectAllCheckIcon"
                @click="handleSelectCheck"
              >
                <img src="../assets/icon_cancel_big.png" />
              </div>
            </div>
          </template>
          <template #content-container>
            <div
              class="nft-card"
              v-for="(item, index) in showcaseList"
              :key="index"
              v-show="showSelectAllCheck"
            >
              <div
                class="nft-img"
                :class="computedClass"
                @click="handleCheckIcon(index)"
              >
                <img :src="item.token_uri" />
              </div>
              <div
                class="nft-card-check-modal"
                :class="computedClass"
                v-show="showCheckModal"
                @click="handleCheckIcon(index)"
              ></div>
              <div
                class="check-icon"
                :class="computedClass"
                v-show="item.isChecked"
                @click="handleCheckIcon(index)"
              >
                <img src="../assets/icon_cancel.png" />
              </div>
            </div>

            <div class="row" v-show="showEditBtn">
              <div class="col-6">
                <draggable
                  class="list-group"
                  item-key="order"
                  tag="transition-group"
                  :component-data="{
                    tag: 'div',
                    name: 'flip-list',
                    type: 'transition',
                  }"
                  v-model="this.showcaseList"
                  v-bind="dragOptions"
                  @start="isDragging = true"
                  @end="isDragging = false"
                >
                  <template #item="{ element }">
                    <div class="draggable-card">
                      <img :src="element.token_uri" class="nft-img" />
                    </div>
                  </template>
                </draggable>
              </div>
            </div>
          </template>
        </ShowcaseModal>
        <ShowcaseModal
          classType="collected"
          width="496"
          :showModalHead="false"
          :showSearchBox="true"
          :showDragInfo="false"
          :showFooterBtn="false"
          :isOverflow="isOverflow"
          :showLeftBtn="true"
          @leftMove="addShowcase"
        >
          <template v-slot:content-title>Your items</template>
          <template #content-icon>
            <div class="select-all colleced">
              <div
                class="select-all-check-modal"
                @click="handleCollectedSelectCheck"
              ></div>
              <div class="select-all-text">Select all</div>
              <div
                class="select-all-check-icon"
                v-show="showSelectCollectedAllCheckIcon"
                @click="handleCollectedSelectCheck"
              >
                <img src="../assets/icon_cancel_big.png" />
              </div>
            </div>
          </template>
          <template #content-container>
            <div>
              <div
                class="nft-card"
                v-for="(item, index) in collectedList"
                :key="index"
              >
                <!-- <video width="66" height="66" controls v-show="item.isVideo">
                <source :src="item.token_uri" type="video/mp4" />
              </video> -->
                <div class="nft-img" @click="handleCollectedCheckIcon(index)">
                  <img :src="item.token_uri" />
                </div>
                <div
                  class="nft-card-check-modal"
                  @click="handleCollectedCheckIcon(index)"
                ></div>
                <div
                  class="check-icon"
                  v-show="item.isChecked"
                  @click="handleCollectedCheckIcon(index)"
                >
                  <img src="../assets/icon_cancel.png" />
                </div>
              </div>
            </div>
          </template>
        </ShowcaseModal>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { getCollectedList } from "../util/moralis.js";
import { signMessage, verifyMessage } from "../util/sign.js";
import md5 from "blueimp-md5/js/md5.min.js";
import axios from "axios";
// import $ from "jquery";
export default {
  name: "HandlePreviewModal",
  components: {
    draggable,
  },
  data() {
    return {
      showCheckModal: false,
      showCheckIcon: false,
      showEditBtn: true,
      showSelectAllCheck: false,
      showSelectAllCheckIcon: false,
      showSelectCollectedAllCheckIcon: false,
      isOverflow: 0,
      enableSaveBtn: false,
      collectedList: [],
      showcaseList: [],
      order: 0,
      removeCheckedList: [],
      addCheckedList: [],
      account: "0x6B38425037f8b127FC4300DDeCdbC189d21FF6f3".toLowerCase(),
      isSort: false,
      count: 0,
      showDragText: true,
      showModal: true,
      currentAccount: "",
    };
  },
  methods: {
    async handleCollectedList(account) {
      /* eslint-disable */
      // debugger;
      const data = await getCollectedList(account);
      let list;
      for (let index in data) {
        if (data[index].total !== 0) {
          if (list) {
            let datalist = data[index].result;
            for (let i in datalist) {
              if (datalist[i].token_uri || datalist[i].metadata) {
                list.push(datalist[i]);
              }
            }
          } else {
            list = [...data[index].result];
            for (let i = 0; i < list.length; i++) {
              list[i].isChecked = false;
              if (!list[i].token_uri && !list[i].metadata) {
                list.splice(i, 1);
                i -= 1;
              }
            }
          }
        }
      }
      await Promise.all(
        list.map(async (i) => {
          if (i.metadata) {
            const json = JSON.parse(i.metadata);
            if (json.image) {
              i.token_uri = json.image;
            } else if (json.image_url) {
              i.token_uri = json.image_url;
            } else if (json.animation_url) {
              i.token_uri = json.animation_url;
            }
          } else {
            if (i.token_uri && i.token_uri.includes("0.json")) {
              //   $.ajax({
              //     url: "https://pop-art-cats.s3.us-east-2.amazonaws.com/pre-reveal/gift.json",
              //     type: "GET",
              //     success: function (res) {
              //       console.log(res);
              //       // eslint-disable-next-line
              //       debugger;
              //     },
              //   });
              i.token_uri =
                "https://www.thehighapesclub.com/assets/nft/worldpass/1.gif";
            }
            await axios
              .get(i.token_uri)
              .then((res) => {
                if (res.data.image) {
                  i.token_uri = res.data.image;
                } else if (res.data.image_url) {
                  i.token_uri = res.data.image_url;
                } else if (res.data.animation_url) {
                  i.token_uri = res.data.animation_url;
                }
              })
              .catch((e) => {
                console.log("请求图片error", e.message);
              });
          }

          if (i.token_uri && i.token_uri.includes("ipfs")) {
            let n = i.token_uri.indexOf("ipfs");
            if (n === 0) {
              i.token_uri =
                "https://ipfs.io/" + i.token_uri.replace("://", "/");
              let index = i.token_uri.charAt(i.token_uri.length - 1);
              if (index === "/") {
                i.token_uri = i.token_uri.substring(0, i.token_uri.length - 1);
              }
            }
          }
        })
      );

      for (let i = 0; i < list.length; i++) {
        if (list[i].token_uri && list[i].token_address) {
          if (
            (!list[i].metadata && list[i].token_uri.includes(".json")) ||
            list[i].token_address ==
              "0x28472a58a490c5e09a238847f66a68a47cc76f0f" ||
            list[i].token_address ==
              "0xda22422592ee3623c8d3c40fe0059cdecf30ca79"
          ) {
            list.splice(i, 1);
            i -= 1;
          }
        }
      }

      this.showcaseList.forEach((item) => {
        for (let i = 0; i < list.length; i++) {
          if (
            list[i].token_address == item.token_address &&
            list[i].token_id == item.token_id
          ) {
            list.splice(i, 1);
            i -= 1;
          }
        }
      });

      this.isOverflow = list.length;
      this.collectedList = list.reverse();
      console.log("collectedList", this.collectedList);
    },
    async handleShowcaseList(account) {
      await axios
        .get(
          "https://r5uyhu7s5c.execute-api.us-east-1.amazonaws.com/test/showcase/list/" +
            account
        )
        .then((res) => {
          this.showcaseList = [...res.data.Items];
          this.showcaseList = this.showcaseList.sort(
            (a, b) => a.order - b.order
          );
          for (let i = 0; i < this.showcaseList.length; i++) {
            this.showcaseList[i].isChecked = false;
          }
        })
        .catch((e) => {
          console.log(e);
        });
      console.log("this.showcaseList", this.showcaseList);
    },
    addShowcase() {
      if (!this.showEditBtn) {
        return;
      }
      let addCheckedList = [];
      this.collectedList.forEach((item) => {
        if (item.isChecked) {
          this.showSelectAllCheck = true;
          this.showEditBtn = false;
          this.showCheckModal = true;
          this.showSelectCollectedAllCheckIcon = false;
          addCheckedList.push(item);
          this.showcaseList.push(item);
          this.enableSaveBtn = true;
        }
      });
      if (!addCheckedList || addCheckedList.length == 0) {
        return;
      } else {
        this.addCheckedList = addCheckedList;
        addCheckedList.forEach((item) => {
          for (let i = 0; i < this.collectedList.length; i++) {
            if (
              this.collectedList[i].token_address == item.token_address &&
              this.collectedList[i].token_id == item.token_id
            ) {
              this.collectedList.splice(i, 1);
              i -= 1;
            }
          }
        });
      }
    },
    async saveShowcase() {
      // debugger;
      if (!this.enableSaveBtn) {
        return;
      }
      if (this.addCheckedList.length == 0 && this.showEditBtn) {
        for (let i = 0; i < this.showcaseList.length; i++) {
          this.showcaseList[i].sequence = i + 1;
        }
        let params = {
          tokens: this.showcaseList,
        };
        /* eslint-disable */
        // debugger;
        // const signature = await signMessage(md5(this.showcaseList + "sort"));
        // if (signature) {
        //   const verify = verifyMessage(
        //     md5(this.showcaseList + "sort"),
        //     signature,
        //     this.account
        //   );
        /* eslint-disable */
        // debugger;
        // if (!verify) {
        //   return;
        // }
        // }
        // await axios
        //   .post(
        //     "https://r5uyhu7s5c.execute-api.us-east-1.amazonaws.com/test/showcase/sort",
        //     params
        //   )
        //   .then((res) => {
        //     if (res.data) {
        //       this.enableSaveBtn = false;
        //     }
        //   })
        //   .catch((e) => {
        //     console.log(e);
        //   });
        this.enableSaveBtn = false;
        this.isSort = false;
      } else {
        if (this.addCheckedList.length > 0) {
          for (let i = 0; i < this.addCheckedList.length; i++) {
            this.addCheckedList[i].order =
              this.showcaseList.length - this.addCheckedList.length + i + 1;
            this.addCheckedList[i].sequence =
              this.showcaseList.length - this.addCheckedList.length + i + 1;
          }
        } else {
          return;
        }
        let params = {
          account: this.account,
          tokens: this.addCheckedList,
        };
        // 进行操作签名及验证
        // verifyMessage
        // const signature = await signMessage(md5(this.addCheckedList + "add"));
        // if (signature) {
        //   const verify = verifyMessage(
        //     md5(this.addCheckedList + "add"),
        //     signature,
        //     this.account
        //   );
        /* eslint-disable */
        // debugger;
        // if (!verify) {
        //   return;
        // }
        // }

        // await axios
        //   .post(
        //     "https://r5uyhu7s5c.execute-api.us-east-1.amazonaws.com/test/showcase/add",
        //     params
        //   )
        //   .then((res) => {
        //     if (res.data) {
        //       if (!this.isSort) {
        //         this.showcaseList.forEach((item) => {
        //           if (item.isChecked) {
        //             item.isChecked = false;
        //           }
        //         });
        //         this.showCheckModal = false;
        //         this.showEditBtn = true;
        //         this.showSelectAllCheck = false;
        //       }
        //       this.enableSaveBtn = false;
        //     }
        //   })
        //   .catch((e) => {
        //     console.log(e);
        //   });
        if (!this.isSort) {
          this.showcaseList.forEach((item) => {
            if (item.isChecked) {
              item.isChecked = false;
            }
          });
          this.showCheckModal = false;
          this.showEditBtn = true;
          this.showSelectAllCheck = false;
        }
        this.enableSaveBtn = false;
      }
    },
    handleCheckModal() {
      this.showCheckModal = true;
      this.showEditBtn = false;
      this.showSelectAllCheck = true;
    },
    handleCheckIcon(index) {
      if (!this.showCheckModal) {
        return;
      }
      if (this.showcaseList[index].isChecked) {
        this.showcaseList[index].isChecked = false;
        if (this.removeCheckedList[index]) {
          this.removeCheckedList.shift(this.removeCheckedList[index]);
        }
      } else {
        this.showcaseList[index].isChecked = true;
        this.removeCheckedList.push(this.showcaseList[index]);
      }
    },
    //
    handleSelectCheck() {
      let isCancelAll;
      if (this.showSelectAllCheckIcon) {
        this.showcaseList.forEach((item) => {
          if (item.isChecked) {
            isCancelAll = true;
          }
          item.isChecked = false;
        });
        this.showSelectAllCheckIcon = false;
        if (isCancelAll) {
          this.removeCheckedList.forEach((item) => {
            this.removeCheckedList.shift(item);
          });
        }
      } else {
        for (let index in this.showcaseList) {
          this.showcaseList[index].isChecked = true;
          this.removeCheckedList.push(this.showcaseList[index]);
        }
        this.showSelectAllCheckIcon = true;
      }
    },
    handleCollectedSelectCheck() {
      if (this.showSelectCollectedAllCheckIcon) {
        this.collectedList.forEach((item) => {
          item.isChecked = false;
        });
        this.showSelectCollectedAllCheckIcon = false;
      } else {
        this.collectedList.forEach((item) => {
          item.isChecked = true;
        });
        this.showSelectCollectedAllCheckIcon = true;
      }
    },
    handleCollectedCheckIcon(index) {
      if (this.collectedList[index].isChecked) {
        this.collectedList[index].isChecked = false;
      } else {
        this.collectedList[index].isChecked = true;
      }
    },
    async removeItem() {
      let showcaseCheckedList = [];
      this.showcaseList.forEach((item) => {
        if (item.isChecked) {
          // if (this.collectedList.length == 0) {
          //   this.showSelectCollectedAllCheckIcon = true;
          // }
          showcaseCheckedList.push(item);
        }
      });
      if (!showcaseCheckedList || showcaseCheckedList.length == 0) {
        // this.showEditBtn = true;
        // this.showSelectAllCheck = false;
        // this.showCheckModal = false;
      } else {
        // const signature = await signMessage(
        //   md5(showcaseCheckedList + "remove")
        // );
        // if (signature) {
        //   const verify = await verifyMessage(
        //     md5(showcaseCheckedList + "remove"),
        //     signature,
        //     this.account
        //   );
        /* eslint-disable */
        // debugger;
        // if (!verify) {
        //   return;
        // }
        // }
       
        showcaseCheckedList.forEach(async (item) => {
          // await axios
          //   .get(
          //     "https://r5uyhu7s5c.execute-api.us-east-1.amazonaws.com/test/showcase/remove/" +
          //       item.id
          //   )
          //   .then((res) => {
          //     if (res.data.statusCode == "200") {
          //       item.isChecked = false;
          //       this.collectedList.push(item);
          //       this.showSelectAllCheckIcon = false;
          //     }
          //   })
          //   .catch((e) => {
          //     console.log(e);
          //   });
          item.isChecked = false;
          this.collectedList.push(item);

          // await axios
          //   .get(
          //     "https://r5uyhu7s5c.execute-api.us-east-1.amazonaws.com/test/showcase/" +
          //       item.id
          //   )
          //   .then((res) => {
          //     if (res.statusCode == "200") {
          //       isExsitItem = res.body.Item;
          //     }
          //   })
          //   .catch((e) => {
          //     console.log(e);
          //   });

          for (let i = 0; i < this.showcaseList.length; i++) {
            if (
              this.showcaseList[i].token_address == item.token_address &&
              this.showcaseList[i].token_id == item.token_id
            ) {
              this.showcaseList.splice(i, 1);
              i -= 1;
            }
          }
        });
         this.enableSaveBtn = true;
        this.showSelectAllCheckIcon = false;
      }

      this.showEditBtn = true;
      this.showSelectAllCheck = false;
      this.showCheckModal = false;
    },
    closeModal() {
      this.showModal = false;
      this.$parent.showPreviewModal = false;
    },
    metamaskChangeWallet() {
      ethereum.on("accountsChanged", function (accounts) {
        if (accounts.length === 0) {
          this.account = null;
        } else if (this.account !== accounts[0]) {
          this.account = accounts[0];
          // this.$set(this.currentAccount,currentAccount1);
          // this.changeCurrentAccout = true;
          console.log("accountslphead--------" + this.account);
        }
        // return  this.currentAccount;
        //一旦切换账号这里就会执行
      });
    },
  },
  watch: {
    account(val) {
      this.handleShowcaseList(val);
      this.handleCollectedList(val);
    },
    showcaseList() {
      if (this.count > 0) {
        // this.isSort = true;
        this.enableSaveBtn = true;
      }
      this.count++;
    },
    showEditBtn(val) {
      if (!val) {
        this.showDragText = false;
      } else {
        this.showDragText = true;
      }
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    computedClass() {
      let clazzes = [];
      if (this.enableSaveBtn) {
        clazzes.push("content-icon-disable");
      }
      return clazzes;
    },
  },
  mounted() {
    // debugger;
    this.metamaskChangeWallet();
    ethereum
      .request({ method: "eth_accounts" })
      .then((accounts) => {
        if (accounts.length === 0) {
          // MetaMask is locked or the user has not connected any accounts
          console.log("Please connect to MetaMask.");
        } else if (accounts[0] !== this.account) {
          this.account = accounts[0];
          // Do any other work!
        }
      })
      .catch((err) => {
        console.error(err);
      });
    this.handleShowcaseList(this.account);
    this.handleCollectedList(this.account);
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.preview-modal-mask {
  position: fixed;
  display: table;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  .preview-modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    .preview-modal {
      width: 1066px;
      height: 706px;
      display: flex;
      margin: auto;
      .content-icon {
        cursor: pointer;
      }
      .content-icon-disable {
        pointer-events: none;
      }
      .colleced {
        margin-right: 2px;
      }
      .select-all {
        display: flex;
        height: 44px;
        // border: 1px solid red;
        position: relative;
        .select-all-check-modal {
          width: 23px;
          height: 22px;
          background: #ffffff;
          border: 1px solid #d4d4d4;
          border-radius: 50%;
          margin-top: 9px;
          cursor: pointer;
        }
        .select-all-check-icon {
          width: 12px;
          height: 10px;
          position: absolute;
          top: 11px;
          left: 6px;
          cursor: pointer;
        }
        .select-all-text {
          height: 22px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 22px;
          margin: 9px 10px 13px 8px;
          //   border: 1px solid black;
        }
        .select-all-delete {
          width: 46px;
          height: 44px;
          cursor: pointer;
        }
      }

      .right-modal {
        width: 496px;
        background: #fbfafd;
        border-radius: 10px;
      }
      .nft-card {
        position: relative;
        display: inline-block;
        margin: 0 5.6px 10px 0;
      }
      .draggable-card {
        position: relative;
        display: inline-block;
        margin: 0 5.6px 5px 0;
      }
      .nft-img {
        width: 66px;
        height: 66px;
        box-shadow: 0px 2px 4px 0px rgba(101, 48, 255, 0.21);
        border-radius: 8px;
        cursor: pointer;
        object-fit: contain;
        // border: 1px solid red;
      }
      .nft-img img {
        width: 66px;
        height: 66px;
        max-width: 100%;
        border-radius: 8px;
        box-shadow: 0px 2px 4px 0px rgba(101, 48, 255, 0.21);
        object-fit: contain;
      }
      .nft-card-check-modal {
        position: absolute;
        width: 15px;
        height: 15px;
        background: #ffffff;
        border: 1px solid #d4d4d4;
        border-radius: 50%;
        cursor: pointer;
        top: 2px;
        left: 49px;
      }
      .check-icon {
        position: absolute;
        top: -3px;
        left: 53px;
        width: 8px;
        height: 6px;
        cursor: pointer;
      }
    }
  }
}
</style>