<template>
  <div class="myUpload">
    <!-- 上传文件列表 -->
    <div
      class="myUpload-img center"
      v-for="(item, index) in mydata"
      :key="index"
      :style="computedStyle"
    >
      <img :src="url" v-if="modalType == 'import'" />
      <div v-else>
        <img :src="item.url" v-if="type == 'image'" />
        <img
          class="icon_del"
          src="../../assets/icon_del.png"
          @click="del(item)"
        />
      </div>
      <!-- <div class="fileLogo" v-else>
        {{ item.ext }}
      </div> -->
      <!-- 删除文件 -->
      <!-- <div class="delete" @click="del(item)">
        <i class="icon-delete">+</i>
      </div> -->
    </div>
    <!-- 上传框 -->
    <label
      class="btn center"
      v-if="(limit == 1 && mydata.length < 1) || limit > 1"
      :style="computedStyle"
    >
      <div><img src="../images/icon_floder.png" /></div>
      <div>Support PNG、JPG、GIF, 400 × 400 recommended, max size 1M</div>
      <!-- <span class="icon-add">+</span> -->
      <!-- 多图 -->
      <input
        type="file"
        v-if="multiple == 1"
        multiple
        class="inputfile"
        @change="handlerUpload($event)"
        :accept="accept"
      />
      <!-- 单图 -->
      <input
        type="file"
        v-else
        class="inputfile"
        @change="handlerUpload($event)"
        :accept="accept"
      />
    </label>
  </div>
</template>
 
<style lang="scss" scoped>
i {
  font-style: normal;
}

.myUpload {
  display: flex;
}

.inputfile {
  width: 0px;
  height: 0px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.btn {
  width: 200px;
  height: 200px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
  letter-spacing: 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #fafafa;
  border-radius: 10px;
  border: 2px dashed #dedede;
}

.btn:hover {
  /* color: #6bc7ff;
  border-color: #6bc7ff; */
}

.myUpload-img {
  width: 928px;
  padding: 31px 0 11px 0;
  height: 118px;
  position: relative;
  background: #fafafa;
  border-radius: 10px;
  border: 2px dashed #dedede;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
  }
  .icon_del {
    width: 13px;
    height: 13px;
    position: absolute;
    top: 14px;
    right: 35px;
    cursor: pointer;
  }
}

.myUpload-img:hover .delete {
  display: flex;
}

/* .myUpload-img:hover {
  border-color: red;
} */

.myUpload-img img {
  max-width: 100%;
  // width: 165px;
  height: 118px;
}

.myUpload-img .fileLogo {
  color: #adadad;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  text-transform: uppercase;
  font-size: 3rem;
}

.myUpload-img .delete {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  bottom: 0;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.icon-delete {
  color: red;
  font-size: 3rem;
  transform: rotate(45deg);
}

.center {
  margin: auto;
}
/* @import "../css/Uploads.scss"; */
</style>
 
<script>
// 引入js文件
import Uploads from "../js/Uploads";
// 使用js对象
export default {
  ...Uploads,
};
</script>
