import {
    ref, reactive, getCurrentInstance, toRefs,
} from 'vue';
import Web3, { utils } from 'web3';
import Web3Modal from 'web3modal';
import { getChainData } from '../web3/tools';
import { providerOptions } from '../web3/config';
import store from '../store'

// const INITIAL_STATE = {
//     web3: store.getters.getWeb3Value.web3,
//     provider: store.getters.getWeb3Value.provider,
//     userAddress: store.getters.getWeb3Value.userAddress,
//     connected:  store.getters.getWeb3Value.connected,
//     chainId: store.getters.getWeb3Value.chainId,
//     networkId: store.getters.getWeb3Value.networkId,
// };

const INITIAL_STATE = {
    web3: null,
    provider: null,
    userAddress: '',
    connected: false,
    chainId: 1,
    networkId: 1,
};
export default function UseWallet() {
    const { ctx: _this } = getCurrentInstance();

    const walletObj = reactive({ ...INITIAL_STATE });
    const fetching = ref(false);
    const assets = ref(0);
    //https://github.com/Web3Modal/web3modal#web3modal
    const web3Modal = new Web3Modal({
        theme: 'dark',
        network: getChainData(walletObj.chainId).network,
        cacheProvider: true,
        providerOptions,
    });
    // methods wallte.js
    const resetApp = async () => {
        const { web3 } = walletObj;
        if (web3 && web3.currentProvider && web3.currentProvider.close) {
            await web3.currentProvider.close();
        }
        web3Modal.clearCachedProvider();
        assets.value = 0;
        Object.keys(INITIAL_STATE).forEach((e) => {
            walletObj[e] = INITIAL_STATE[e];
            store.dispatch('setWeb3Value', { ...walletObj })
        });
        _this.$forceUpdate();

    };
    const getUserBalance = () => walletObj.web3.eth
        .getBalance(walletObj.userAddress)
        .then((res) => (res ? utils.fromWei(res.toString(), 'ether') : 0));

    const getAccountAssets = async () => {
        fetching.value = true;
        // get account balances

        assets.value = await getUserBalance();
    };
    const subscribeProvider = async (provider) => {
        if (!provider.on) {
            return;
        }
        // Subscribe to provider connection
        provider.on("connect", () => {
            console.log("已经手动连接");
        });

        // Subscribe to provider disconnection
        provider.on("disconnect", () => {
            console.log("已经断开连接");
            resetApp()
        });
        provider.on('close', () => {
            resetApp()
        });
        provider.on('accountsChanged', async (accounts) => {
            // eslint-disable-next-line prefer-destructuring
            walletObj.userAddress = accounts[0];
            store.dispatch('setWeb3Value', { ...walletObj })
            await getAccountAssets();
            window.location.reload();
        });
        provider.on('chainChanged', async (chainId) => {
            const networkId = await walletObj?.web3?.eth?.net.getId();
            walletObj.chainId = chainId;
            walletObj.networkId = networkId;
            console.log('333', chainId, networkId);
            store.dispatch('setWeb3Value', { ...walletObj })
            await getAccountAssets();
        });
    };

    const onConnect = async () => {
        let provider = await web3Modal.connect();
        await subscribeProvider(provider);
        const web3 = new Web3(provider);
        const accounts = await web3.eth.getAccounts();
        const address = accounts[0];
        const networkId = await web3.eth.net.getId();
        const chainId = await web3.eth.getChainId(); // 坑逼 注意版本 chainId
        walletObj.web3 = web3;
        walletObj.provider = provider;
        walletObj.connected = true;
        walletObj.userAddress = address;
        walletObj.chainId = chainId;
        walletObj.networkId = networkId;
        store.dispatch('setWeb3Value', { ...walletObj })
        console.log("dddddd", store.state, store.getters.getWeb3Value)
        await getAccountAssets();
    };

    return {
        ...toRefs(walletObj),
        fetching,
        assets,
        resetApp,
        getAccountAssets,
        web3Modal,
        onConnect,
    };
}
