
export default {
  setUidParam(state, uidParam) {
    state.uidParam = uidParam
  },
  Set_Collection_URL(state, imageUrl) {
    state.collectionURL = imageUrl
  },
  Set_Item_URL(state, imageUrl) {
    state.itemURL = imageUrl
  },
  Set_Collection(state, payload) {
    state.collection = { ...payload }
  },
  setAccount(state, account) {
    state.account = account
  },
  handlePreviewModal(state, bol) {
    state.showPreviewModal = bol
  },
  setNewItemCollectionData(state, payload) {
    state.newItemCollectionData = { ...payload }
  },
  setWeb3Value(state, payload) {
    state.web3Value = { ...payload }
  },
  setPageDefaultValue(state, value) {
    state.pageDefaultValue = value
  },
  setCollectionBlockChain(state, payload) {
    state.collectionBlockChain = { ...payload }
  },
  setImportCollectionBlockChain(state, payload) {
    state.importCollectionBlockChain = { ...payload }
  },
  setImportNFTBlockChain(state, payload) {
    state.importNFTBlockChain = { ...payload }
  }
}
