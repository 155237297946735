<template>
  <div class="outer6 flex-col">
    <div class="mod6 flex-col">
      <div class="mod7 flex-col">
        <div class="layer8 flex-col"></div>
      </div>
      <div class="ImageText8 flex-col">
        <div class="group2 flex-col justify-between">
          <div class="group3 flex-row justify-between">
            <img
              class="pic3"
              referrerpolicy="no-referrer"
              src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng8169d95770a533132704c256907eec2166d0d746de37f0f1235c0530515662b8"
            />
          </div>
          <div class="TextGroup8 flex-col">
            <div class="main4 flex-col">
              <span class="info8">0x45…893a</span>
              <div class="bd6">
                <span class="word18">Jack</span>
                <span class="word19">（123321&#64;aster.so）</span>
              </div>
              <span class="info9">aster_jack&#64;aster.so</span>
              <span class="txt5"
                >Community-owned&nbsp;DAO&nbsp;to&nbsp;support&nbsp;web3&nbsp;innovation&nbsp;+&nbsp;360°&nbsp;NFTs&nbsp;+&nbsp;memes.&nbsp;&nbsp;</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="mod10 flex-col"></div>
      <div class="mod11 flex-col">
        <div class="layer10 flex-row">
          <div class="ImageText9 flex-col">
            <div class="layer10 flex-row justify-between">
              <div class="layer11 flex-col">
                <div class="box7 flex-col"></div>
              </div>
              <div class="TextGroup9 flex-col">
                <span class="info10">facebook_jack&#64;aster.so</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="mod12 flex-col">
        <div class="block3 flex-row">
          <div class="ImageText10 flex-col">
            <div class="block3 flex-row justify-between">
              <div class="group5 flex-col">
                <div class="main5 flex-col"></div>
              </div>
              <div class="TextGroup10 flex-col">
                <span class="txt6">twitter_10129384020&#64;aster.so</span>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div class="mod13 flex-col">
        <div class="mod14 flex-row">
          <div class="ImageText11 flex-col">
            <div class="mod14 flex-row justify-between">
              <div class="bd7 flex-col">
                <div class="section15 flex-col"></div>
              </div>
              <div class="TextGroup11 flex-col">
                <span class="info11">Instagram_jack&#64;aster.so</span>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div class="mod15 flex-col"></div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "SocialCard",
};
</script>

<style lang="scss" scoped>
.outer6 {
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 16px;
  z-index: 175;
  height: 295px;
  width: 249px;
  position: absolute;
  left: 644px;
  top: 384px;
}

.mod6 {
  width: 248px;
  height: 282px;
  margin-top: 13px;
}
.mod7 {
  border-radius: 10px;
  height: 21px;
  border: 1px solid rgba(239, 239, 239, 1);
  margin-left: 135px;
  width: 102px;
}
.layer8 {
  background-color: rgba(239, 239, 239, 1);
  border-radius: 10px;
  height: 21px;
  width: 21px;
}
.section13 {
  width: 8px;
  height: 7px;
  margin: 4px 0 0 7px;
}
.label2 {
  width: 4px;
  height: 7px;
}
.icon2 {
  width: 4px;
  height: 7px;
}
.section14 {
  width: 8px;
  height: 6px;
  margin: 1px 0 3px 7px;
}
.mod8 {
  z-index: 234;
  position: relative;
  width: 4px;
  height: 6px;
  // background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng564913d227dde1394d763c121068bb0134720828d723666c2aaf8da03601e7f9)
  //   0px -1px no-repeat;
}
.label3 {
  z-index: 236;
  position: absolute;
  left: 0;
  top: -3px;
  width: 4px;
  height: 4px;
}
.mod9 {
  z-index: 235;
  position: relative;
  width: 4px;
  height: 6px;
  // background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng15d13b46b9a3e5437daa241f0da13b033450a9d142294a01174c1a628515e820)
  //   0px -1px no-repeat;
}
.label4 {
  z-index: 237;
  position: absolute;
  left: 0;
  top: -3px;
  width: 4px;
  height: 4px;
}
.ImageText8 {
  height: 147px;
  width: 222px;
  margin: -21px 0 0 15px;
}
.group2 {
  width: 222px;
  height: 147px;
}
.group3 {
  width: 222px;
  height: 54px;
}
.pic3 {
  width: 54px;
  height: 54px;
}
.main3 {
  border-radius: 10px;
  height: 21px;
  border: 1px solid rgba(239, 239, 239, 1);
  margin-top: 25px;
  width: 102px;
}
.outer7 {
  background-color: rgba(239, 239, 239, 1);
  border-radius: 10px;
  height: 21px;
  width: 21px;
}
.layer9 {
  height: 10px;
  // background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng32bb3be0245cab2772385de8ec17020429353e2bb669ecc47698cf6eda3cd931)
  //   100% no-repeat;
  width: 12px;
  margin: 6px 0 0 5px;
}
.group4 {
  width: 12px;
  height: 10px;
  // background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga21602b062d74d6fa9fc39686431123f3c981d4694c2779969d2cda556a65490)
  //   100% no-repeat;
}
.TextGroup8 {
  height: 87px;
  margin-top: 6px;
  width: 220px;
}
.main4 {
  width: 220px;
  height: 145px;
  margin-top: -58px;
}
.info8 {
  width: 69px;
  height: 17px;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 12px;
  font-family: PingFangSC-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 17px;
  margin-left: 147px;
  display: block;
}
.word17 {
  width: 67px;
  height: 17px;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 12px;
  font-family: PingFangSC-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 17px;
  display: block;
  margin: 8px 0 0 147px;
}
.bd6 {
  width: 194px;
  height: 20px;
  overflow-wrap: break-word;
  font-size: 0;
  font-family: PingFangSC-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 16px;
  margin-top: 39px;
}
.word18 {
  width: 194px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 16px;
  font-family: PingFangSC-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 16px;
}
.word19 {
  width: 194px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 12px;
  font-family: PingFangSC-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 12px;
}
.info9 {
  width: 131px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(106, 90, 155, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  display: block;
}
.txt5 {
  width: 220px;
  height: 40px;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 12px;
  font-family: PingFangSC-Regular;
  text-align: left;
  line-height: 14px;
  margin-top: 7px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.mod10 {
  background-color: rgba(222, 222, 222, 0.5);
  width: 248px;
  height: 1px;
  margin-top: 11px;
}
.mod11 {
  background-color: rgba(249, 248, 252, 0.79);
  border-radius: 10px;
  height: 28px;
  width: 226px;
  margin: 8px 0 0 12px;
}
.layer10 {
  width: 164px;
  height: 20px;
  display: flex;
}
.ImageText9 {
  height: 20px;
  width: 164px;
  margin: 4;
}
.layer11 {
  background-color: rgba(60, 89, 155, 1);
  border-radius: 7px;
  height: 20px;
  width: 20px;
  margin: 4px;
}
.box7 {
  width: 14px;
  height: 14px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf4743070245286477d07c601d8bebe1fe70a7c2ec500c964e26bf551bee1e709)
    100% no-repeat;
  margin: 3px 0 0 3px;
}
.TextGroup9 {
  height: 17px;
  margin-top: 4px;
  width: 136px;
}
.info10 {
  width: 136px;
  height: 17px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 12px;
  font-family: PingFangSC-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 17px;
  display: block;
}
.mod12 {
  background-color: rgba(249, 248, 252, 1);
  border-radius: 10px;
  height: 28px;
  width: 226px;
  margin: 8px 0 0 12px;
}
.block3 {
  width: 200px;
  height: 20px;
}
.ImageText10 {
  height: 20px;
  width: 200px;
}
.group5 {
  background-color: rgba(36, 124, 223, 1);
  border-radius: 7px;
  height: 20px;
  width: 20px;
}
.main5 {
  width: 14px;
  height: 14px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngcb354e1d122b5579955424041a17f523dd58f4bfcff67186823fc4eef4dcadea)
    100% no-repeat;
  margin: 3px 0 0 3px;
}
.TextGroup10 {
  height: 17px;
  margin-top: 1px;
  width: 172px;
}
.txt6 {
  width: 172px;
  height: 17px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 12px;
  font-family: PingFangSC-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 17px;
  display: block;
}
.mod13 {
  background-color: rgba(249, 248, 252, 1);
  border-radius: 10px;
  height: 28px;
  width: 226px;
  margin: 8px 0 0 12px;
}
.mod14 {
  width: 167px;
  height: 20px;
}
.ImageText11 {
  height: 20px;
  width: 167px;
}
.bd7 {
  height: 20px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng39085196e539cbff280ca1dc76bafc2e2da4715ab7e8fb2cfd16de0dd7adf367)
    100% no-repeat;
  width: 20px;
}
.section15 {
  width: 14px;
  height: 14px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng83a0bd7f3a7b0624d785eb009ad88a056ff69e0e33d9d2df97193591cf0c845e)
    100% no-repeat;
  margin: 3px 0 0 3px;
}
.TextGroup11 {
  height: 17px;
  margin-top: 1px;
  width: 139px;
}
.info11 {
  width: 139px;
  height: 17px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 12px;
  font-family: PingFangSC-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 17px;
  display: block;
}
.mod15 {
  width: 14px;
  height: 14px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng94d23560602d58a33b3b933b6643e61a47cfc954d556dbd3506d49117f1d4228)
    100% no-repeat;
  margin: 1px 0 0 118px;
}
</style>

