<template>
  <div class="preview-modal-mask" v-show="showModal">
    <div class="preview-modal-wrapper">
      <div class="preview-modal">
        <ShowcaseModal
          :enableSaveBtn="enableSaveBtn"
          :showDragText="showDragText"
          @save="saveShowcase"
          @close="closeModal"
        >
          <template #content-title> Showcase </template>
          <template #content-icon>
            <img
              class="content-icon"
              src="../assets/preview_btn_list.png"
              @click="handleCheckBtn"
              v-show="showEditBtn"
            />
            <div class="select-all" v-show="showSelectAll">
              <div
                class="select-all-check-modal"
                @click="handleShowcaseSelectAllCheck"
              ></div>
              <div class="select-all-text">Select all</div>
              <div class="select-all-delete" @click="removeItem">
                <img src="../assets/preview_btn_del.png" />
              </div>
              <div
                class="select-all-check-icon"
                v-show="showShowcaseSelectAllCheckIcon"
                @click="handleShowcaseSelectAllCheck"
              >
                <img src="../assets/icon_cancel_big.png" />
              </div>
            </div>
          </template>
          <template #content-container>
            <div
              class="nft-card"
              v-for="(item, index) in showcaseList"
              :key="index"
              v-show="showSelectAll"
            >
              <div class="nft-img" @click="handleCheckIcon(index)">
                <img
                  :src="item.token_uri"
                  onerror="../assets/img_Placeholder.png"
                />
              </div>
              <div
                class="nft-card-check-modal"
                v-show="showCheckModal"
                @click="handleCheckIcon(index)"
              ></div>
              <div
                class="check-icon"
                v-show="item.isChecked"
                @click="handleCheckIcon(index)"
              >
                <img src="../assets/icon_cancel.png" />
              </div>
            </div>

            <div class="row" v-show="showEditBtn">
              <div class="col-6">
                <draggable
                  class="list-group"
                  item-key="order"
                  key="order"
                  tag="transition-group"
                  :component-data="{
                    tag: 'div',
                    name: 'flip-list',
                    type: 'transition',
                  }"
                  v-model="showcaseList"
                  v-bind="dragOptions"
                  @start="isDragging = true"
                  @end="isDragging = false"
                >
                  <template #item="{ element }">
                    <div class="draggable-card">
                      <img
                        :src="element.token_uri"
                        class="nft-img"
                        onerror="../assets/img_Placeholder.png"
                      />
                    </div>
                  </template>
                </draggable>
              </div>
            </div>
          </template>
        </ShowcaseModal>
        <ShowcaseModal
          classType="collected"
          width="347"
          :showModalHead="false"
          :showSearchBox="true"
          :showDragInfo="false"
          :showFooterBtn="false"
          :isOverflow="isOverflow"
          :showLeftBtn="true"
          @leftMove="addShowcase"
        >
          <template v-slot:content-title>Your items</template>
          <template #content-icon>
            <div class="select-all colleced">
              <div
                class="select-all-check-modal"
                @click="handleCollectedSelectAllCheck"
                :class="computedClass"
              ></div>
              <div class="select-all-text">Select all</div>
              <div
                class="select-all-check-icon"
                v-show="showSelectCollectedAllCheckIcon"
                @click="handleCollectedSelectAllCheck"
              >
                <img
                  v-show="showSelectCollectedAllCheckIconImg"
                  src="../assets/icon_cancel_big.png"
                />
              </div>
            </div>
          </template>
          <template #content-container>
            <div>
              <div
                class="nft-card collected-card"
                v-for="(item, index) in collectedList"
                :key="index"
              >
                <!-- <video width="66" height="66" controls v-show="item.isVideo">
                <source :src="item.token_uri" type="video/mp4" />
              </video> -->
                <div
                  class="nft-img"
                  @click="handleCollectedCheckIcon(index)"
                  :class="computedClass"
                >
                  <img
                    :src="item.token_uri"
                    onerror="../assets/img_Placeholder.png"
                  />
                </div>
                <div
                  class="nft-card-check-modal"
                  v-show="showCollectedCheckModal"
                  @click="handleCollectedCheckIcon(index)"
                ></div>
                <div
                  class="check-icon"
                  v-show="item.isChecked"
                  @click="handleCollectedCheckIcon(index)"
                >
                  <img v-show="hideCheckIcon" src="../assets/icon_cancel.png" />
                </div>
              </div>
            </div>
          </template>
        </ShowcaseModal>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import axios from "axios";
import { getCollectedList } from "../util/moralis.js";
import {
  showcaseListSort,
  getShowcaseList,
  insertShowcase,
  deleteShowcase,
} from "../util/showcase.js";
import { getHandledCollectedList } from "../util/collected.js";
import { signMessage, verifyMessage } from "../util/sign.js";
import md5 from "blueimp-md5/js/md5.min.js";
export default {
  name: "ManageShowcase",
  components: {
    draggable,
  },
  data() {
    return {
      showModal: true,
      enableSaveBtn: false,
      showDragText: true,
      isOverflow: 0,
      showEditBtn: true,
      showSelectAll: false,
      showCheckModal: false,
      showCollectedCheckModal: true,
      showShowcaseSelectAllCheckIcon: false,
      showSelectCollectedAllCheckIcon: false,
      showSelectCollectedAllCheckIconImg: true,
      hideCheckIcon: true,
      showcaseList: [],
      list: [],
      // collectedList: [],
      removeCheckedList: [],
      addCheckedList: [],
      // account: "0x6B38425037f8b127FC4300DDeCdbC189d21FF6f3".toLowerCase(),
      // account: "0xaaE32d2fd3e3dA9092C45017ee0420d96A38F768",
      // account: this.$store.getters.getAccount,
      count: 0,
    };
  },
  watch: {
    showcaseList: {
      deep: true,
      handler() {
        if (this.count == 1) {
          //   debugger;
          if (
            this.addCheckedList.length == 0 &&
            this.removeCheckedList.length == 0 &&
            this.showSelectAll
          ) {
            return;
          }
          this.enableSaveBtn = true;
        }
        this.count++;
      },
    },
    account(val) {
      this.handleShowcaseList(val);
    },
  },
  computed: {
    account() {
      return this.$store.getters.getWeb3Value.userAddress;
    },
    collectedList() {
      if (!this.list) {
        return [];
      }
      if (this.showcaseList) {
        this.showcaseList.forEach((item) => {
          for (let i = 0; i < this.list.length; i++) {
            if (
              this.list[i].token_address.toLowerCase() ==
                item.token_address.toLowerCase() &&
              this.list[i].token_id == item.token_id
            ) {
              this.list.splice(i, 1);
              i -= 1;
            }
          }
        });
        this.isOverflow = this.list.length;
        let collectedList = this.list;
        this.initCollectedList(collectedList);
        console.log("collectedList", collectedList);
        return collectedList;
      } else {
        return [];
      }
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    computedClass() {
      let clazzes = [];
      if (this.showSelectAll) {
        // let isChecked = false;
        // this.collectedList.forEach((item) => {
        //   if (item.isChecked) {
        //     isChecked = true;
        //   }
        // });
        // if (!isChecked) {
        if (this.showSelectCollectedAllCheckIcon) {
          this.showSelectCollectedAllCheckIconImg = false;
        }
        this.showCollectedCheckModal = false;
        this.hideCheckIcon = false;
        clazzes.push("nft-collected-disable");
        clazzes.push("collected-select-all-disable");
        // }
      } else {
        this.showCollectedCheckModal = true;
        this.showSelectCollectedAllCheckIconImg = true;
        this.hideCheckIcon = true;
      }
      return clazzes;
    },
  },
  methods: {
    async saveShowcase() {
      if (!this.enableSaveBtn) {
        return;
      }
      const signature = await signMessage(
        md5(this.showcaseList + this.addCheckedList + this.removeCheckedList)
      );
      if (signature) {
        debugger;
        const verify = verifyMessage(
          md5(this.showcaseList + this.addCheckedList + this.removeCheckedList),
          this.account,
          signature
        );
        /* eslint-disable */
        // debugger;
        // if (!verify) {
        //   return;
        // }

        if (this.showcaseList.length > 0) {
          let addCheckedList = [...this.showcaseList];
          let result = await insertShowcase(this.account, addCheckedList);
          //   debugger;
        }
        if (this.collectedList.length > 0) {
          // debugger;
          this.collectedList.forEach(async (item) => {
            let result = await deleteShowcase(item.id);
            // debugger;
          });
        }

        let result = await showcaseListSort(this.showcaseList);
        if (result) {
          console.log("sort success", result);
        }
        this.enableSaveBtn = false;
        this.$parent.showPreviewModal = false;
        location.replace(location);
        // this.showModal = false;
      }
    },
    closeModal() {
      this.showModal = false;
      this.$store.commit("handlePreviewModal", false);
    },
    addShowcase() {
      if (!this.showCollectedCheckModal) {
        return;
      }
      let addCheckedList = [...this.collectedList];
      for (let i = 0; i < addCheckedList.length; i++) {
        if (addCheckedList[i].isChecked) {
          addCheckedList[i].isChecked = false;
          this.addCheckedList.push(addCheckedList[i]);
          this.showcaseList.push(addCheckedList[i]);
          // debugger;
          // this.collectedList.splice(i, 1);
          // i -= 1;
        }
      }
      if (this.showSelectAll) {
        this.showCheckModal = true;
      } else {
        this.showCheckModal = false;
      }
      this.showSelectCollectedAllCheckIcon = false;
      this.isOverflow = this.collectedList.length;
    },
    handleCheckBtn() {
      this.showEditBtn = false;
      this.showSelectAll = true;
      this.showCheckModal = true;
    },
    handleCheckIcon(index) {
      if (!this.showCheckModal) {
        return;
      }
      if (this.showcaseList[index].isChecked) {
        this.showcaseList[index].isChecked = false;
        this.showShowcaseSelectAllCheckIcon = false;
      } else {
        this.showcaseList[index].isChecked = true;
        let isCheckedAll = true;
        this.showcaseList.forEach((item) => {
          if (!item.isChecked) {
            isCheckedAll = false;
          }
        });
        if (isCheckedAll) {
          this.showShowcaseSelectAllCheckIcon = true;
        }
      }
    },
    handleShowcaseSelectAllCheck() {
      if (this.showShowcaseSelectAllCheckIcon) {
        this.showShowcaseSelectAllCheckIcon = false;
        this.showcaseList.forEach((item) => {
          if (item.isChecked) {
            item.isChecked = false;
          }
        });
      } else {
        this.showShowcaseSelectAllCheckIcon = true;
        for (let index in this.showcaseList) {
          this.showcaseList[index].isChecked = true;
        }
      }
    },
    initCollectedList(collectedList) {
      collectedList.forEach((item) => {
        if (this.showSelectCollectedAllCheckIcon) {
          item.isChecked = true;
        }
      });
      //   if (this.removeCheckedList.length > 0) {
      //     for (let r = 0; r < this.removeCheckedList.length; r++) {
      //       for (let c = 0; c < this.collectedList.length; c++) {
      //         if (
      //           this.removeCheckedList[r].token_id !==
      //             this.collectedList[c].token_id &&
      //           this.removeCheckedList[r].token_address.toLowerCase() !==
      //             this.collectedList[c].token_address.toLowerCase()
      //         ) {
      //           this.collectedList.push(this.removeCheckedList[r]);
      //           break;
      //         }
      //       }
      //     }
      //   }
    },
    handleCollectedSelectAllCheck() {
      if (this.showSelectCollectedAllCheckIcon) {
        this.showSelectCollectedAllCheckIcon = false;
        this.collectedList.forEach((item) => {
          item.isChecked = false;
        });
      } else {
        this.showSelectCollectedAllCheckIcon = true;
        this.collectedList.forEach((item) => {
          item.isChecked = true;
        });
      }
    },
    handleCollectedCheckIcon(index) {
      if (this.collectedList[index].isChecked) {
        this.collectedList[index].isChecked = false;
        this.showSelectCollectedAllCheckIcon = false;
      } else {
        this.collectedList[index].isChecked = true;
        let isCheckedAll = true;
        this.collectedList.forEach((item) => {
          if (!item.isChecked) {
            isCheckedAll = false;
          }
        });
        if (isCheckedAll) {
          this.showSelectCollectedAllCheckIcon = true;
        }
      }
    },
    removeItem() {
      for (let i = 0; i < this.showcaseList.length; i++) {
        if (this.showcaseList[i].isChecked) {
          this.removeCheckedList.push(this.showcaseList[i]);
          this.collectedList.push(this.showcaseList[i]);
          this.showcaseList[i].isChecked = false;
          this.showcaseList.splice(i, 1);
          i -= 1;
        }
      }
      this.isOverflow = this.collectedList.length;
      //   if (this.removeCheckedList && this.removeCheckedList.length > 0) {
      //       this.enableSaveBtn = true;
      //   }
      this.showShowcaseSelectAllCheckIcon = false;
      this.showEditBtn = true;
      this.showSelectAll = false;
    },
    async handleCollectedList(account) {
      /* eslint-disable */
      // debugger;
      this.list = await getHandledCollectedList(account);
    },
    async handleShowcaseList(account) {
      debugger;
      this.showcaseList = await getShowcaseList(account);
      console.log("this.showcaseList", this.showcaseList);
    },
  },
  mounted() {
    this.handleShowcaseList(this.account);
    this.handleCollectedList(this.account);
  },
};
</script>

<style lang="scss" scoped>
.preview-modal-mask {
  position: fixed;
  display: table;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  .preview-modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    .preview-modal {
      width: 744px;
      height: 494px;
      display: flex;
      margin: auto;
      .content-icon {
        width: 32.2px;
        height: 30.8px;
        cursor: pointer;
      }

      .colleced {
        margin-right: 5px;
      }
      .select-all {
        display: flex;
        // border: 1px solid red;
        position: relative;
        .select-all-check-modal {
          width: 16px;
          height: 15px;
          background: #ffffff;
          border: 1px solid #d4d4d4;
          border-radius: 50%;
          margin-top: 8px;
          cursor: pointer;
        }
        .collected-select-all-disable {
          pointer-events: none;
          background: #e3e0e0;
        }
        .select-all-check-icon {
          position: absolute;
          top: 7px;
          left: 2px;
          cursor: pointer;
          img {
            width: 65%;
            height: 65%;
          }
        }
        .select-all-text {
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 31px;
          margin: 0px 0px 0px 8px;
          //   border: 1px solid black;
        }
        .select-all-delete {
          cursor: pointer;
          margin-left: 10px;
          img {
            width: 32.2px;
            height: 30.8px;
          }
        }
      }
      .nft-card {
        position: relative;
        display: inline-block;
        margin: 0 2.4px 15px 2.4px;
      }
      .draggable-card {
        position: relative;
        display: inline-block;
        margin: 0 2.4px 11px 2.4px;
      }
      .collected-card {
        margin: 0 2px 11px 2px;
      }
      .nft-img {
        width: 46px;
        height: 46px;
        box-shadow: 0px 2px 4px 0px rgba(101, 48, 255, 0.21);
        border-radius: 6px;
        cursor: pointer;
        object-fit: contain;
        // border: 1px solid red;
        img {
          width: 46px;
          height: 46px;
        }
      }
      .nft-collected-disable {
        pointer-events: none;
      }
      .nft-img img {
        width: 46px;
        height: 46px;
        max-width: 100%;
        border-radius: 6px;
        object-fit: contain;
      }
      .nft-card-check-modal {
        position: absolute;
        width: 10px;
        height: 10px;
        background: #ffffff;
        border: 1px solid #d4d4d4;
        border-radius: 50%;
        cursor: pointer;
        top: 2px;
        left: 33px;
      }
      .check-icon {
        position: absolute;
        top: -5px;
        left: 36px;
        cursor: pointer;
        img {
          width: 5.6px;
          height: 4.2px;
        }
      }
    }
  }
}
</style>