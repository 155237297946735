<template>
  <div>
    <HomePageTop :defaultKey=4 top_type="browse"></HomePageTop>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>