import Web3 from 'web3'
import nftplatform from '../constant/contracts/nftplatform.json'
import { ethers } from 'ethers';
import store from '../store';
import chainList from '../constant/supportedChains'
const nftplatformABI = nftplatform.abi;

export const getBindAddress = async (uid, chainId) => {
    const chain = nftplatform.chains[chainId]
    if (!chain) {
        throw new Error('ChainId missing or not supported');
    }
    const nftplatformAddress = chain.contractAddress;
    const web3 = new Web3(new Web3.providers.HttpProvider(chain.rpcUrl))
    const contract = new web3.eth.Contract(nftplatformABI, nftplatformAddress)
    console.log(contract, nftplatformAddress)
    const bindAccount = await contract.methods.getWalletAddress(uid).call()
    return bindAccount
}

export const getBindUid = async (account, chainId) => {
    const chain = nftplatform.chains[43113]
    if (!chain) {
        console.log("the chain is not supported")
        return
    }
    const nftplatformAddress = chain.contractAddress;
    const web3 = new Web3(new Web3.providers.HttpProvider(chain.rpcUrl))
    web3.eth.defaultAccount = account
    console.log(nftplatformAddress, web3, web3.eth.defaultAccount)
    personal.unlockAccount(web3.eth.defaultAccount)
    const contract = web3.eth.contract(nftplatformABI).at(nftplatformAddress)
    // const a = await web3.eth.getAccounts()

    const bindUid = contract.getUIDsByWalletAddress(account).call()
    return bindUid[0]
}

export const getBindUidHandler = async (account) => {
    try {
        const ethereum = store.getters.getWeb3Value.provider;
        if (ethereum) {
            const chain = nftplatform.chains[parseInt(store.getters.getWeb3Value.chainId, 16)]
            if (!chain) {
                console.log("the chain is not supported")
                return
            }
            const nftplatformAddress = chain.contractAddress;
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(nftplatformAddress, nftplatformABI, signer);
            let uid = await contract.getUIDsByWalletAddress(account);
            console.log(contract, account, uid)
            let uids = []
            uid.forEach(id => {
                if (id.startsWith("twitter_")) {
                    uids[0] = id
                }
                else if (id.startsWith("facebook_")) {
                    uids[1] = id
                }
                // todo 别的社交绑定账号
                // else if(id.startsWith("ins"))
                //方便测试，后面需要删除
                else {
                    uids[uids.length + 1] = id
                }
            });
            return uids
        } else {
            console.log("Ethereum object does not exist");
        }
    } catch (err) {
        console.log(err);
    }
}

export const batchClaimHandler = async (to, tokenAddress, tokenID, chainId) => {
    const chain = nftplatform.chains[43113]
    if (!chain) {
        console.log("the chain is not supported")
        return
    }
    const nftplatformAddress = chain.contractAddress;
    const web3 = new Web3(new Web3.providers.HttpProvider(chain.rpcUrl))
    // web3.eth.defaultAccount = web3.eth.accounts[0]
    console.log(nftplatformAddress, web3)
    // web3.personal.unlockAccount(web3.eth.defaultAccount)
    const contract = web3.eth.contract(nftplatformABI).at(nftplatformAddress)
    // const a = await web3.eth.getAccounts()

    const res = contract.claimNFTs(to, tokenAddress, tokenID)
    return res
}

export const claimHandler = async (to, tokenAddress, tokenID) => {
    try {
        // const { ethereum } = window;
        const ethereum = store.getters.getWeb3Value
        if (ethereum) {
            //当前连接链是否正确
            // switchChain(chainId)
            const chain = nftplatform.chains[parseInt(ethereum.chainId, 16)]
            const nftplatformAddress = chain.contractAddress;
            const provider = new ethers.providers.Web3Provider(ethereum.provider);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(nftplatformAddress, nftplatformABI, signer);
            let res = await contract.ERC721TransferFromUID2Account(to, tokenAddress, tokenID);
            console.log(contract, res)
            return res.hash
        } else {
            console.log("Ethereum object does not exist");
        }
    } catch (err) {
        console.log(err);
    }
}
function delay(ms) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            reject("time out")
        }, ms);
    });
}

function timeout(promise, ms) {
    let timeout = delay(ms).then();
    return Promise.race([promise, timeout]);
}
export const isUidInvalid = (socialUID) => {
    let promises = []

    chainList.map((chain) => {
        debugger
        const contractAddress = chain.native_currency.contractAddress;
        chain.rpclist.map((rpc) => {
            debugger
            const web3provider = new Web3(new Web3.providers.HttpProvider(rpc));
            // console.log(web3provider)
            const contract = new web3provider.eth.Contract(nftplatformABI, contractAddress);
            let promise = contract.methods.getWalletAddress(socialUID)
                .call((error, result) => {
                    debugger
                    if (!error) {
                        console.log(`Uid ${socialUID} has been linked to ${result}`);
                    }
                    else {
                        console.log(error);
                    }
                })

            promises.push(timeout(promise, 10000))
        })
    })


    return Promise.allSettled(promises)
}