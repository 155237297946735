
export const setUidParam = ({ commit }, uidParam) => {
  commit('setUidParam', uidParam)
}

export const setImageUrl = ({ commit }, imageUrl) => {
  commit('Set_Collection_URL', imageUrl)
}

export const setItemUrl = ({ commit }, imageUrl) => {
  commit('Set_Item_URL', imageUrl)
}

export const setCollection = ({ commit }, payload) => {
  commit('Set_Collection', payload)
}

export const setNewItemCollectionData = ({ commit }, payload) => {
  commit('setNewItemCollectionData', payload)
}

export const setWeb3Value = ({ commit }, payload) => {
  commit('setWeb3Value', payload)
}

export const setCollectionBlockChain = ({ commit }, payload) => {
  commit('setCollectionBlockChain', payload)
}

export const setImportCollectionBlockChain = ({ commit }, payload) => {
  commit('setImportCollectionBlockChain', payload)
}

export const setImportNFTBlockChain = ({ commit }, payload) => {
  commit('setImportNFTBlockChain', payload)
}

