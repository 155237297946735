<template>
  <router-view />
</template>

<script setup>

import useWallet from "./hooks/useWallet";
import { onMounted } from "vue";

const { onConnect } = useWallet();

onMounted(async () => {
  
  if (localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER")) await onConnect();
});

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  /* text-align: center; */
  color: #2c3e50;
  /* border: 1px solid blue; */
  /* margin: 0px 0px; */
}
</style>
