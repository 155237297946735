<template>
  <div class="popup-container" v-show="showModal" :style="computedStyle">
    <div
      v-for="(item, index) in menubarList"
      :key="index"
      class="menu-bar"
      :id="item.name"
      @click="to(item)"
      @mouseover="over(item)"
      @mouseout="out(item)"
      :style="computedMenuStyle"
    >
      <div v-show="haveIcon"><img :src="item.icon" /></div>
      <div>{{ item.title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EntrancePopup",
  data() {
    return {
      defType: ["edit"],
      showModal: true,
      isCheck: false,
      editList: [
        {
          name: "EditCollection",
          title: "Edit",
          path: "/collection/edit",
        },
        {
          name: "CreatorEarnings",
          title: "Creator Earnings",
          path: "/collection/edit",
        },
      ],
      menubarList: [
        {
          name: "collections",
          title: "My Collections",
          icon: require("../../assets/icon_collection.png"),
          path: "/collections",
        },
        {
          name: "signout",
          title: "Sign out",
          icon: require("../../assets/icon_signout.png"),
          path: "",
        },
      ],
    };
  },
  props: {
    type: {
      type: String,
      default: "default",
    },
    haveIcon: {
      type: Boolean,
      default: "true",
    },
    width: [String, Number],
    height: [String, Number],
    lineHeight: [String, Number],
    fontWeight: [String, Number],
    fontColor: String,
    paddingLeft: [String, Number],
    borderRadius: [String, Number],
    resetApp: {
      type: Function,
      default() {
        return "Default Function";
      },
    },
  },
  computed: {
    computedStyle() {
      if (this.type == "edit") {
        this.menubarList = this.editList;
      }
      let style = {};
      if (this.width) style.width = this.width + "px";
      if (this.height) style.height = this.height + "px";
      if (this.borderRadius) style.borderRadius = this.borderRadius + "px";
      return style;
    },
    computedMenuStyle() {
      let style = {};
      if (this.lineHeight) {
        style.height = this.lineHeight + "px";
        style.lineHeight = this.lineHeight + "px";
      }
      if (this.fontColor) style.color = this.fontColor;
      if (this.fontWeight) style.fontWeight = this.fontWeight;
      if (this.paddingLeft) style.paddingLeft = this.paddingLeft + "px";
      return style;
    },
  },
  methods: {
    to(item) {
      if (item.path) {
        this.$router.push(item.path);
      }else{
        this.resetApp()
      }
      this.showModal = false;
    },
    over(item) {
      document.getElementById(item.name).style.background = "#f9f9f9";
    },
    out(item) {
      document.getElementById(item.name).style.background = "#fff";
    },
  },
};
</script>
<style lang="scss" scoped>
.popup-container {
  position: relative;
  top: 0;
  left: 0;
  width: 159px;
  height: 82px;
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 10px 10px;
  .menu-bar {
    display: flex;
    height: 40px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #606060;
    line-height: 40px;
    background: #ffffff;
    cursor: pointer;
    img {
      width: 18px;
      height: 18px;
      margin: 11px 11px 0 13px;
    }
  }
}
</style>