<template>
  <div>
    <div id="route-uid" v-show="false">{{ $route.params.uid }}</div>
    <HomePageTop :defaultKey="2" top_type="browse"> </HomePageTop>
    <div class="home-page-content">
      <TabGroup defaultKey="1" class="content-top-tab">
        <TabPanel label="Showcase" actKey="1">
          <Showcase :hideAddButton="true" />
        </TabPanel>
        <TabPanel label="Collected" actKey="2"> </TabPanel>
        <TabPanel label="For sale" actKey="3"></TabPanel>
      </TabGroup>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
export default {
  methods: {
    getTwitterIdParam() {
      const uidParam = document.getElementById("route-uid").innerText;
      const input = uidParam.split("_")[1];
      this.$store.commit("setUidParam", uidParam);
      axios
        .post("http://localhost:8080/api/ajax.php", qs.stringify({ input }))
        .then(
          (response) => {
            if (response.data != "error") {
              console.log(response.data);
              // this.$store.commit("setUidParam", input);
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    // this.getTwitterIdParam();
  },
};
</script>

<style lang="scss" scoped>
.content-top-tab {
  width: 100%;
  height: 68px;
  background: #f9f9f9;
  padding-top: 20px;
  // border: 1px solid blueviolet;
}
</style>