import ShowcaseModal from './ShowcaseModal.vue'
import HandlePreviewModal from './HandlePreviewModal.vue'
import ManageShowcase from './ManageShowcase.vue'
import SearchBox from './SearchBox.vue'
// import Web3ModalVue from './WebModal.vue'
import TabGroup from "./TabGroup.vue";
import TabPanel from "./TabPanel.vue";
import HomePageTop from "./HomePageTop.vue";
import CheckCard from "./CheckCard.vue";
import SelectModal from "./SelectModal.vue"
import SyntheticTable from "./SyntheticTable.vue"
import SyntheticForm from "./SyntheticForm.vue"
import Button from "./Button.vue"
import Showcase from "./Showcase.vue"
import StarUploads from "./common/StarUploads.vue"
import EntrancePopup from './collection/EntrancePopup.vue'
import SocialCard from "./wallet/SocialCard.vue"
import NotFound from "./common/NotFound.vue"
import EditModal from "./common/EditModal.vue"
import CollectionModal from "./collection/CollectionModal.vue"
import EditPopup from "./common/EditPopup.vue"
import AssetModal from "./asset/AssetModal.vue"
import NFTList from "./NFTList.vue"
import PopupModal from "./common/PopupModal.vue"
import DropDownBox from "./common/DropDownBox.vue"
import ConnectWallteExample from "./ConnectWallteExample.vue";

export default (Vue) => {
    Vue.component(ConnectWallteExample.name, ConnectWallteExample)
    Vue.component(ShowcaseModal.name, ShowcaseModal)
    Vue.component(HandlePreviewModal.name, HandlePreviewModal)
    Vue.component(SearchBox.name, SearchBox)
    Vue.component(TabGroup.name, TabGroup)
    Vue.component(TabPanel.name, TabPanel)
    Vue.component(HomePageTop.name, HomePageTop)
    Vue.component(ManageShowcase.name, ManageShowcase)
    Vue.component(CheckCard.name, CheckCard)
    Vue.component(SelectModal.name, SelectModal)
    Vue.component(SyntheticTable.name, SyntheticTable)
    Vue.component(SyntheticForm.name, SyntheticForm)
    Vue.component(Button.name, Button)
    Vue.component(Showcase.name, Showcase)
    Vue.component(StarUploads.name, StarUploads)
    Vue.component(EntrancePopup.name, EntrancePopup)
    Vue.component(SocialCard.name, SocialCard)
    Vue.component(NotFound.name, NotFound)
    Vue.component(EditModal.name, EditModal)
    Vue.component(CollectionModal.name, CollectionModal)
    Vue.component(EditPopup.name, EditPopup)
    Vue.component(AssetModal.name, AssetModal)
    Vue.component(NFTList.name, NFTList)
    Vue.component(PopupModal.name, PopupModal)
    Vue.component(DropDownBox.name, DropDownBox)
    // Vue.component(Web3ModalVue.name, Web3ModalVue)
}