export default {
  uidParam: "",
  collectionURL: "",
  collection: {},
  account: "",
  showPreviewModal: false,
  itemURL: "",
  newItemCollectionData: {},
  web3Value: {
    web3: null,
    provider: null,
    userAddress: '',
    connected: false,
    chainId: 1,
    networkId: 1,
  },
  pageDefaultValue: 2,
  collectionBlockChain: {},
  importCollectionBlockChain: {},
  importNFTBlockChain: {},
}
