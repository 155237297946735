<template>
  <HomePageTop :defaultKey="0"></HomePageTop>
  <div class="collection-detail-top">
    <div class="top-background">
      <div class="logo">
        <div class="collection-logo-img">
          <img
            :src="collectionDetailData.logo_image"
            v-show="collectionDetailData.logo_image"
          />
        </div>
        <div class="btn-img" v-if="showCreateBtn">
          <img
            class="img-more"
            src="../../assets/btn_more@2x.png"
            @click="handleImportPopup"
          />
          <img
            class="img-additem"
            src="../../assets/btn_additem@2x.png"
            @click="gotoAddItem"
          />
          <EditPopup
            class="import-popup"
            v-show="showImportPopup"
            @to="handleImportModal"
            @remove="removeNFT"
          >
            <template #first-menu>Import NFT</template>
            <template #second-menu>Remove</template>
          </EditPopup>
        </div>
        <div class="right-icon-btn" v-else>
          <div class="right-remove-btn" @click="remove">
            <div class="btn-remove-icon">
              <img src="../../assets/icon_remove.png" />
            </div>
            <div class="btn-text">Remove</div>
          </div>
          <div class="right-cancel-btn" @click="cancel">
            <div class="btn-cancel-icon">
              <img src="../../assets/icon_cancel@2x.png" />
            </div>
            <div class="btn-text">Cancel</div>
          </div>
        </div>
      </div>
    </div>
    <div class="collection-detail-info">
      <div class="collection-name-text">
        {{ collectionDetailData.collection_name }}
      </div>
      <div
        class="collection-description-text"
        id="collection-detail-description-text"
      >
        {{ collectionDetailData.description }}
      </div>
      <div
        @click="unfoldDescription"
        v-show="collectionDetailData.description?.length > 135"
      >
        <img src="../../assets/icon_down14x10@2x.png" v-if="!isUpfold" />
        <img src="../../assets/icon_up12x8@2x.png" v-else />
      </div>
    </div>
  </div>
  <div class="collection-detail-bottom">
    <div class="detail-mid">
      <div class="left-text">
        <span class="item-count-text">{{
          collectionDetailData.item_count
        }}</span>
        <span class="items-text">Items</span>
      </div>
      <div class="mid-right">
        <SearchBox width="152" height="30" type="collection" />
        <div class="right-filter">
          <div class="filter-icon-sort">
            <img src="../../assets/icon_sort@2x.png" />
          </div>
          Time:First to Last
          <div class="filter-down-icon">
            <img src="../../assets/icon_down14x10@2x.png" />
          </div>
        </div>
      </div>
    </div>
    <NFTList
      :list="collectionDetailData.items"
      :showRemove="showRemove"
      class="nft-list"
    ></NFTList>
  </div>
  <PopupModal
    width="506"
    height="260"
    v-show="showImportModal"
    @close="handleImportModal"
    @ok="importNFT"
    >Import NFT
    <template #body>
      <div class="popup-modal-body">
        <DropDownBox width="215" height="33" type="blockchain" isMini="true" />
        <div class="import-nft-tokenId">
          <div class="tokenId-text">Token ID</div>
          <input
            class="import-nft-tokenId-input"
            placeholder="e.g #0001"
            v-on:focusout="verifyContractAddress"
            v-model="tokenId"
          />
        </div>
        <div v-show="showTipText" class="tip-div">
          <img class="tip-img" src="../../assets/icon_hint.png" />
          <span class="tip-text"
            >You need to be the owner of the asset in order to import this
            nft.</span
          >
        </div>
      </div>

      <input
        type="text"
        class="popup-modal-body-input"
        placeholder="e.g 0x0000..."
        v-on:focusout="verifyContractAddress"
        v-model="contractAddress"
      />
    </template>
  </PopupModal>
</template>

<script>
import axios from "axios";
import metadata from "../../contracts/metadata/mynft.json";
import { mapActions,mapGetters } from "vuex";
import { signMessage } from "../../util/sign";
import Web3 from "web3";
import nftplatform from "../../constant/contracts/nftplatform.json";
export default {
  data() {
    return {
      showRemove: false,
      isUpfold: false,
      collectionDetailData: {},
      showImportPopup: false,
      showTipText: false,
      contractAddress: "",
      tokenId: "",
      showImportModal: false,
      showcaseList: [
        {
          token_uri: require("../../assets/panda.png"),
          token_name: "PhantaBear ",
          token_id: "1000",
        },
        {
          token_uri: require("../../assets/panda.png"),
          token_name: "PhantaBear ",
          token_id: "1000",
        },
        {
          token_uri: require("../../assets/panda.png"),
          token_name: "PhantaBear ",
          token_id: "1000",
        },
        {
          token_uri: require("../../assets/panda.png"),
          token_name: "PhantaBear ",
          token_id: "1000",
        },
        {
          token_uri: require("../../assets/panda.png"),
          token_name: "PhantaBear ",
          token_id: "1000",
        },
        {
          token_uri: require("../../assets/panda.png"),
          token_name: "PhantaBear ",
          token_id: "1000",
        },
        {
          token_uri: require("../../assets/panda.png"),
          token_name: "PhantaBear ",
          token_id: "1000",
        },
        {
          token_uri: require("../../assets/panda.png"),
          token_name: "PhantaBear ",
          token_id: "1000",
        },
        {
          token_uri: require("../../assets/panda.png"),
          token_name: "PhantaBear ",
          token_id: "1000",
        },
      ],
      showCreateBtn: true,
    };
  },
  computed: {
    web3() {
      return this.$store.getters.getWeb3Value.web3;
    },
    ...mapGetters(["getImportNFTBlockChain"]),
  },
  watch: {
    contractAddress(newVal, oldVal) {
      console.log(newVal, newVal.length);
      if (
        newVal !== "0x0000000000000000000000000000000000000000" &&
        newVal?.length >= 42
      ) {
        this.verifyContractAddress();
        if (!this.showTipText && this.tokenId) {
          document.getElementById("component-button-box-id").style.background =
            "#b082fa";
        }
      }
    },
    tokenId() {
      this.verifyContractAddress();
      if (!this.showTipText && this.contractAddress) {
        document.getElementById("component-button-box-id").style.background =
          "#b082fa";
      }
    },
  },
  methods: {
    ...mapActions(["setNewItemCollectionData"]),
    handleImportPopup() {
      this.showImportPopup = !this.showImportPopup;
    },
    getCollectionDetail() {
      debugger
      const collectionName = this.$route.params.name.replaceAll(" ", "");
      console.log(collectionName, decodeURI(collectionName));
      const url =
        "https://nd9gajq3lj.execute-api.us-east-1.amazonaws.com/star/mycollections/Detail/";
      axios.get(url + decodeURI(collectionName)).then((res) => {
        console.log(res);
        if (res.data.statusCode == 200) {
          this.collectionDetailData = res.data.body;
        } else if (res.data.statusCode == 401) {
          this.$router.push({ name: "nFound" });
        }
        (err) => console.log(err);
      });
    },
    gotoAddItem() {
      this.setNewItemCollectionData(this.collectionDetailData);
      this.$router.push({ name: "CreateAsset" });
    },
    unfoldDescription() {
      if (this.isUpfold) {
        this.isUpfold = false;
        document.getElementById(
          "collection-detail-description-text"
        ).style.height = "48px";
      } else {
        this.isUpfold = true;
        document.getElementById(
          "collection-detail-description-text"
        ).style.height = "auto";
      }
    },
    handleImportModal() {
      this.showTipText = false;
      document.getElementById("component-button-box-id").style.background =
        "#b8a0fd";
      this.contractAddress = "";
      this.tokenId = "";
      this.showImportModal = !this.showImportModal;
      this.showImportPopup = false;
    },
    async verifyContractAddress() {
      debugger;
      console.log(this.web3);
      if (!this.tokenId || !this.contractAddress) return;
      let contract;
      let address;
      let chainId = this.getImportNFTBlockChain.chainId;
      const chain = nftplatform.chains[chainId];
      if (!chain) {
        throw new Error("ChainId missing or not supported");
      }
      try {
        debugger
        const web3 = new Web3(new Web3.providers.HttpProvider(chain.rpcUrl));
        contract = new web3.eth.Contract(metadata.abi, this.contractAddress);
        address = await contract.methods.ownerOf(this.tokenId).call();
        console.log("contract", contract, address);
      } catch (err) {
        this.showTipText = true;
        document.getElementById("component-button-box-id").style.background =
          "#b8a0fd";
        return;
      }
      const accounts = await this.web3.eth.getAccounts();
      if (accounts[0].toLowerCase() !== address.toLowerCase()) {
        this.showTipText = true;
      } else {
        this.showTipText = false;
      }
    },
    importNFT() {
      if (
        this.contractAddress !== "0x0000000000000000000000000000000000000000" &&
        this.contractAddress?.length >= 42 &&
        this.tokenId
      ) {
        this.verifyContractAddress();
        if (!this.showTipText) {
          this.$router.push(
            "/asset/import/" + this.contractAddress + "/" + this.tokenId
          );
        }
      }
    },
    async removeData(id) {
      const signature = await signMessage(" remove item " + id);
      console.log(signature);
      if (!signature) return;
      const url =
        "https://nd9gajq3lj.execute-api.us-east-1.amazonaws.com/star/collectionItems/remove/";
      axios.get(url + id).then(
        (res) => {
          if (res.data.statusCode == 200) {
          }
        },
        (err) => console.log(err)
      );
    },
    removeNFT() {
      this.showImportPopup = false;
      this.showCreateBtn = false;
      // document.getElementById("top-background").style.background =
      //   "rgba(0, 0, 0, 0.5);";
    },
    remove() {
      this.collectionDetailData.items.forEach((item) => {
        if (item.showCheckIcon) {
          this.removeData(item.collection_item_id);
          alert("success");
          this.$router.push({ name: "collections" });
        }
      });
    },
    cancel() {
      this.showCreateBtn = true;
    },
    checkCollection(item) {
      if (item.showCheckIcon) {
        item.showCheckIcon = false;
      } else {
        item.showCheckIcon = true;
      }
    },
  },

  created() {
    this.getCollectionDetail();
  },
};
</script>

<style lang="scss" scoped>
.collection-detail-top {
  width: 100%;
  padding-bottom: 14px;
  border-bottom: 1px solid #f4f4f4;
  .top-background {
    width: 100%;
    height: 68px;
    background: #f4f4f4;
    padding-top: 30px;
    margin-bottom: 83px;
    .logo {
      position: relative;
      width: 1218px;
      margin: auto;
      .collection-logo-img {
        margin: auto;
        width: 136px;
        height: 136px;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
        border: 4px solid #ffffff;
        border-radius: 50%;
        img {
          width: 136px;
          height: 136px;
          border-radius: 50%;
          object-fit: contain;
        }
      }
      .btn-img {
        display: flex;
        position: absolute;
        right: 0px;
        bottom: 9px;
        .img-more {
          width: 44px;
          height: 44px;
          cursor: pointer;
        }
        .img-additem {
          width: 121px;
          height: 44px;
          cursor: pointer;
        }
        .import-popup {
          position: absolute;
          top: 42px;
          // right: 82px;
          right: 130px;
        }
      }
      .right-icon-btn {
        display: flex;
        position: absolute;
        right: 0px;
        bottom: 9px;
        .right-remove-btn {
          margin-top: 6px;
          cursor: pointer;
          display: flex;
          width: 121px;
          height: 22px;
          background: #ffffff;
          border-radius: 10px;
          border: 1px solid #f48989;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #f48989;
          line-height: 22px;
          padding: 5px 0 5px 20px;
          .btn-remove-icon img {
            width: 14px;
            height: 14px;
            margin: 4px 10px 0 0px;
          }
        }
        .right-cancel-btn {
          margin-top: 6px;
          margin-left: 14px;
          cursor: pointer;
          display: flex;
          width: 111px;
          height: 22px;
          background: #ccbdf7;
          border-radius: 10px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 22px;
          padding: 5px 0 5px 20px;

          .btn-cancel-icon img {
            width: 15px;
            height: 15px;
            margin: 4px 7px 0 0px;
          }
        }
        .right-cancel-btn:hover {
          background: #b8a0fd;
        }
      }
    }
    .check-modal {
      cursor: pointer;
      position: absolute;
      left: 240px;
      bottom: 262px;
      width: 24px;
      height: 24px;
      background: #ffffff;
      border: 1px solid #979797;
      border-radius: 50%;
      .check-icon {
        position: relative;
        top: 4px;
        left: 2px;
        img {
          width: 20px;
          height: 16px;
        }
      }
    }
  }
  .collection-detail-info {
    width: 1218px;
    margin: auto;
    text-align: center;
    // border: 1px solid red;
    .collection-name-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 40px;
      font-size: 28px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 40px;
    }
    .collection-description-text {
      width: 528px;
      height: 48px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 22px;
      margin: auto;
      overflow: hidden;
    }
    img {
      width: 14px;
      height: 10px;
      cursor: pointer;
    }
  }
}
.collection-detail-bottom {
  width: 1218px;
  margin: auto;
  margin-bottom: 80px;
  .detail-mid {
    display: flex;
    margin-top: 20px;
    .left-text {
      height: 32px;
      margin: 0 728px 10px 8px;
      // border: 1px solid red;
      .item-count-text {
        width: 12px;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #4220a5;
        line-height: 32px;
        margin-right: 7px;
      }
      .items-text {
        width: 52px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #666666;
        line-height: 32px;
      }
    }
    .mid-right {
      width: 402px;
      display: flex;
      justify-content: space-between;
      .right-filter {
        width: 160px;
        height: 30px;
        background: #ffffff;
        border-radius: 6px;
        border: 1px solid #9d9d9d;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #888888;
        line-height: 30px;
        padding-left: 32px;
        position: relative;
        cursor: pointer;
        .filter-icon-sort {
          position: absolute;
          top: 2px;
          left: 10px;
          img {
            width: 16px;
            height: 16px;
          }
        }
        .filter-down-icon {
          position: absolute;
          top: -3px;
          right: 12px;
          img {
            width: 7.49px;
            height: 4.19px;
          }
        }
      }
    }
  }
  .nft-list {
  }
}

.popup-modal-body {
  display: flex;
  .import-nft-tokenId {
    position: relative;
    .tokenId-text {
      position: absolute;
      width: 63px;
      // height: 33px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 33px;
      top: 3px;
      left: 9px;
    }
    .import-nft-tokenId-input {
      outline: none;
      width: 137px;
      height: 33px;
      border-radius: 7px;
      border: 1px solid #999999;
      padding-left: 78px;
    }
  }
}
.popup-modal-body-input {
  position: absolute;
  top: 118px;
  outline: none;
  width: 438px;
  height: 33px;
  border-radius: 7px;
  border: 1px solid #999999;
  padding-left: 10px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}
.tip-div {
  position: absolute;
  top: 155px;
  .tip-img {
    width: 10px;
    height: 10px;
    margin-right: 3px;
  }
  .tip-text {
    margin-top: 5px;
    width: 421px;
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #cb8d71;
    line-height: 17px;
  }
}
</style>