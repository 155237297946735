<template>
  <HomePageTop :defaultKey="0"></HomePageTop>
  <div class="my-collections">
    <div class="top-title">
      <div class="left-title-text">My Collections</div>
      <div class="right-icon-btn" v-if="showCreateBtn">
        <img
          class="img-more"
          src="../../assets/btn_more@2x.png"
          @click="handleImportPopup"
        />
        <EditPopup
          class="import-popup"
          v-show="showImportPopup"
          @to="handleImportModal"
          @remove="showRemove"
        >
          <template #first-menu>Import Collection</template>
          <template #second-menu>Remove</template>
        </EditPopup>
        <div class="right-create-btn">
          <div class="btn-add-icon">
            <img src="../../assets/create_collection_icon.png" />
          </div>
          <div class="btn-text" @click="create">Create Collection</div>
        </div>
      </div>
      <div class="right-icon-btn" v-else>
        <div class="right-remove-btn" @click="remove">
          <div class="btn-remove-icon">
            <img src="../../assets/icon_remove.png" />
          </div>
          <div class="btn-text">Remove</div>
        </div>
        <div class="right-cancel-btn" @click="cancel">
          <div class="btn-cancel-icon">
            <img src="../../assets/icon_cancel@2x.png" />
          </div>
          <div class="btn-text">Cancel</div>
        </div>
      </div>
    </div>
    <div v-for="(item, index) in data" :key="index" class="mid-data">
      <div
        class="top-backgoround"
        @click="detail(item.collection_name)"
        v-if="showCreateBtn"
      >
        <img :src="item.logo_image" />
      </div>
      <div class="top-backgoround" id="top-background" v-else>
        <img :src="item.logo_image" />
        <div class="check-modal" @click="checkCollection(item)">
          <div class="check-icon" v-show="item.showCheckIcon">
            <img src="../../assets/icon_cancel_big.png" />
          </div>
        </div>
      </div>
      <div class="mid-icon">
        <div class="icon"><img :src="item.logo_image" /></div>
        <div class="more-icon" @click="handleEdit(item)">
          <img src="../../assets/collection_icon_more.png" />
          <EditPopup
            class="edit-popup"
            v-show="item.showEditPopup"
            @to="toEdit(item)"
            @remove="toEdit"
          >
            <template #first-menu>Edit</template>
            <template #second-menu>Creator Earnings</template>
          </EditPopup>
        </div>
      </div>
      <div class="bottom-content">
        <div class="name-text">{{ item.collection_name }}</div>
        <div>
          <span class="count-text">{{ item.item_count }}</span
          ><span class="items-text">Items</span>
        </div>
        <div class="discription-text">{{ item.description }}</div>
      </div>
    </div>
  </div>
  <PopupModal
    v-show="showImportModal"
    @close="handleImportModal"
    @ok="importCollection"
    >Import Collection
    <template #body>
      <div class="popup-modal-body">
        <!-- <div class="chain-network-box">
          <img src="../../assets/icon_eth_big.png" />
          <span>Ethereum</span>
          <span class="icon">></span>
        </div> -->
        <DropDownBox
          width="97"
          height="33"
          type="blockchain"
          isMini="true"
          importCollection="true"
        />
        <input
          type="text"
          class="popup-modal-body-input"
          placeholder="e.g 0x0000..."
          v-on:focusout="verifyContractAddress"
          v-model="contractAddress"
        />

        <div v-show="showTipText" class="tip-div">
          <img class="tip-img" src="../../assets/icon_hint.png" />
          <span class="tip-text"
            >You need to be the owner of the contract in order to import this
            collection.</span
          >
        </div>
      </div>
    </template>
  </PopupModal>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import axios from "axios";
import metadata from "../../contracts/metadata/mynft.json";
import { signMessage } from "../../util/sign";
import Web3 from "web3";
import nftplatform from "../../constant/contracts/nftplatform.json";
export default {
  name: "",
  data() {
    return {
      data: [
        // {
        //   logo_image: require("../../assets/panda.png"),
        //   collection_name: "PhantaBear ",
        //   item_count: "0",
        //   description: "11",
        // },
      ],
      showImportPopup: false,
      showImportModal: false,
      showTipText: false,
      contractAddress: "",
      collection_name: "",
      showCreateBtn: true,
    };
  },
  watch: {
    contractAddress(newVal, oldVal) {
      console.log(newVal, newVal.length);
      if (
        newVal !== "0x0000000000000000000000000000000000000000" &&
        newVal?.length >= 42
      ) {
        this.verifyContractAddress();
        if (!this.showTipText) {
          document.getElementById("component-button-box-id").style.background =
            "#b082fa";
        }
      }
    },
    account(val) {
      this.getCollections(val);
    },
  },
  computed: {
    account() {
      let account = this.$store.getters.getWeb3Value.userAddress;
      return account;
    },
    web3() {
      return this.$store.getters.getWeb3Value.web3;
    },
    ...mapGetters(["getImportCollectionBlockChain"]),
  },
  created() {
    this.getCollections(this.account);
  },
  methods: {
    ...mapActions(["setCollection"]),
    toEdit(item) {
      this.setCollection(item);
      this.$router.push("/collection/edit/" + item.collection_name);
    },
    handleImportPopup() {
      this.showImportPopup = !this.showImportPopup;
    },
    handleImportModal() {
      this.showTipText = false;
      document.getElementById("component-button-box-id").style.background =
        "#b8a0fd";
      this.contractAddress = "";
      this.showImportModal = !this.showImportModal;
      this.showImportPopup = false;
    },
    handleEdit(item) {
      if (item.showEditPopup) {
        item.showEditPopup = false;
      } else {
        item.showEditPopup = true;
      }
    },
    detail(name) {
      this.$router.push("/collection/" + name);
    },
    async verifyContractAddress() {
      let contract;
      let address;
      let chainId = this.getImportCollectionBlockChain.chainId;
      try {
        const chain = nftplatform.chains[chainId];
        if (!chain) {
          throw new Error("ChainId missing or not supported");
        }
        const web3 = new Web3(new Web3.providers.HttpProvider(chain.rpcUrl));
        contract = new web3.eth.Contract(metadata.abi, this.contractAddress);
        address = await contract.methods.owner().call();
        console.log("contract", contract, address);
      } catch (err) {
        this.showTipText = true;
        document.getElementById("component-button-box-id").style.background =
          "#b8a0fd";
        return;
      }
      // this.collection_name = await contract.methods.name().call();
      const accounts = await this.web3.eth.getAccounts();
      if (accounts[0].toLowerCase() !== address.toLowerCase()) {
        this.showTipText = true;
      } else {
        this.showTipText = false;
      }
    },
    importCollection() {
      if (
        this.contractAddress !== "0x0000000000000000000000000000000000000000" &&
        this.contractAddress?.length >= 42
      ) {
        this.verifyContractAddress();
        if (!this.showTipText) {
          this.$router.push("/collection/import/" + this.contractAddress);
        }
      }
    },
    async removeData(id) {
      const signature = await signMessage(" remove collection " + id);
      console.log(signature);
      if (!signature) return;
      const url =
        " https://nd9gajq3lj.execute-api.us-east-1.amazonaws.com/star/collection/remove/";
      await axios.get(url + id).then(
        (res) => {
          console.log("结果", res);
          if (res.data.statusCode == 200) {
            location.replace(location);
          }
        },
        (err) => console.log(err)
      );
    },
    create() {
      this.$router.push({ name: "CreateCollection" });
    },
    showRemove() {
      this.showImportPopup = false;
      this.showCreateBtn = false;
      // document.getElementById("top-background").style.background =
      //   "rgba(0, 0, 0, 0.5);";
    },
    remove() {
      this.data.forEach((item) => {
        if (item.showCheckIcon) {
          this.removeData(item.collection_id);
          this.$router.push({ name: "collections" });
        }
      });
    },
    cancel() {
      this.showCreateBtn = true;
    },
    checkCollection(item) {
      if (item.showCheckIcon) {
        item.showCheckIcon = false;
      } else {
        item.showCheckIcon = true;
      }
    },
    getCollections(account) {
      if (!account) return;
      const url =
        "https://nd9gajq3lj.execute-api.us-east-1.amazonaws.com/star/mycollections/";
      // const url =
      //   "https://ipfs.infura.io/ipfs/QmeHSpyg39JPJkqCLoAJPAVGJ9gGZ8Bjo4W8Msaa47iPKR";
      console.log("this.account", this.account);
      axios.get(url + account).then(
        (res) => {
          if (res.data.statusCode == 200) this.data = res.data.body;
          console.log(res);
        },
        (err) => {
          console.log(err);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.my-collections {
  width: 966px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 23px;
  .top-title {
    display: flex;
    justify-content: space-between;
    margin: 0 15px 0 15px;
    .left-title-text {
      font-size: 28px;
      font-family: Arial-BoldMT, Arial;
      font-weight: normal;
      color: #333333;
      line-height: 44px;
    }
    .right-icon-btn {
      display: flex;
      position: relative;
      .img-more {
        width: 44px;
        height: 44px;
        margin-top: 2px;
        cursor: pointer;
      }
      .import-popup {
        position: absolute;
        top: 42px;
        right: 176px;
        z-index: 9999;
      }
      .right-create-btn {
        cursor: pointer;
        display: flex;
        box-shadow: 0px 2px 6px 0px rgba(101, 48, 255, 0.21);
        background-color: rgba(255, 255, 255, 1);
        border-radius: 10px;
        height: 22px;
        width: 161px;
        color: rgba(116, 73, 252, 1);
        font-size: 16px;
        padding: 5px 0 5px 9px;
        margin-top: 6px;
        .btn-add-icon {
          margin-top: 2px;
        }
        .btn-text {
          margin-left: 5px;
        }
      }
      .right-remove-btn {
        margin-top: 6px;
        cursor: pointer;
        display: flex;
        width: 121px;
        height: 22px;
        background: #ffffff;
        border-radius: 10px;
        border: 1px solid #f48989;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #f48989;
        line-height: 22px;
        padding: 5px 0 5px 20px;
        .btn-remove-icon img {
          width: 14px;
          height: 14px;
          margin: 4px 10px 0 0px;
        }
      }
      .right-cancel-btn {
        margin-top: 6px;
        margin-left: 14px;
        cursor: pointer;
        display: flex;
        width: 111px;
        height: 22px;
        background: #ccbdf7;
        border-radius: 10px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 22px;
        padding: 5px 0 5px 20px;

        .btn-cancel-icon img {
          width: 15px;
          height: 15px;
          margin: 4px 7px 0 0px;
        }
      }
      .right-cancel-btn:hover {
        background: #b8a0fd;
      }
    }
  }
  .mid-data {
    display: inline-block;
    margin: 15px 15px 0px 15px;
    width: 290px;
    height: 306px;
    background: #ffffff;
    border-radius: 17px;
    border: 1px solid #dedede;
    position: relative;

    .top-backgoround {
      border-radius: 17px 17px 0px 0px;
      // opacity: 0.7;
      img {
        width: 290px;
        height: 158px;
        border-radius: 17px 17px 0px 0px;
        object-fit: cover;

        //
      }
      .check-modal {
        cursor: pointer;
        position: absolute;
        left: 240px;
        bottom: 262px;
        width: 24px;
        height: 24px;
        background: #ffffff;
        border: 1px solid #979797;
        border-radius: 50%;
        .check-icon {
          position: relative;
          top: 4px;
          left: 2px;
          img {
            width: 20px;
            height: 16px;
          }
        }
      }
    }
    .mid-icon {
      width: 250px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      justify-content: space-between;
      //   border: 1px solid red;
      .icon {
        position: relative;
        top: -34px;
        width: 54px;
        height: 54px;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
        border: 2px solid #ffffff;
        border-radius: 50%;
        img {
          width: 54px;
          height: 54px;
          border-radius: 50%;
          box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
          object-fit: contain;
        }
      }
      .more-icon {
        width: 20px;
        height: 5px;
        cursor: pointer;
        img {
          width: 20px;
          height: 5px;
        }
        .edit-popup {
          position: relative;
          right: 110px;
          bottom: 85px;
          z-index: 9999999;
        }
      }
    }
    .bottom-content {
      position: relative;
      top: -34px;
      margin: 5px 0px 20px 20px;
      .name-text {
        width: 220px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 25px;
        margin-bottom: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .count-text {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #4220a5;
        line-height: 20px;
        margin-right: 6px;
      }
      .items-text {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
      }
      .discription-text {
        width: 251px;
        height: 38px;
        margin-top: 11px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        // white-space: nowrap;
      }
    }
  }
}
.popup-modal-body {
  position: relative;
  display: flex;
  .chain-network-box {
    width: 97px;
    height: 33px;
    border-radius: 7px;
    border: 1px solid #999999;
    padding-left: 7px;
    margin-right: 10px;
    img {
      // width: 13px;
      // height: 13px;
      width: 8px;
      height: 12px;
      margin-right: 4px;
    }
    span {
      // height: 33px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 33px;
    }
    .icon {
      margin-left: 7px;
      width: 6px;
      height: 6px;
    }
  }
  .popup-modal-body-input {
    outline: none;
    width: 440px;
    height: 33px;
    border-radius: 7px;
    border: 1px solid #999999;
    padding-left: 10px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
  }
}
.tip-div {
  position: absolute;
  top: 38px;
  .tip-img {
    width: 10px;
    height: 10px;
    margin-right: 3px;
  }
  .tip-text {
    margin-top: 5px;
    width: 421px;
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #cb8d71;
    line-height: 17px;
  }
}
</style>