<template>
  <div class="modal">
    <div class="title-text">
      <span>Logo image</span>
      <span class="require-star-logo">&nbsp;*</span>
    </div>
    <StarUploads
      class="upload"
      :limit="1"
      :type="'image'"
      :data="{}"
      :action="''"
      :autoName="1"
      :size="5 * 1024 * 1024"
      :multiple="0"
      width="928"
      height="160"
      uploadType="collection"
      :modalType="type"
      :url="collection.logo_image"
    >
    </StarUploads>

    <div class="title-text">
      <span>Name</span>
      <span class="require-star-logo">&nbsp;*</span>
    </div>
    <input
      type="text"
      v-model="collection.collection_name"
      class="input require-input-border"
      placeholder="Collection name"
      required="required"
    />
    <div class="title-text">
      <span>Custom URL</span>
    </div>
    <input
      type="text"
      v-model="collection.custom_url"
      class="input other-input-border"
      placeholder="https://yoursite.io/item/123"
    />
    <div class="title-text">
      <span>Description</span>
      <div class="description-text">
        Spread some words about your token collection
      </div>
    </div>
    <textarea
      v-model="collection.description"
      class="description-textarea"
      placeholder="Provide a detailed description of your collection."
    ></textarea>
    <div class="title-text">
      <span>Category</span>
    </div>
    <!-- <SelectModal width="928" :height="54" type="category" /> -->
    <DropDownBox width="928" height="54" type="category" />
    <div class="title-text">
      <span>Creator Earnings</span>
      <div class="description-text">Maximum is 10%</div>
    </div>
    <div class="description-input-box">
      <input
        type="text"
        class="input other-input-border description-input"
        v-model="collection.creator_earnings"
      />
      <span class="percent-text">%</span>
    </div>
    <div>
      <div class="title-text">
        <span>Blockchain</span>
      </div>
      <DropDownBox
        width="928"
        height="66"
        type="blockchain"
        :disabled="disabled"
      />
    </div>
    <div class="bottom-button">
      <input
        class="create-button"
        type="submit"
        value="Submit"
        @click="submit"
        id="submit-button"
      />
      <input
        v-show="showDeleteButton"
        class="delete-button"
        type="submit"
        value="Delete"
        @click="remove"
      />
    </div>
  </div>
</template>

<script>
import { inject, computed, reactive, toRefs } from "vue";
import { mapGetters, useStore } from "vuex";
import axios from "axios";
import metadata from "../../contracts/metadata/mynft.json";
import { signMessage } from "../../util/sign";
import DropDownBox from "../common/DropDownBox.vue";
import { switchChain } from "../../web3/tools.js";
export default {
  name: "CollectionModal",
  data() {
    return {
      showDeleteButton: false,
      collection: {},
    };
  },
  props: {
    type: {
      type: String,
      default: "create",
    },
  },
  computed: {
    web3() {
      return this.$store.getters.getWeb3Value.web3;
    },
    disabled() {
      if (this.type == "import") {
        return true;
      }
      return false;
    },
    ...mapGetters(["getCollectionBlockChain"]),
  },
  watch: {
    web3() {
      if (this.type == "import") {
        this.verifyContractAddress(this.$route.params.address);
      }
    },
  },
  methods: {
    async getAccount() {
      if (!this.collection.collection_owner)
        this.collection.collection_owner =
          this.$store.getters.getWeb3Value.userAddress;
      return this.collection.collection_owner;
    },
    getCollectionData(param) {
      const url =
        "https://nd9gajq3lj.execute-api.us-east-1.amazonaws.com/star/collection/";
      axios
        .get(url + param)
        .then(
          (response) => {
            if (!response.data.statusCode) {
              console.log(response.data.errorMessage);
            }
            if (response.data.statusCode !== 200) {
              alert(response.data.msg);
            }
            this.collection = [...response.data];
            console.log(res);
          },
          (error) => console.log(error)
        )
        .catch((error) => {
          console.log(error);
        });
    },
    async deploy(name) {
      if (!this.web3) return;
      // const chainId = await this.web3.eth.getChainId();
      const chainId = this.getCollectionBlockChain.chainId;
      const result = await switchChain(chainId);
      if (!result) {
        return;
      }
      const accounts = await this.web3.eth.getAccounts();
      console.log(accounts[0], chainId);
      let contract = new this.web3.eth.Contract(metadata.abi);
      console.log("contract", contract);
      contract = contract.deploy({
        data: metadata.bytecode,
        arguments: [name, name],
      });
      console.log("contract.deploy", contract);
      return await contract.send({ from: accounts[0] }).then((res, error) => {
        if (error) console.log(error);
        else if (res) {
          console.log("ContractInstance", res);
          this.collection.contract_address = res.options.address;
          this.collection.collection_owner = accounts[0].toLowerCase();
          this.collection.block_chain = chainId;
          //   return res.options.address;
          //   this.hastokenAddress = true;
        }
      });
    },
    async saveData(url) {
      console.log("编辑签名信息" + JSON.stringify(this.collection));
      const signature = await signMessage(JSON.stringify(this.collection));
      console.log(signature);
      if (!signature) return;
      axios.post(url, { ...this.collection }).then(
        (res) => {
          if (!res.data.statusCode) {
            alert(res.data.errorMessage);
          }
          if (res.data.statusCode !== 200) {
            alert(res.data.msg);
          } else {
            alert("success");
            this.$router.push({ name: "collections" });
            // location.replace(location);
          }
          console.log(res);
        },
        (err) => alert(err)
      );
    },
    async submit() {
      if (this.$store.getters.getImageUrl !== "") {
        this.collection.logo_image = this.$store.getters.getImageUrl;
      }
      this.collection.category = "Collectible";
      if (!this.collection.collection_name) {
        alert("collection name cannot be empty！");
        return;
      }
      if (!this.collection.logo_image) {
        // alert("collection image cannot be empty！");
        this.collection.logo_image =
          "https://ipfs.moralis.io:2053/ipfs/QmVC6JSidCGZsJuA7QX3BFJeaSmjVkXtAZ6whaudoatDZw";
        // return;
      }
      let url;
      if (this.type == "create") {
        // create
        await this.deploy(this.collection.collection_name);
        url =
          "https://nd9gajq3lj.execute-api.us-east-1.amazonaws.com/star/collection/create";
        if (this.collection.contract_address) {
          this.saveData(url);
        }
      } else if (this.type == "edit") {
        // edit
        url =
          "https://nd9gajq3lj.execute-api.us-east-1.amazonaws.com/star/collection/edit";
        this.saveData(url);
      } else if (this.type == "import") {
        url =
          "https://nd9gajq3lj.execute-api.us-east-1.amazonaws.com/star/collection/create";
        this.saveData(url);
      }
    },
    async verifyContractAddress(contractAddress) {
      let contract;
      let address;
      if (!this.web3) return;
      try {
        contract = new this.web3.eth.Contract(metadata.abi, contractAddress);
        address = await contract.methods.owner().call();
        console.log("contract", contract, address);
      } catch (err) {
        this.$router.push({ name: "nFound" });
      }
      const accounts = await this.web3.eth.getAccounts();
      const chainId = await this.web3.eth.getChainId();
      if (accounts[0].toLowerCase() !== address.toLowerCase()) {
        this.$router.push({ name: "nFound" });
      }
      this.collection.collection_name = await contract.methods.name().call();
    },
  },
  created() {
    if (this.type == "edit") {
      // this.showDeleteButton = true;
      this.collection = this.$store.getters.getCollection;
      // this.getCollectionData(this.$route.params.param);
      // console.log("编辑签名信息" + JSON.stringify(this.collection));
      // const collectionName = this.$route.params.param.replaceAll(" ", "");
      // this.getCollectionData(collectionName);
      this.disabled = "true";
    } else if (this.type == "import") {
      this.verifyContractAddress(this.$route.params.address);
    }
    this.getAccount();
  },
  setup(props, context) {
    const store = useStore();
    const data = reactive({
      imageUrl: "",
    });
    let url = computed(() => {
      data.imageUrl = inject("imageUrl");
      return data.imageUrl;
    });
    const dataRef = toRefs(data);
    return { ...dataRef };
  },
  components: { DropDownBox },
};
</script>

<style lang="scss" scoped>
.modal {
  padding-bottom: 100px;
  .title-text {
    margin: 24px 0 15px 0;
    .require-star-logo {
      color: rgba(217, 70, 70, 1);
    }
    .description-text {
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #999999;
      margin: 3px 0 5px 0;
    }
  }
  .input {
    outline: 0;
    width: 896px;
    height: 54px;
    border-radius: 10px;
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 28px;
    padding-left: 32px;
  }
  .require-input-border {
    border: 1px solid #999999;
  }
  .other-input-border {
    border: 1px solid #dedede;
  }
  .description-textarea {
    outline: 0;
    width: 854px;
    height: 100px;
    border-radius: 10px;
    border: 1px solid #dedede;
    padding: 14px 42px 0 32px;
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
  }
  .description-input-box {
    position: relative;
    display: flex;
    .percent-text {
      width: 20px;
      height: 28px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 28px;
      position: absolute;
      top: 13px;
      left: 879px;
    }
  }
  .description-input {
    padding-right: 49px;
  }
  .bottom-button {
    margin-top: 30px;
    display: flex;
    width: 518px;
    justify-content: space-between;
    // border-radius: 10px;
    .create-button {
      cursor: pointer;
      width: 244px;
      height: 52px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      background: linear-gradient(
        315deg,
        #7c42fc 0%,
        #6159fc 100%,
        #6159fc 100%
      );
      //   background: #b8a0fd;
      border-radius: 10px;
      border: 2px solid #6159fc;
      //   border-color: #6159fc;
    }
    .delete-button {
      width: 244px;
      height: 52px;
      border-radius: 10px;
      border: 2px solid #f48989;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #f48989;
      background: #fff;
    }
  }
}
</style>