<template>
  <div class="table">
    <div class="table-title">
      <span class="title-span-img">Image</span>
      <span class="title-span-name">Name</span>
      <span class="title-span-address">Contract Address</span>
      <span class="title-span-from">From</span>
      <span class="title-span-date">Date</span>
      <!-- <span>Expiring in</span> -->
    </div>
    <div class="social-text">{{ type }} ({{ nftPreviewList.length }})</div>
    <div class="table-row" v-for="(item, index) in nftPreviewList" :key="index">
      <div
        v-if="!item.isCheck && !item.isClaim"
        class="select-modal"
        id="select"
        @click="selectClick(index)"
      ></div>
      <div
        v-else-if="item.isCheck && !item.isClaim"
        class="select-modal bg"
        id="select"
        @click="selectClick(index)"
      >
        <div class="check-icon">
          <img src="../assets/icon_all_cancel.png" />
        </div>
      </div>
      <div v-else-if="item.isClaim" class="select-modal bgClaim"></div>
      <div class="nft-img margin-space">
        <img :src="item.token_uri ? item.token_uri : item.image_url" />
      </div>
      <div class="name-text margin margin-space">
        <div>{{ item.name ? item.name : "SYD MEAD" }}</div>
        <div>#{{ item.token_id }}</div>
      </div>
      <div class="contract-address-text margin margin-space">
        <div class="icon-modal">
          <!-- ../assets/icon_eth.png -->
          <div class="icon"><img :src="item.icon" /></div>
        </div>
        <div class="address-text">
          {{ item.tokenAddress }}
        </div>
      </div>
      <div class="from-modal">
        <div
          class="from-account-text margin margin-space"
          @click="handleCard(index)"
        >
          <div class="logo-modal">
            <div class="logo-img">
              <img v-if="!isPriority" src="../assets/logo_twitter.png" />
              <img class="logo-img-eth" src="../assets/icon_eth.png" v-else />
            </div>
          </div>
          <div class="uid-text">
            {{ item.uid }}
          </div>
        </div>
        <SocialCard v-show="item.showCard" class="social-card" />
      </div>
      <div class="date-text">
        <span>{{ item.date }} ago</span>
        <img src="../assets/icon_open.png" />
      </div>
      <!-- <div class="expired-text">2min</div> -->
    </div>
    <div class="bottom-modal">
      <div class="bottom-content">
        <div class="select">
          <div class="select-all-modal" id="select-all" @click="selectAllClick">
            <div v-show="showSelectAllIcon" class="check-all-icon">
              <img src="../assets/icon_all_cancel.png" />
            </div>
          </div>
          <div class="text">Select all</div>
        </div>
        <Button class="claim-button" @click="claim" v-show="!isPriority"
          >Claim</Button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import nftplatform from "../constant/contracts/nftplatform.json";
import { getBindUidHandler, claimHandler } from "../hooks/nftplatform.js";
import { intervalTime } from "../utils/timeutil.js";
import { ethers } from "ethers";
const nftplatformABI = nftplatform.abi;
const nftplatformAddress = nftplatform.contractAddress;
const chains = nftplatform.chains;
export default {
  name: "SyntheticForm",
  data() {
    return {
      nftlist: [],
      nftPreviewList: [],
      checkedList: [],
      account: "",
      flag: false,
      chainsIcon: {
        80001: require("../assets/matic.jpg"),
        97: require("../assets/bnb.jpg"),
        43113: require("../assets/avax.jpg"),
        5: require("../assets/icon_eth.png"),
      },
      socialIcon: [
        require("../assets/logo_twitter.png"),
        require("../assets/logo_facebook.png"),
        require("../assets/logo_ins.png"),
        require("../assets/logo_tiktok.png"),
        require("../assets/logo_youtube.png"),
        require("../assets/logo_discord.png"),
      ],
    };
  },
  props: {
    type: {
      type: String,
      default: "Aster",
    },
    index: {
      type: [String, Number],
      default: 6,
    },
  },
  computed: {
    ethereum() {
      return this.$store.getters.getWeb3Value;
    },
  },
  setup(props, context) {
    let type = props.type;
    const isPriority = inject("isPriority");
    let showSelectIcon = ref(false);
    return {
      showSelectIcon,
      isPriority,
      type,
    };
  },
  methods: {
    selectAllClick() {
      if (showSelectAllIcon.value) {
        showSelectAllIcon.value = false;
        showSelectIcon.value = false;
        document.getElementById("select").style.background = "#ffffff";
        document.getElementById("select-all").style.background = "#ffffff";
      } else {
        showSelectAllIcon.value = true;
        showSelectIcon.value = true;
        document.getElementById("select").style.background = "#6530ff";
        document.getElementById("select-all").style.background = "#6530ff";
      }
    },
    selectClick(index) {
      // if (this.nftlist[index].isClaim) {
      //   return;
      // }
      if (this.nftlist[index].isCheck) {
        this.nftlist[index].isCheck = false;
        for (let i = 0; i < this.checkedList.length; i++) {
          if (
            this.nftlist[index].token_address ==
              this.checkedList[i].token_address &&
            this.nftlist[index].token_id == this.checkedList[i].token_id
          ) {
            this.checkedList.splice(i, 1);
            i -= 1;
          }
        }
      } else if (!this.nftlist[index].isCheck) {
        this.nftlist[index].isCheck = true;
        this.checkedList.push(this.nftlist[index]);
      }
    },
    handleCard(index) {
      if (this.nftlist[index].showCard) {
        this.nftlist[index].showCard = false;
      } else {
        this.nftlist[index].showCard = true;
      }
    },
    async getSingleList(uid) {
      const url = `https://pij3k7x783.execute-api.ca-central-1.amazonaws.com/default/NFT_getListFunction/${uid}`;
      console.log("url", url, uid);
      let list;
      await axios
        .get(url)
        .then((res) => {
          if (res.data.statusCode == 200) {
            list = [...res.data.body.items];
            list.forEach((item) => {
              const address = item.token_address;
              //todo index=6 aster展示所有时，没法对应展示绑定平台社交icon
              item.socialIcon = this.socialIcon[this.index];
              item.date = intervalTime(item.time_stamp, 0);
              item.icon = this.chainsIcon[item.chain_id];
              if (address.length > 12) {
                item.tokenAddress =
                  address.substring(0, 6) +
                  "..." +
                  address.substring(address.length - 4, address.length);
              }
            });
            if (this.nftlist.length == 0) {
              this.nftlist = list;
            } else {
              if (list) {
                list.forEach((item) => {
                  this.nftlist.push(item);
                });
              }
            }
            // console.log(this.nftlist);
            this.nftlist = this.nftlist.sort(
              (a, b) => b.time_stamp - a.time_stamp
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getList() {
      let uid;
      if (!this.isPriority) {
        this.account = this.ethereum.userAddress;
        uid = await getBindUidHandler(this.account);
      } else {
        uid = ["twitter_" + this.$store.getters.getUidParam];
      }
      if (!uid || uid == "" || uid.length == 0) {
        return;
      }
      if (this.index == 6) {
        console.log(uid);
        if (uid.length == 1) {
          this.getSingleList(uid[0]);
        } else {
          uid.forEach((id) => {
            this.getSingleList(id);
          });
        }
      } else {
        await this.getSingleList(uid[this.index]);
      }
    },
    async claim() {
      if (this.flag) {
        return;
      }
      this.flag = true;
      if (this.checkedList.length == 0) {
        alert("Please check data");
        return;
      }
      let tokenIds = [];
      this.checkedList.forEach((item) => tokenIds.push(item.token_id));
      if (this.checkedList.length == 1) {
        const token = this.checkedList[0];
        // 不支持的链的nft提示
        const chain = chains[token.chain_id];
        if (!chain) {
          alert("不支持的链");
        }
        let res = await claimHandler(
          this.account,
          token.token_address,
          token.token_id
        );
        if (res) {
          if (!window.localStorage) {
            alert("浏览器不支持localstorage");
          } else {
            let storage = window.localStorage;
            let data = {
              tokenAddress: token.token_address,
              tokenId: token.token_id,
              txHash: res,
              chainId: token.chain_id,
            };
            storage.setItem("txHashIds_" + res, JSON.stringify(data));
          }
          for (let i = 0; i < this.nftlist.length; i++) {
            if (this.nftlist[i].isCheck) {
              if (
                this.nftlist[i].token_address == token.token_address &&
                this.nftlist[i].token_id == token.token_id
              ) {
                this.nftlist[i].isClaim = true;
              }
            }
          }
          // 有延迟不能马上刷新页面
          // location.replace(location);
          // for (let i = 0; i < this.nftlist.length; i++) {
          //   if (
          //     this.nftlist[i].token_address ==
          //       this.checkedList[0].token_address &&
          //     this.nftlist[i].token_id == this.checkedList[0].token_id
          //   ) {
          //     this.nftlist.splice(i, 1);
          //     i -= 1;
          //   }
          // }
        }
      } else {
        // 目前只支持同一个合约地址下的nft进行批量转出
        alert("暂不支持选择多个");
        // let res = batchClaimHandler(
        //   this.account,
        //   this.checkedList[0].token_address,
        //   tokenIds
        // );
      }
    },
    async loadLocalStorage() {
      let localStorageData = window.localStorage;
      for (let i = 0; i < localStorageData.length; i++) {
        let key = localStorageData.key(i);
        if (key.startsWith("txHashIds_")) {
          let storage = localStorageData.getItem(key);
          let s = JSON.parse(storage);
          let flag = true;
          for (let i = 0; i < this.nftlist.length; i++) {
            if (
              this.nftlist[i].token_address == s.tokenAddress &&
              this.nftlist[i].token_id == s.tokenId
            ) {
              flag = false;
              try {
                const provider = new ethers.providers.Web3Provider(
                  this.ethereum.provider
                );
                const res = await provider.getTransactionReceipt(s.txHash);
                this.nftlist[i].isClaim = true;
                //还需确认pending和失败的区别
                if (res.status && res.status !== 1 && res.blockHash) {
                  this.nftlist[i].isClaim = false;
                  localStorageData.removeItem(key);
                  debugger;
                }
              } catch (err) {
                console.log(err);
              }
            }
          }
          if (flag && this.nftlist.length > 0) {
            localStorageData.removeItem(key);
          } else if (flag && this.nftlist.length == 0) {
            //todo 需要根据local storage查询接口是否还存在，不存在则删除localstorage
          }
        }
      }
      this.nftlist.forEach((item) => {
        const fromAccount = item.from_account;
        if (!this.isPriority) {
          getBindUidHandler(fromAccount).then((res) => {
            //todo 无法知道是哪个社交平台的uid
            item.uid = res[0] + "@aster.so";
            if (res[0] && res[0].length > 13) {
              item.uid = res[0].substring(0, 13) + "...@aster.so";
            }
          });
        } else {
          if (item.from_account.length > 17) {
            item.uid = item.from_account.substring(0, 17) + "...";
          }
        }
      });
      // this.nftPreviewList = this.nftlist;
    },
  },
  watch: {
    nftlist: {
      deep: true,
      handler(newVal, oldVal) {
        if (oldVal.length == 0 && newVal.length > 0) {
          this.loadLocalStorage();
        } else if (oldVal.length > 0 && newVal.length > 0) {
          this.nftPreviewList = newVal;
          console.log(this.nftPreviewList);
        }
      },
      //todo  newVal.length==0 和一个地方类似，在那里处理就好
    },
    ethereum() {
      this.getList();
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.table {
  background: #ffffff;
  border-radius: 14px;
  border: 1px solid #dedede;
  .table-title {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #606060;
    line-height: 36px;
    background: #f6f5f7;
    height: 36px;
    border-radius: 14px 14px 0 0;
    //   padding:0 49px 0 66px;
    .title-span-img {
      // width: 200px;
      // border: 1px solid red;
      margin: 0px 115px 0 66px;
    }
    .title-span-name {
      margin-right: 140px;
    }
    .title-span-address {
      margin-right: 167px;
    }
    .title-span-from {
      margin-right: 188px;
    }
    .title-span-date {
      margin-right: 100px;
    }
  }
  .table-row {
    display: flex;
    height: 80px;
    border-top: 1px solid #d8d8d8;
    .margin {
      margin-top: 19.5px;
    }
    .margin-space {
      margin-left: 20px;
      margin-right: 20px;
    }
    .select-modal {
      width: 18px;
      height: 18px;
      background: #ffffff;
      border: 1px solid #dedede;
      border-radius: 50%;
      margin-left: 20px;
      margin-top: 31px;
      cursor: pointer;
      .check-icon {
        position: relative;
        left: 3px;
        bottom: 3px;
        img {
          width: 11px;
          height: 8px;
        }
      }
    }
    .bg {
      background: #6530ff;
    }
    .bgClaim {
      background: #dbdbdb;
    }
    .nft-img {
      width: 60px;
      height: 60px;
      border-radius: 11px;
      margin-top: 10px;
      img {
        width: 60px;
        height: 60px;
        border-radius: 11px;
        object-fit: contain;
      }
    }
    .name-text {
      width: 190px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      text-align: center;
      div {
        width: 190px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .contract-address-text {
      width: 175px;
      height: 41px;
      background: #f0eef6;
      border-radius: 21px;
      display: flex;
      line-height: 41px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #666666;
      .icon-modal {
        position: fixed;
        width: 41px;
        height: 41px;
        background: #e9e5f6;
        border-radius: 21px;
        margin-right: 12px;
        .icon {
          position: relative;
          width: 20px;
          height: 20px;
          // left: 14.5px;
          left: 10.5px;
          top: 4px;
          img {
            width: 20px;
            height: 20px;
            // width: 12px;
            // height: 21px;
          }
        }
      }
      .address-text {
        position: relative;
        left: 51px;
      }
    }
    .from-modal {
      position: relative;
      .from-account-text {
        width: 248px;
        height: 41px;
        background: #f0eef6;
        border-radius: 21px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #666666;
        line-height: 41px;
        display: flex;
        .logo-modal {
          position: fixed;
          width: 41px;
          height: 41px;
          background: #e9e5f6;
          border-radius: 21px;
          .logo-img {
            position: relative;
            left: 11px;
            top: 4px;
            width: 19px;
            height: 19px;
            img {
              width: 19px;
              height: 19px;
            }
            .logo-img-eth {
              width: 14px;
              height: 21px;
              margin-left: 3.5px;
            }
          }
        }
        .uid-text {
          // width: 184px;
          // overflow: hidden;
          // text-overflow: ellipsis;
          // white-space: nowrap;
          position: relative;
          left: 51px;
        }
      }
      .social-card {
        position: absolute;
        top: 66px;
        left: 20px;
      }
    }

    .date-text {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      margin-top: 32px;
      margin-left: 30px;
      img {
        width: 16px;
        height: 16px;
        margin-left: 8px;
      }
    }
    .expired-text {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      margin-top: 29px;
      margin-left: 77px;
    }
  }
  .bottom-modal {
    position: fixed;
    right: 0%;
    bottom: 0%;
    width: 100%;
    height: 64px;
    background: #ffffff;
    box-shadow: -10px 0px 9px 0px rgba(191, 191, 191, 0.3);
    .bottom-content {
      width: 1200px;
      margin-left: auto;
      margin-right: auto;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      display: flex;
      justify-content: space-between;
      .select {
        display: flex;
        .select-all-modal {
          width: 24px;
          height: 24px;
          background: #ffffff;
          border: 1px solid #dedede;
          border-radius: 50%;
          margin-top: 20px;
          margin-right: 10px;
          cursor: pointer;
          .check-all-icon {
            position: relative;
            left: 4.5px;
            bottom: 2.5px;
            cursor: pointer;
            width: 14.5px;
            height: 11px;
            img {
              width: 14.5px;
              height: 11px;
            }
          }
        }
        .text {
          color: #333333;
          line-height: 64px;
        }
      }

      .claim-button {
        margin-top: 11px;
      }
    }
  }
  .social-text {
    height: 25px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 25px;
    margin: 6px 0px 9px 20px;
  }
}
</style>