import supportedChains from './chains';
import store from '../store';
import nftplatform from '../constant/contracts/nftplatform.json'

export function getChainData(chainId) {
  const chainData = supportedChains.filter((chain) => chain.chain_id === chainId)[0];
  console.log(chainId);
  if (!chainData) {
    throw new Error('ChainId missing or not supported');
  }

  const API_KEY = process.env.REACT_APP_INFURA_ID;

  if (
    chainData.rpc_url.includes('infura.io')
    && chainData.rpc_url.includes('%API_KEY%')
    && API_KEY
  ) {
    const rpcUrl = chainData.rpc_url.replace('%API_KEY%', API_KEY);
    return {
      ...chainData,
      rpc_url: rpcUrl,
    };
  }

  return chainData;
}


export async function switchChain(chainIdParam) {
  // const { ethereum } = window;
  const ethereum = store.getters.getWeb3Value
  const chain = nftplatform.chains[chainIdParam]
  // parseInt(ethereum.chainId, 16)
  // console.log("chainId", chainIdParam.toString(16), parseInt(chainIdParam.toString(16), 16))
  if (ethereum) {
    //如果当前nft所属链与钱包所在链不对，则提示用户切换
    if (chainIdParam !== ethereum.chainId) {
      try {
        await ethereum.provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: "0x" + chainIdParam.toString(16) }],
        });
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          if (!chain) {
            console.log("the chain is not supported", chainId)
            return
          }
          //支持的链则可以帮用户自动添加到钱包中
          try {
            await ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: "0x" + chainIdParam.toString(16),
                  chainName: chain.name,
                  rpcUrls: [chain.rpcUrl] /* ... */,
                  blockExplorerUrls: [chain.exploreUrl]
                },
              ],
            });
          } catch (addError) {
            // handle "add" error
            console.log("wallet_addEthereumChain error", addError)
            return false
          }
        }
        // handle other "switch" errors
        console.log("wallet_switchEthereumChain error", switchError)
        return false
      }
    }
    return true
  }
}
