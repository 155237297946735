<template>
  <div class="TabGroup">
    <!-- tabRef 这块本来是用来实现下划线动态切换特效的，暂时没有写效果 -->
    <!-- <div ref="barRef" class="tab-bar" :style="{ width: widthRef + 'px' }"></div> -->
    <div class="tab-top" v-if="showTop">
      <div ref="titsRef" class="tab-header" layout="row" layout-wrap>
        <div
          ref="titRef"
          :class="[{ active: activeKey == item.props.actKey }, 'tab-nav']"
          v-for="(item, index) in tabTitLists"
          :key="item"
          @click="onTabClick($event, item, index)"
        >
          {{ item.props.label }}
        </div>
      </div>
      <div class="tab-header-right">
        <SearchBox width="152" height="42" type="home" />
        <!-- <div class="right-filter">
            Time: First to Last
            <div class="filter-down-icon">
              <img src="../assets/icon_down.png" />
            </div>
          </div> -->
      </div>
    </div>
    <div class="tab-top-synthetic" v-else>
      <div
        v-for="(item, index) in tabTitLists"
        :key="index"
        @click="onTabClick($event, item, index)"
        class="tab-synthetic-nav"
        :class="tabCls(item)"
      >
        <!-- <div>{{item.props.label}}</div> -->
        <div v-if="item.props.label == 'aster'">
          <img
            v-if="activeKey == item.props.actKey"
            class="tab-top-icon"
            src="../assets/icon_all_s.png"
          />
          <img class="tab-top-icon" src="../assets/icon_all_n.png" v-else />
        </div>
        <div v-if="item.props.label == 'twitter'">
          <img
            v-if="activeKey == item.props.actKey"
            class="tab-top-icon"
            src="../assets/icon_twitter_s.png"
          />
          <img class="tab-top-icon" src="../assets/icon_twitter_n.png" v-else />
        </div>
        <div v-if="item.props.label == 'facebook'">
          <img
            v-if="activeKey == item.props.actKey"
            class="tab-top-icon"
            src="../assets/icon_facebook_s.png"
          />
          <img
            class="tab-top-icon"
            src="../assets/icon_facebook_n.png"
            v-else
          />
        </div>
        <div v-if="item.props.label == 'ins'">
          <img
            v-if="activeKey == item.props.actKey"
            class="tab-top-icon"
            src="../assets/icon_ins_s.png"
          />
          <img class="tab-top-icon" src="../assets/icon_ins_n.png" v-else />
        </div>
        <div v-if="item.props.label == 'tiktok'">
          <img
            v-if="activeKey == item.props.actKey"
            class="tab-top-icon"
            src="../assets/icon_tiktok_s.png"
          />
          <img class="tab-top-icon" src="../assets/icon_tiktok_n.png" v-else />
        </div>
        <div v-if="item.props.label == 'youtube'">
          <img
            v-if="activeKey == item.props.actKey"
            class="tab-top-icon"
            src="../assets/icon_youtube_s.png"
          />
          <img class="tab-top-icon" src="../assets/icon_youtube_n.png" v-else />
        </div>
        <div v-if="item.props.label == 'discord'">
          <img
            v-if="activeKey == item.props.actKey"
            class="tab-top-icon"
            src="../assets/icon_discord_s.png"
          />
          <img class="tab-top-icon" src="../assets/icon_discord_n.png" v-else />
        </div>
      </div>
    </div>
    <div v-show="!showTop" class="bottom-line"></div>
    <div class="TabPanel" :class="computedPanel">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, provide } from "vue";
export default {
  name: "TabGroup",
  props: {
    defaultKey: {
      type: String,
      default: "1",
    },
    type: {
      type: String,
      default: "default",
    },
  },
  computed: {
    computedPanel() {
      let clazz = [];
      if (this.type == "synthetic") {
        clazz.push("TabPanel-" + this.type);
      }
      return clazz;
    },
  },
  setup(props, context) {
    let showTop = true;
    let tabTitLists = context.slots.default();
    let activeKey = ref(props.defaultKey); //当前key
    let type = ref(props.type);
    if (type.value == "synthetic") {
      showTop = false;
    }
    provide("activeKey", activeKey);

    const barRef = ref(null);
    const titRef = ref(null);
    let widthRef = ref();
    onMounted(() => {
      // 设置状态线初始化宽度
      // widthRef.value = titRef.value.clientWidth;
    });
    function onTabClick(event, tab, index) {
      activeKey.value = tab.props.actKey;
    }
    function tabCls(item) {
      var active = "";
      if (item.props.actKey == activeKey.value) {
        active = "tab-synthetic-nav-active";
      }
      return [active];
    }
    return {
      tabTitLists,
      barRef,
      titRef,
      widthRef,
      onTabClick,
      activeKey,
      showTop,
      tabCls,
    };
  },
};
</script>
<style scoped lang="scss">
.TabGroup {
  //   .tab-bar {
  //     position: absolute;
  //     left: 0;
  //     border-bottom: 2px solid #409eff;
  //   }
  // border: 1px solid red;
  .tab-top {
    // border: 1px solid green;
    height: 48px;
    width: 419px;
    border-radius: 16px;
    margin-left: auto;
    margin-right: auto;
    background: #f3f3f3;
    position: relative;
    .tab-header {
      width: 411px;
      height: 40px;
      margin: 0px 4px;
      // border: 1px solid red;
      // &:after {
      //   content: "";
      //   width: 100%;
      //   border-bottom: 2px solid #ddd;
      // }

      .tab-nav {
        margin: 4px 0;
        // border: 1px solid black;
        width: 137px;
        height: 40px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #606060;
        line-height: 40px;
        display: inline-block;
        text-align: center;
        cursor: pointer;
        &.active {
          background: #ffffff;
          border-radius: 12px;
          // border: 1px solid rgba(151, 151, 151, 0.16);
          color: #4100ff;
        }
      }
    }
    .tab-header-right {
      // display: flex;
      // justify-content: space-between;
      // border: 1px solid red;
      position: absolute;
      left: 615px;
      bottom: 2px;
      .right-filter {
        width: 220px;
        height: 44px;
        background: #ffffff;
        border-radius: 8px;
        border: 1px solid #9d9d9d;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 44px;
        margin-left: 20px;
        padding-left: 17px;
        position: relative;
        cursor: pointer;
        .filter-down-icon {
          position: absolute;
          top: 0;
          right: 22px;
        }
      }
    }
  }
  .tab-top-synthetic {
    width: 1191px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding-left: 9px;
    //  border: 1px solid red;
    .tab-top-icon {
      width: 34px;
      height: 34px;
      margin-right: 40px;
    }
    .tab-synthetic-nav {
      height: 53px;
      cursor: pointer;
      // border: 1px solid red;
    }
    .tab-synthetic-nav-active:after {
      content: "";
      width: 36px;
      height: 3px;
      background: #4100ff;
      border-radius: 3px;
      display: block;
      margin-top: 11px;
    }
  }
  .bottom-line {
    width: 100%;
    height: 1px;
    background: #dedede;
    // margin-top: 19px;
  }
  .TabPanel {
    width: 1218px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 34px;
    // border: 1px solid pink;
  }
  .TabPanel-synthetic {
    width: 1200px;
    padding-top: 21px;
    //  border: 1px solid pink;
  }
}
</style>
