<template>
  <div>
    <button @click="handleWalletConnect">WalletConnect</button>
    <button @click="resetApp">resetApp</button>
    <button @click="getAccountAssets">getBalance</button>
    <button @click="approve">approveUSDTContract</button>
    <p>
      Address:
      {{ userAddress }}
    </p>
    <p>balance:{{ assets }}</p>
    <p>networkId: {{ networkId }}</p>
    <p>chainId: {{ chainId }}</p>
    <!-- <p>web3: {{ web3  }}</p> -->
    <!-- <p>provider: {{ provider }}</p> -->
  </div>
</template>
<script setup>
// import { computed } from 'vue';
import useWallet from "../hooks/useWallet";
// import { USDT_API } from '../web3/abis';
// import { USDT_ADDRESS } from '../web3/config';

const {
  onConnect,
  connected,
  userAddress,
  web3,
  provider,
  chainId,
  networkId,
  resetApp,
  assets,
  getAccountAssets,
} = useWallet();

// const networkId = await web3.eth.net.getId((err, id) => {
//   if (err) console.log(err);
//   else console.log("id", id);
// });
const handleWalletConnect = async () => {
  await onConnect();
  if (connected) {
    console.log("web3", web3.value);
    console.log("provider", provider.value);
    const networkId = await web3.value.eth.net.getId((err, id) => {
      if (err) console.log(err);
      else console.log("id", id);
    });
    console.log("afterConnectdWallet", connected);
  }
};
// const contract = computed(
//   () => new web3.value.eth.Contract(USDT_API, USDT_ADDRESS),
// );
// function approve() {
//   return contract.value.methods
//     .approve(USDT_ADDRESS, utils.toHex(utils.toWei('1000000000000000000000000000', 'gwei')))
//     .send({ from: userAddress.value });
// }

// .....
</script>
