<template>
  <div
    class="divSelect"
    @click="openList"
    :style="computedDivStyle"
    :class="computedDivSelectClass"
  >
    <div class="divSelectinput" :style="computedStyle">
      <!-- 选中后的内容 -->
      <div
        class="selectinfos"
        :style="computedStyle"
        v-if="type == 'blockchain'"
      >
        <div>
          <img :src="annexTitle.icon" v-if="!isMini" />
          <img :src="annexTitle.miniIcon" v-else />
        </div>
        <div>{{ annexTitle.name }}</div>
      </div>
      <div class="selectinfos" :style="computedStyle" v-else>
        <div>{{ annexTitle.name }}</div>
      </div>
      <!-- 三角形图标 -->
      <div
        class="imgthree"
        :style="computedIconStyle"
        v-show="!importCollection"
      >
        <img src="../../assets/icon_down@2x.png" v-if="!showSelectList" />
        <img src="../../assets/icon_up12x8@2x.png" v-else />
      </div>
    </div>
    <div
      class="Selectlist"
      :style="computedSelectListStyle"
      v-show="showSelectList && disabled == 'false'"
    >
      <!-- 下拉框列表 -->
      <ul>
        <li
          v-for="(item, index) in Files"
          :key="index"
          @click="changeSelect(item)"
          :style="computedStyle"
        >
          <div v-if="type == 'blockchain'" :class="computedSelectListClass">
            <img :src="item.icon" v-if="!isMini" />
            <img :src="item.miniIcon" v-else />
            <div>{{ item.name }}</div>
          </div>
          <div v-else>{{ item.name }}</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "DropDownBox",
  data() {
    return {
      showSelectList: false,
      annexTitle: {},
      Files: [],
      chains: {
        80001: {
          value: 0,
          chainId: 80001,
          name: "Polygon",
          icon: require("../../assets/matic.jpg"),
          miniIcon: require("../../assets/logo_polygon.png"),
        },
        43113: {
          value: 1,
          chainId: 43113,
          name: "Avalanche",
          icon: require("../../assets/avax.jpg"),
          miniIcon: require("../../assets/avax.jpg"),
        },
        97: {
          value: 2,
          chainId: 97,
          name: "BNB Chain",
          icon: require("../../assets/bnb.jpg"),
          miniIcon: require("../../assets/logo_bnb.png"),
        },
        5: {
          value: 3,
          chainId: 5,
          name: "Ethereum",
          icon: require("../../assets/icon_etherum.png"),
          miniIcon: require("../../assets/logo_eth.png"),
        },
      },
      tokenType: [
        {
          value: 0,
          name: "ERC-721",
        },
        // {
        //   value: 1,
        //   name: "ERC-1155",
        // },
      ],
      categoty: [
        {
          value: 0,
          name: "Collectible",
        },
      ],
    };
  },
  props: {
    width: [String, Number],
    height: [String, Number],
    type: {
      type: String,
      default: "tokenType",
    },
    disabled: {
      type: String,
      default: "false",
    },
    isMini: {
      type: Boolean,
      default: false,
    },
    importCollection: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      "getNewItemCollectionData",
      "getImportCollectionBlockChain",
      "getImportNFTBlockChain",
    ]),
    computedDivStyle() {
      let style = {};
      if (this.width) {
        style.width = this.width + "px";
      }
      if (this.type == "blockchain" && this.isMini && this.importCollection) {
        style["--blockchain-mini-margin-right"] = "10px";
      }
      return style;
    },
    computedStyle() {
      let style = {};
      if (this.width) {
        style.width = this.width + "px";
      }
      if (this.height) {
        style.height = this.height + "px";
        style["--selectinfo-line-height"] = style.height;
      }
      if (this.type == "blockchain" && this.isMini && this.importCollection) {
        style["--blockchain-mini-padding-left"] = "7px";
      }
      return style;
    },
    computedSelectListStyle() {
      let style = {};
      if (this.type == "blockchain" && this.isMini && this.importCollection) {
        style["--blockchain-mini-padding-left"] = "7px";
      }
      return style;
    },
    computedIconStyle() {
      let style = {};
      if (this.type == "blockchain") {
        style["--imgthree-triangle-icon-top"] = "29px";
        style["--imgthree-triangle-icon-right"] = "20px";
        if (this.isMini) {
          style["--imgthree-triangle-icon-top"] = "13px";
          style["--imgthree-triangle-icon-right"] = "11px";
        }
      } else if (this.type == "category") {
        style["--imgthree-triangle-icon-right"] = "20px";
      }
      return style;
    },
    computedDivSelectClass() {
      let clazzes = [];
      if (this.type == "blockchain" || this.type == "category") {
        clazzes.push("divSelect-" + this.type);
        if (this.type == "blockchain" && this.isMini) {
          clazzes.push("divSelect-" + this.type + "-mini");
        }
      }
      return clazzes;
    },
    computedSelectListClass() {
      let clazzes = [];
      if (this.type == "blockchain") {
        if (this.isMini) {
          clazzes.push("select-list-" + this.type + "-mini");
        } else {
          clazzes.push("select-list-" + this.type);
        }
      }
      return clazzes;
    },
  },
  methods: {
    ...mapActions([
      "setCollectionBlockChain",
      "setImportCollectionBlockChain",
      "setImportNFTBlockChain",
    ]),
    openList() {
      let list = [];
      if (this.type == "blockchain") {
        list = [].slice.call(this.Files);
        list = "[" + JSON.stringify(this.Files) + "]";
        // this.Files.splice(this.annexTitle.chainId, 1);
      }
      console.log(this.Files, JSON.parse(list));
      // this.Files.splice(5, 1);
      console.log(this.Files);
      // for (let i = 0; i < list.length; i++) {
      //   if (this.Files[i].value == this.annexTitle.value) {
      //     this.Files.splice(i, 1);
      //     i -= 1;
      //   }
      // }
      if (this.Files.length == 0) return;
      this.showSelectList = !this.showSelectList;
    },
    //点击选择下拉框中的某一选项
    changeSelect(item) {
      this.annexTitle = { ...item };
      console.log("this.annexTitle", this.annexTitle);
      this.initializeData(false);
      if (this.type == "blockchain") {
        debugger;
        if (!this.isMini && !this.importCollection) {
          this.setCollectionBlockChain(this.annexTitle);
        } else if (this.isMini && this.importCollection) {
          this.setImportCollectionBlockChain(this.annexTitle);
        } else if (this.isMini && !this.importCollection) {
          this.setImportNFTBlockChain(this.annexTitle);
        }
      }
    },
    initializeData(isSet) {
      if (this.type == "blockchain") {
        debugger;
        this.Files = { ...this.chains };
        if (
          this.getNewItemCollectionData &&
          this.getNewItemCollectionData.block_chain
        ) {
          if (isSet)
            this.annexTitle =
              this.Files[this.getNewItemCollectionData.block_chain];
        } else if (
          this.getImportCollectionBlockChain &&
          this.getImportCollectionBlockChain.chainId
        ) {
          if (isSet)
            this.annexTitle =
              this.Files[this.getImportCollectionBlockChain.chainId];
        } else if (
          this.getImportNFTBlockChain &&
          this.getImportNFTBlockChain.chainId
        ) {
          if (isSet)
            this.annexTitle = this.Files[this.getImportNFTBlockChain.chainId];
        }
      } else if (this.type == "tokenType") {
        this.Files = [...this.tokenType];
        if (isSet) this.annexTitle = this.Files[0];
      } else if (this.type == "category") {
        this.Files = [...this.categoty];
        if (isSet) this.annexTitle = this.Files[0];
      }
    },
  },
  created() {
    this.initializeData(true);
    this.setCollectionBlockChain(this.chains[97]);
    if (
      !this.getImportCollectionBlockChain ||
      !this.getImportCollectionBlockChain.chainId
    ) {
      this.setImportCollectionBlockChain(this.chains[97]);
    }
    if (!this.getImportNFTBlockChain || !this.getImportNFTBlockChain.chainId) {
      this.setImportNFTBlockChain(this.chains[97]);
    }
  },
};
</script>

<style lang="scss" scoped>
/* 三角形图标 */
.imgthree img {
  width: 12px;
  height: 8px;
  position: absolute;
  // top: 24px;
  // left: 129px;
  top: var(--imgthree-triangle-icon-top);
  // left: var(--imgthree-triangle-icon-left);
  right: var(--imgthree-triangle-icon-right);
}
/*  重新写一个下拉框组件  */
.divSelect {
  // position: absolute;
  width: 152px;
  background: #ffffff;
  height: 100%;
  // height: 102px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  border-radius: 0px 10px 10px 10px;
  border-right: 1px solid #dedede;
  // border: 1px solid red;
  li {
    text-align: center;
    width: 152px;
    height: 46px;
    line-height: 46px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    list-style: none;
    cursor: pointer;
    border: 1px solid #dedede;
    border-top: none;
    border-right: none;
    // border-bottom: 1px solid #dedede;
    border-radius: 0px 0px 9px 9px;
  }
}

.divSelect-blockchain {
  border-left: 1px solid #dedede;
  border-radius: 10px;
  color: #000;
  .divSelectinput {
    border-left: none;
    border-radius: 10px;
    background: #ffffff;
    .selectinfos {
      display: flex;
      img {
        margin: 13px 13px 13px 8px;
      }
    }
  }
  li {
    border-left: none;
  }
}
.divSelect-blockchain-mini {
  border-left: 1px solid #999999;
  border-radius: 7px;
  color: #666666;
  line-height: 33px;
  border-right: 1px solid #999999;
  margin-right: var(--blockchain-mini-margin-right);
  z-index: 999999;
  .divSelectinput {
    border-radius: 7px;
    border: 1px solid #999999;
    border-left: none;
    .selectinfos {
      display: flex;
      font-size: 14px;
      padding-left: var(--blockchain-mini-padding-left);
      img {
        margin: 0px 4px -2px 0px;
        width: 13px;
        height: 13px;
      }
    }
  }
  li {
    border-radius: 0px 0px 7px 7px;
    border: 1px solid #999999;
    border-top: none;
  }
}
.divSelect-category {
  border-left: 1px solid #dedede;
  border-radius: 10px;
  color: #000;
  .divSelectinput {
    border-left: none;
    border-radius: 10px;
    background: #ffffff;
  }
  .selectinfos {
    display: flex;
    margin-left: 32px;
  }
}
.divSelectinput {
  position: relative;
  width: 150px;
  height: 56px;
  background: #f0f0f0;
  border-radius: 0px 10px 10px 0;
  border: 1px solid #dedede;
  // border: 1px solid red;
  cursor: pointer;
}

.divSelectinput .selectinfos {
  // width: 152px;
  height: 56px;
  text-align: center;
  line-height: var(--selectinfo-line-height);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  list-style: none;
}

/* 出现的下拉列表 */
// .Selectlist {
//   // border: 1px solid red;
// }
.Selectlist > ul {
  margin: 0;
  padding: 0;
}
// .divSelect li {
//   text-align: center;
//   width: 152px;
//   height: 46px;
//   line-height: 46px;
//   white-space: nowrap;
//   text-overflow: ellipsis;
//   overflow: hidden;
//   list-style: none;
//   cursor: pointer;
//   border: 1px solid #dedede;
//   border-top: none;
//   border-right: none;
//   // border-bottom: 1px solid #dedede;
//   border-radius: 0px 0px 9px 9px;
// }
.divSelect-blockchain li {
  border-left: none;
  // border-bottom: 1px solid #dedede;
  // border: 1px solid red;
}
.divSelect-category li {
  text-align: left;
  margin-left: 10px;
  border-left: none;
}
.select-list-blockchain {
  line-height: 66px;
  display: flex;
  img {
    margin: 13px 13px 13px 8px;
  }
}
.select-list-blockchain-mini {
  display: flex;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 33px;
  padding-left: var(--blockchain-mini-padding-left);
  img {
    width: 13px;
    height: 13px;
    margin-right: 4px;
    margin-top: 10px;
  }
}
</style>