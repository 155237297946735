<template>
  <div class="wallet-page">
    <HomePageTop :defaultKey="3"></HomePageTop>
    <SyntheticTable class="wallet-content"/>
  </div>
</template>

<script>
import TabGroup from "../components/TabGroup.vue";
import TabPanel from "../components/TabPanel.vue";
export default {
  components: { TabGroup, TabPanel },
};
</script>

<style lang="scss" scoped>
.wallet-page {
  // .wallet-content{
  //   width: 1200px;
  //   margin-left: auto;
  //   margin-right: auto;
  // }
}
</style>