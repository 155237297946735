<template>
  <div>
    <div class="title-text">Synthetic Web3 Account</div>
    <TabGroup defaultKey="0" type="synthetic" class="synthetic-tab">
      <TabPanel label="aster" actKey="0">
        <SyntheticForm></SyntheticForm>
      </TabPanel>
      <TabPanel label="twitter" actKey="1">
        <SyntheticForm type="Twitter" index="0"></SyntheticForm>
      </TabPanel>
      <TabPanel label="facebook" actKey="2">
        <!-- todo 会调用多次 -->
        <!-- <SyntheticForm type="Facebook" index="1"></SyntheticForm> -->
      </TabPanel>
      <TabPanel label="ins" actKey="3">
        <!-- <SyntheticForm type="Instagram" index="2"></SyntheticForm> -->
      </TabPanel>
      <TabPanel label="tiktok" actKey="4">
        <!-- <SyntheticForm type="Tiktok" index="3"></SyntheticForm> -->
      </TabPanel>
      <TabPanel label="youtube" actKey="5">
        <!-- <SyntheticForm type="Youtube" index="4"></SyntheticForm> -->
      </TabPanel>
      <TabPanel label="discord" actKey="6">
        <!-- <SyntheticForm type="Discord" index="5"></SyntheticForm> -->
      </TabPanel>
    </TabGroup>
  </div>
</template>

<script>
import SocialCard from "./wallet/SocialCard.vue";
export default {
  name: "SyntheticTable",
  components: {
    SocialCard,
  },
};
</script>

<style lang="scss" scoped>
.title-text {
  font-size: 28px;
  font-family: Arial-BoldMT, Arial;
  font-weight: normal;
  color: #333333;
  line-height: 32px;
  margin-top: 28px;
  margin-bottom: 20px;
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.synthetic-tab {
  // margin-left: 9px;
}
</style>