import { createApp } from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import state from './state'
import * as actions from './actions'



export default new Vuex.Store({
  // namespaced: true,
  state,
  mutations,
  actions,
  getters: {
    getAccount: state => state.account,
    getUidParam: state => state.uidParam,
    getShowPreviewModal: state => state.showPreviewModal,
    getImageUrl: state => state.collectionURL,
    getCollection: state => state.collection,
    getItemURL: state => state.itemURL,
    getNewItemCollectionData: state => state.newItemCollectionData,
    getWeb3Value: state => state.web3Value,
    getCollectionBlockChain: state => state.collectionBlockChain,
    getImportCollectionBlockChain: state => state.importCollectionBlockChain,
    getImportNFTBlockChain: state => state.importNFTBlockChain,
  }
})
// createApp.use(Vuex)
