<template>
  <HomePageTop :defaultKey="0"></HomePageTop>
  <EditModal>
    <template #title>
      <div>Import My Item</div>
    </template>
    <template #content>
      <AssetModal type="import" />
    </template>
  </EditModal>
</template>

<script>
import AssetModal from "../../components/asset/AssetModal.vue";
export default { components: { AssetModal } };
</script>

<style lang="scss" scoped>
</style>