import { ethers } from "ethers";
import store from "../store";
export const signMessage = async (message) => {
    try {
        console.log({ message });
        if (!store.getters.getWeb3Value.web3)
            throw new Error("No crypto wallet found. Please install it.");

        console.log(store.getters.getWeb3Value,window.ethereum);
        /* eslint-disable */
        // debugger;
        await window.ethereum.send("eth_requestAccounts");
        const provider = new ethers.providers.Web3Provider(store.getters.getWeb3Value.provider);
        const signer = provider.getSigner();
        const msg = `aster information update: `;
        const signature = await signer.signMessage(msg + message);
        // const address = await signer.getAddress();
        return {
            signature
        };
    } catch (err) {
        console.log(err.message);
    }
};

export const verifyMessage = (message, address, signature) => {
    try {
        const msg = `aster information update: `;
        const signerAddr = ethers.utils.verifyMessage(msg + message, signature);
        if (signerAddr !== address) {
            return false;
        }
        return true;
    } catch (err) {
        console.log(err);
        return false;
    }
}