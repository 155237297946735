<template>
  <div
    class="nft-card"
    v-for="(item, index) in showcaseList"
    :key="index"
    v-show="showSelectAllCheck"
  >
    <div class="nft-img" :class="computedClass" @click="handleCheckIcon(index)">
      <img :src="item.token_uri" />
    </div>
    <div
      class="nft-card-check-modal"
      :class="computedClass"
      v-show="showCheckModal"
      @click="handleCheckIcon(index)"
    ></div>
    <div
      class="check-icon"
      :class="computedClass"
      v-show="item.isChecked"
      @click="handleCheckIcon(index)"
    >
      <img src="../assets/icon_cancel.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckCard",
};
</script>

<style lang="scss" scoped>
.nft-card {
  position: relative;
  display: inline-block;
  margin: 0 5.6px 10px 0;
  .nft-img {
    width: 66px;
    height: 66px;
    box-shadow: 0px 2px 4px 0px rgba(101, 48, 255, 0.21);
    border-radius: 8px;
    cursor: pointer;
    object-fit: contain;
    // border: 1px solid red;
    img {
      width: 66px;
      height: 66px;
      max-width: 100%;
      border-radius: 8px;
      box-shadow: 0px 2px 4px 0px rgba(101, 48, 255, 0.21);
      object-fit: contain;
    }
  }
  .nft-card-check-modal {
    position: absolute;
    width: 15px;
    height: 15px;
    background: #ffffff;
    border: 1px solid #d4d4d4;
    border-radius: 50%;
    cursor: pointer;
    top: 2px;
    left: 49px;
  }
  .check-icon {
    position: absolute;
    top: -3px;
    left: 53px;
    width: 8px;
    height: 6px;
    cursor: pointer;
  }
}
</style>