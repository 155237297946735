<template>
  <div id="select_module">
    <div class="divSelect" @click="openList">
      <div class="divSelectinput" :style="computedStyle">
        <!-- 选中后的内容 -->
        <div class="selectinfos" :title="annexTitle" :style="computedStyle">
          {{ annexTitle }}
        </div>
        <!-- 三角形图标 -->
        <div class="imgthree"></div>
      </div>
      <div class="Selectlist" v-show="showSelectList">
        <!-- 下拉框列表 -->
        <ul>
          <li
            v-for="(item, index) in Files"
            :key="index"
            @click="changeSelect(item.FileName)"
          >
            {{ item.FileName }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectModal",
  data() {
    return {
      showSelectList: false,
      annexTitle: "Art",
      Files: [
        {
          FileName: "第一个：法律",
          value: 1,
        },
        {
          FileName: "第二个：经济",
          value: 2,
        },
        {
          FileName: "第三个：政治",
          value: 3,
        },
        {
          FileName: "第四个：安全",
          value: 4,
        },
      ],
    };
  },
  props: {
    width: [String, Number],
    height: [String, Number],
    type: {
      type: String,
      default: "default",
    },
  },
  computed: {
    computedStyle() {
      let style = {};
      if (this.width) {
        style.width = this.width + "px";
      }
      if (this.height) {
        style.height = this.height + "px";
        style["--selectinfo-line-height"] = style.height;
      }

      return style;
    },
  },
  methods: {
    openList() {
      this.showSelectList = !this.showSelectList;
    },
    //点击选择下拉框中的某一选项
    changeSelect(FileName) {
      this.annexTitle = FileName;
    },
  },
};
// window.onload = function () {
//   var aSelect = true;
//   //点击页面其他地方收起下拉列表
//   document.onclick = function (e) {
//     if (aSelect) {
//       if (document.getElementsByClassName("Selectlist")[0] != undefined) {
//         document.getElementsByClassName("Selectlist")[0].style.display = "none";
//         document
//           .getElementsByClassName("imgthree")[0]
//           .classList.remove("imgthree2");
//       }
//     }
//     aSelect = true;
//   };
//   if (document.getElementsByClassName("divSelectinput")[0] != undefined) {
//     document.getElementsByClassName("divSelectinput")[0].onclick = function () {
//       document.getElementsByClassName("Selectlist")[0].style.display = "block";
//       document.getElementsByClassName("imgthree")[0].classList.add("imgthree2");
//       aSelect = false;
//     };
//   }
// };
</script>

<style lang="scss" scoped>
/* 三角形图标 */
.imgthree {
  margin-top: 17px;
  width: 6px;
  height: 6px;
  //   background: url(../img/shape.png) 0px 0px;
  background-repeat: no-repeat;
  float: left;
}

.imgthree2 {
  animation: imgthreeanimation2 0.5s forwards;
}

@keyframes imgthreeanimation2 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}
/*  重新写一个下拉框组件  */
.divSelect {
  /*width:100%;*/
  height: 100%;
  // border: 1px solid red;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.divSelectinput {
  margin-top: 2px;
  width: 130px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #dedede;
  cursor: pointer;
}
.divSelectinput .selectinfos {
  width: 120px;
  height: 40px;
  text-align: center;
  line-height: var(--selectinfo-line-height);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  list-style: none;
}

/* 出现的下拉列表 */
.Selectlist {
  position: absolute;
  margin-top: 10px;
  background-color: #ffffff;
  z-index: 800;
  border-radius: 5px;
  border: 1px solid #e4e7ed;
}
.Selectlist > ul {
  margin: 0;
  padding: 0;
}
.divSelect li {
  width: 238px;
  padding: 0 10px;
  height: 34px;
  line-height: 34px;
  white-space: nowrap;
  /*background-color:#ffffff;*/
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  list-style: none;
  cursor: pointer;
}
// .divSelect li:hover {
//   color: #409eff;
//   font-weight: 700;
//   background-color: #f5f7fa;
// }
</style>