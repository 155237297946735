<template>
  <div v-show="actKey == activeKey">
    <slot></slot>
    <div class="tab-bottom"></div>
  </div>
</template>
<script>
import { ref, inject } from "vue";
export default {
  name: "TabPanel",
  props: {
    actKey: {
      type: String,
      default: "1",
    },
    label: {
      type: String,
      default: "全部",
    },
  },

  setup() {
    let activeKey = ref();
    activeKey = inject("activeKey");
    return { activeKey };
  },
};
</script>
