import axios from "axios";
export const getShowcaseList = async (account) => {
    let showcaseList = []
    await axios
        .get(
            "https://r5uyhu7s5c.execute-api.us-east-1.amazonaws.com/test/showcase/list/" + account
        )
        .then((res) => {
            showcaseList = res.data.Items;
            showcaseList = showcaseList.sort(
                (a, b) => a.order - b.order
            );
        })
        .catch((e) => {
            console.log(e);
        });
    return showcaseList;
}

export const showcaseListSort = async (showcaseList) => {
    for (let i = 0; i < showcaseList.length; i++) {
        showcaseList[i].sequence = i + 1;
    }
    let params = {
        tokens: showcaseList,
    };
    await axios
        .post(
            "https://r5uyhu7s5c.execute-api.us-east-1.amazonaws.com/test/showcase/sort",
            params
        )
        .then((res) => {
            if (res.status == "200") {
                return res
            }
        })
        .catch((e) => {
            console.log(e);
        });
}

export const insertShowcase = async (account, addCheckedList) => {
    for (let i = 0; i < addCheckedList.length; i++) {
        let params = {
            id: addCheckedList[i].token_id,
            address: addCheckedList[i].token_address
        }
        await axios.post(" https://q6gj84b287.execute-api.us-east-1.amazonaws.com/test/test", params).then((res) => {
            if (res.status == "200") {
                if (res.data.body.Items.length > 0) {
                    addCheckedList.splice(i, 1)
                    i -= 1
                }
            }
        }).catch((e) => {
            console.log(e)
        })
    }
    let params = {
        account: account,
        tokens: addCheckedList,
    };
    await axios
        .post(
            "https://r5uyhu7s5c.execute-api.us-east-1.amazonaws.com/test/showcase/add",
            params
        )
        .then((res) => {
            if (res.status == "200") {
                return res
            }
        })
        .catch((e) => {
            console.log(e);
        });
}

export const deleteShowcase = async (id) => {
    await axios
        .get(
            "https://r5uyhu7s5c.execute-api.us-east-1.amazonaws.com/test/showcase/remove/" +
            id
        )
        .then((res) => {
            if (res.status == "200") {
                return res
            }
            // if (res.data.statusCode == "200") {
            //     return res
            // }
        })
        .catch((e) => {
            console.log(e);
        });
}