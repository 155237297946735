import Moralis from 'moralis'

export async function getCollectedList(address) {
    // chain :Ethereum (ETH) Binance Smart Chain (BSC) Polygon (MATIC) Avalanche (AVAX) Fantom (FTM)
    // eth, 0x1, ropsten, 0x3, rinkeby, 0x4, goerli, 0x5, kovan, 0x2a, polygon, 0x89, mumbai, 0x13881, bsc, 0x38, bsc testnet, 0x61, avalanche, 0xa86a, avalanche testnet, 0xa869, fantom, 0xfa
    const serverUrl = "https://ijevwpm5jnoh.usemoralis.com:2053/server";
    const appId = "tUYoucGwwQioOoasPyLYWRLjHi3LGleLSBpiC5HM";
    // const serverUrl = "https://sza2hvdc8rkj.usemoralis.com:2053/server";
    // const appId = "axO6x26l1AxehFkzeZowjoIxtCJ4pca6sfwi94eL";
    Moralis.start({ serverUrl, appId });
    // Moralis.settings.setAPIRateLimit({
    //     anonymous: 100, authenticated: 200, windowMs: 60000
    // })
    //对象数组+加模拟异步请求
    const chains = [{ chain: 'mumbai' }, { chain: 'eth' }, { chain: 'bsc' }, { chain: 'matic' }, { chain: 'goerli' }]
    // debugger
    const promise = chains.map(i => {
        const options = {
            chain: i.chain,
            address: address,
        }
        /* eslint-disable */
        return new Promise(async (resolve) => {
            setTimeout(resolve, 0, await Moralis.Web3API.account.getNFTs(options));
        })
    })
    const res = await Promise.all(promise)
    return res
}