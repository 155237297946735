<template>
  <div class="nft-module">
    <HomePageTop :defaultKey="2"></HomePageTop>
    <div class="home-page-content">
      <TabGroup defaultKey="1" class="content-top-tab">
        <TabPanel label="Showcase" actKey="1">
          <Showcase />
        </TabPanel>
        <TabPanel label="Collected" actKey="2"> </TabPanel>
        <TabPanel label="For sale" actKey="3"></TabPanel>
      </TabGroup>
    </div>
    <ManageShowcase v-show="showPreviewModal" />
  </div>
</template>
<script>
import StarUploads from "../components/common/StarUploads.vue";
export default {
  components: { StarUploads },
  name: "NFTModule",
  computed: {
    showPreviewModal() {
      return this.$store.getters.getShowPreviewModal;
    },
  },
  methods: {
    handleFiles(event) {
      debugger;
      // if (files.length) {
      let files = event.target.files;
      let file = files[0];
      let reader = new FileReader();
      reader.onload = function () {
        document.getElementById("preview").src = this.result;
      };
      reader.readAsDataURL(file);
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.nft-module {
  // border: 1px solid rgb(47, 0, 255);
  .home-page-content {
    // border: 1px solid pink;
    .content-top-tab {
      width: 100%;
      height: 68px;
      background: #f9f9f9;
      padding-top: 20px;
      // border: 1px solid blueviolet;
    }
  }
}
</style>