<template>
  <HomePageTop :defaultKey="0"></HomePageTop>
  <EditModal>
    <template #title>
      <div>Create My Collection</div>
    </template>
    <template #content>
      <CollectionModal />
    </template>
  </EditModal>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>