import { createRouter, createWebHistory } from 'vue-router'
import nft from '../views/NFT.vue'
import wallet from '../views/WalletPage.vue'
import identity from '../views/IdentityPage.vue'
import settings from '../views/SettingsPage.vue'
import dapp from '../views/DAppPage.vue'
import browse from '../views/BrowseHome.vue'
import BrowseWallet from '../views/BrowseWallet.vue'
import BrowseIdentity from '../views/BrowseIdentity.vue'
import BrowseDapp from '../views/BrowseDapp.vue'
import BrowseSettings from '../views/BrowseSettings.vue'
import MyCollections from '../views/collections/MyCollections.vue'
import NotFound from '../components/common/NotFound.vue'
import CreateCollection from '../views/collections/CreateCollection.vue'
import EditCollection from '../views/collections/EditCollection.vue'
import CollectionDetail from '../views/collections/CollectionDetail.vue'
import CreateAsset from '../views/assets/CreateAsset.vue'
import EditAsset from '../views/assets/EditAsset.vue'
import ImportAsset from '../views/assets/ImportAsset.vue'
import ImportCollection from '../views/collections/ImportCollection.vue'
import ConnectWallteExample from '../components/ConnectWallteExample.vue'
const routes = [
    {
        path: '/:uid',
        name: "uid",
        redirect: { name: 'BrowseHome' }
    },
    {
        path: '/',
        name:"home",
        redirect: "/nft"
    },
    {
        path: '/example',
        name: 'example',
        component: ConnectWallteExample
    },
    {
        path: '/:uid/nft',
        component: browse,
        name: "BrowseHome"
    },
    {
        path: '/nft',
        name: 'nft',
        component: nft
    },
    {
        path: '/:uid/wallet',
        component: BrowseWallet,
        name: "BrowseWallet"
    },
    {
        path: '/wallet',
        name: 'wallet',
        component: wallet
    },
    {
        path: '/:uid/identity',
        component: BrowseIdentity,
        name: "BrowseIdentity"
    },
    {
        path: '/identity',
        name: 'identity',
        component: identity
    },
    {
        path: '/:uid/settings',
        component: BrowseSettings,
        name: "BrowseSettings"
    },
    {
        path: '/settings',
        name: 'settings',
        component: settings
    },
    {
        path: '/:uid/dapp',
        component: BrowseDapp,
        name: "BrowseDapp"
    },
    {
        path: '/dapp',
        name: 'dapp',
        component: dapp
    },
    {
        path: '/collections',
        name: 'collections',
        component: MyCollections
    },
    {
        path: '/collection/:name',
        name: 'CollectionDetail',
        component: CollectionDetail,
    },
    {
        path: '/collection/create',
        name: 'CreateCollection',
        component: CreateCollection,
    },
    {
        path: '/collection/edit/:param',
        name: 'EditCollection',
        component: EditCollection,
    },
    {
        path: '/collection/import/:address',
        name: 'ImportCollection',
        component: ImportCollection,
    },
    {
        path: '/asset/create',
        name: 'CreateAsset',
        component: CreateAsset,
    },
    {
        path: '/asset/edit/:param',
        name: 'EditAsset',
        component: EditAsset,
    },
    {
        path: '/asset/import/:address/:id',
        name: 'ImportAsset',
        component: ImportAsset,
    },
    {
        path: '/:uid/NotFound',
        name: "NotFound",
        component: NotFound
    },
    {
        path: '/NotFound',
        name: "nFound",
        component: NotFound
    }

]

const router = createRouter({
    history: createWebHistory(),
    routes: routes
})

export default router