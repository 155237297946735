import {
    reactive,
    toRefs,
    onMounted,
    computed,
    provide
} from "vue";
import { useStore } from "vuex";
// 引入公共js文件
// import utils from "/@/assets/js/public/function";
// 引入axios钩子
import qs from "qs"
import axios from "axios";
import { compressAccurately } from "image-conversion";
export default {
    name: "StarUploads",
    /**
     * @name: 父组件传递来的参数
     * @author: camellia
     * @email: guanchao_gc@qq.com
     * @date: 2021-01-10
     */
    props: {
        // 多文件上传(0:单文件上传，1：多文件上传)
        multiple: {
            type: Number,
            default: 0,
        },
        // 上传数量
        limit: {
            type: Number,
            default: 0,
        },
        // 上传地址 url
        action: {
            type: String,
            default: '',
        },
        // 文件类型
        ext: {
            type: Array,// 数组类型
            default: [".gif", ".jpeg", ".jpg", ".png", ".bmp", ".doc", ".docx", ".xls", ".mp4", ".rmvb", ".zip"],
        },
        // 文件大小（mb）
        size: {
            type: Number,
            default: 0,
        },
        // 列表初始值
        data: {
            type: Array,// 数组类型
            default: [],
        },
        // 上传文件类型（image | file）
        type: {
            type: String,
            default: 'image',
        },
        // 随机名(1或者0)
        autoName: {
            type: Boolean,
            default: 1,
        },
        width: [String, Number],
        height: [String, Number],
        uploadType: {
            type: String,
            default: "default",
        },
        modalType: {
            type: String,
            default: "create"
        },
        url: String,
    },
    // VUE3语法 setup函数
    // setup官方文档：https://www.vue3js.cn/docs/zh/guide/composition-api-setup.html#参数
    setup(props, context) {

        const store = useStore()
        /**
         * @name: 声明data
         * @author: camellia
         * @email: guanchao_gc@qq.com
         * @date: 2021-01-10 
         */
        const data = reactive({
            // mydata: props.data ? props.data.slice() : [],
            mydata: [],
            accept: props.ext,
            computedStyle: {},
        });
        onMounted(() => {
            let obj = {}
            // obj.url = store.getters.getCollection.logo_image
            obj.url = props.url
            // if (obj.url && document.URL.endsWith("/edit")) {
            if (obj.url&&props.modalType !== "create") {
                debugger
                data.mydata.push(obj)
            }
            // debugger
            console.log(obj.url, props.modalType)
            // }
        });
        const comp = computed(() => {
            if (props.width) {
                data.computedStyle.width = props.width + "px"
            }
            if (props.height) {
                data.computedStyle.height = props.height + "px"
            }
            // padding: 22px 0 22px 0;
            if (props.modalType == "import") {
                data.computedStyle.paddingTop = "22px";
                data.computedStyle.paddingBottom = "22px";
            }
            return data.computedStyle
        }
        );
        console.log("computed1", comp.value)
        /**
         * @name: 整理目前已有文件
         * @author: camellia
         * @email: guanchao_gc@qq.com
         * @date: 2021-02-20 
         * @param:  file  object  文件对象
         * @param:  obj object  文件上传成功之后返回的信息对象
         */
        const isImg = (file, obj) => {
            if (props.type == "image") {
                let fileReader = new FileReader();
                fileReader.readAsDataURL(file);
                fileReader.onload = function (ev) {
                    // 这是base64文件编码
                    obj.url = ev.target.result;
                    if (props.limit == 1) {
                        data.mydata = [];
                    }
                    data.mydata.push(obj);
                };
            }
            else {
                if (props.limit == 1) {
                    data.mydata = [];
                }
                data.mydata.push(obj);
            }
        };

        /**
         * @name: 删除文件
         * @author: camellia
         * @email: guanchao_gc@qq.com
         * @date: 2021-02-20 
         * @param:  item  object  文件信息
         */
        const del = (item) => {
            data.mydata = data.mydata.filter(function (obj) {
                return obj != item;
            });
        };



        /**
         * @name: 组装上传数据(可多文件上传)
         * @author: camellia
         * @email: guanchao_gc@qq.com
         * @date: 2021-02-20 
         * @param:  e obj 文件流
         */
        const handlerUpload = async (e) => {
            var file = '';
            // 判断文件上传数量是否超限
            if (data.mydata.length >= props.limit) {
                // alert(2000, '上传文件数量已达到上限！'); return;
            }
            for (var i = 0; i < e.target.files.length; i++) {
                file = e.target.files[i];
                //检查文件格式
                if (file.type != "image/jpeg" && file.type != "image/jpg" && file.type != "image/png" && file.type != "image/webp")
                {return alert("wrong file format")}
                //压缩文件
                const limitFileSize = 1 * 1024;
                if(file.size>1 * 1024 * 1024){
                    await compressAccurately(file,limitFileSize).then(
                        res=>{
                            file=res
                        }
                    )
                    console.log("fileRes",file)
                }
                // 上传文件信息
                let dataObj = {
                    file: file,
                    // ext: data.accept,
                    // size: props.size,
                    // autoName: props.autoName == 0 ? props.autoName : 1
                };
                // 调用上传方法
                postUpload(dataObj)
                    .then(function (res) {
                        let obj = res.data;
                        provide("imageUrl", "https://ipfs.io/ipfs/" + obj.Hash)
                        console.log("上传成功", obj, store)
                        // if(document)
                        // https://ipfs.io/ipfs/Qme36coKWxWNND98h57J6iioEp6uiDSXDVVb6757sVyqQf
                        store.dispatch("setImageUrl", "https://ipfs.io/ipfs/" + obj.Hash)
                        store.dispatch("setItemUrl", "https://ipfs.io/ipfs/" + obj.Hash)
                        isImg(file, obj);
                        // alert(2000, '操作成功！'); return;
                    })
                    .catch(function (error) {
                        console.log(error);
                        // alert(2000, error); return;
                    });
            }
        };

        /**
         * @name: 上传接口
         * @author: camellia
         * @email: guanchao_gc@qq.com
         * @date: 2021-02-18 
         * @param:  data  object  上传文件数据对象
         */
        const postUpload = (data) => {
            var obj = new FormData();
            // for (let item in data) {
            //     obj.append(item, data[item]);
            // }
            obj.append("myfile",data.file)
            let config = {
                headers: {
                    "Content-Type": "multipart/form-data"
                },
                onUploadProgress: (e) => {
                    var completeProgress = ((e.loaded / e.total) * 100) | 0;
                    props.progress = completeProgress; //上传过程
                    // 关闭 上传进度显示
                    // utils.alertLoadExec(false);
                    // utils.alertLoadExec(true, '上传进度：' + completeProgress + '%');
                    // alert(false);
                    // alert("true", '上传进度：' + completeProgress + '%');
                }
            };
            console.log(obj, data.file, qs.stringify({ file: data.file }))
            return new Promise(async function (resolve, reject) {
                await axios
                    // .post("https://ipfs.infura.io:5001/api/v0/add", obj, config, [function () { return obj }], obj)
                    .post(`http://165.232.178.203:4001/api/file`,obj)
                    .then(function (res) {
                        // 关闭 上传进度显示
                        // utils.alertLoadExec(false);
                        // alert(false)
                        if (res.data) {
                            resolve(res);
                        }
                        else {
                            reject(res);
                        }
                    })
                    .catch(function (error) {
                        reject(error);
                        // 关闭 上传进度显示
                        // utils.alertLoadExec(false);
                        // alert(false)
                    });
            });
        }

        /**
          * @name: 将data绑定值dataRef
          * @author: camellia
          * @email: guanchao_gc@qq.com
          * @date: 2021-01-10 
          */
        const dataRef = toRefs(data);
        return {
            isImg, handlerUpload, postUpload, del,
            ...dataRef
        }
    }

};
