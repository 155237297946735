<template>
  <div class="box">
    <div class="title">
      <slot name="title"></slot>
    </div>
    <div class="content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditModal",
};
</script>

<style lang="scss" scoped>
.box {
  width: 928px;
  margin-left: auto;
  margin-right: auto;
  .title {
    margin: 40px 0 6px 0;
    font-size: 28px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #333333;
    line-height: 32px;
  }
  .content {
    height: 28px;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 28px;
  }
}
</style>