<template>
  <div class="popup-container" v-show="showModal">
    <div
      class="menu-bar"
      id="edit-collection"
      @click="$emit('to')"
      @mouseover="over($event)"
      @mouseout="out($event)"
    >
      <div>
        <slot name="first-menu"></slot>
      </div>
    </div>
    <div class="line"></div>
    <div
      class="menu-bar"
      id="creator-earnings"
      @click="$emit('remove')"
      @mouseover="over($event)"
      @mouseout="out($event)"
    >
      <div>
        <slot name="second-menu"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditPopup",
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    over(item) {
      item.currentTarget.style.background = "#f9f9f9";
    },
    out(item) {
      item.currentTarget.style.background = "#fff";
    },
  },
};
</script>
<style lang="scss" scoped>
.popup-container {
  width: 130px;
  height: 65px;
  background: #ffffff;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  .menu-bar {
    height: 32px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 32px;
    background: #ffffff;
    cursor: pointer;
    padding-left: 10px;
    border-radius: 10px;
  }
  .line {
    width: 130px;
    height: 1px;
    background: #dedede;
  }
}
</style>