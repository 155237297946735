<template>
  <div class="search-box">
    <img
      class="search-svg"
      :style="computedIconStyle"
      :class="computedIconClass"
      src="../assets/icon_search.png"
    />
    <input
      class="search-input"
      :class="computedClass"
      :style="computedStyle"
      type="text"
      :placeholder="'search here'"
      v-model="filterInput"
    />
  </div>
</template>

<script>
export default {
  name: "SearchBox",
  data() {
    return {
      filterInput: "",
    };
  },
  props: {
    width: String,
    height: String,
    type: {
      type: String,
      default: "default",
    },
  },
  computed: {
    computedStyle() {
      let style = {};
      if (this.width) {
        style.width = this.width + "px";
      }
      if (this.height) {
        style.height = this.height + "px";
      }
      return style;
    },
    computedIconStyle() {
      let style = {};
      if (this.type == "home") {
        style["--search-icon-left"] = "11px";
      } else if (this.type == "collection") {
        style["--search-icon-left"] = "10px";
      }
      return style;
    },
    computedIconClass() {
      let clazzes = [];
      if (this.type !== "default") {
        clazzes.push(" search-svg-" + this.type);
      }
      return clazzes;
    },
    computedClass() {
      let clazzes = [];
      if (this.type !== "default") {
        clazzes.push("search-input-" + this.type);
      }
      return clazzes;
    },
  },
  watch: {
    // filterInput(val) {
    //   this.updateInputVaule();
    // },
  },
  methods: {
    // updateInputVaule() {
    //   this.$parent.$parent.$parent.$parent.getFilterInputValue();
    // },
  },
};
</script>

<style lang="scss" scoped>
.search-box {
  position: relative;
  display: flex;
  .search-svg {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 26%;
    left: var(--search-icon-left);
  }
  .search-svg-home {
    width: 20px;
    height: 20px;
  }
  .search-svg-collection {
    width: 16px;
    height: 16px;
    top: 22%;
  }
  .search-input {
    outline: 0;
    // width: 214px;
    width: 281px;
    height: 27px;
    background: rgba(242, 242, 242, 0.38);
    border-radius: 7px;
    border: 1px solid #eaeaea;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #888888;
    font-weight: 400;
    // line-height: 22px;
    white-space: nowrap;
    padding-left: 24px;
  }
  .search-input-home {
    border: 1px solid #9d9d9d;
    padding-left: 40px;
    background: #ffffff;
    border-radius: 16px;
    font-size: 16px;
    color: #000;
  }
  .search-input-collection {
    background: #ffffff;
    color: #000;
    border-radius: 6px;
    border: 1px solid #9d9d9d;
    padding-left: 32px;
    font-size: 16px;
  }
}
</style>
