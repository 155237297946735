import { createApp } from 'vue'
import components from './components/index.js'
import App from './App.vue'
import router from './router/index.js'
import './style/root.css'
import Vuex from 'vuex'
import store from './store'
/* eslint-disable */
// debugger
// Vue.use(Vuex)

// const store = new Vuex.Store({
//     state: {
//         account: "",
//         uidParam: "",
//         showPreviewModal: false,
//     },
//     mutations: {
//         setAccount(state, account) {
//             state.account = account
//         },
//         setUidParam(state, uidParam) {
//             state.uidParam = uidParam
//         },
//         handlePreviewModal(state, bol) {
//             state.showPreviewModal = bol
//         }
//     },
//     getters: {
//         getAccount: state => state.account,
//         getUidParam: state => state.uidParam,
//         getShowPreviewModal: state => state.showPreviewModal,
//     }

// })

createApp(App).use(components).use(router).use(Vuex).use(store).mount('#app')