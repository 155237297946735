<template>
  <div class="popup-mask">
    <div class="popup-wrapper">
      <div class="popup-container" :style="computedStyle">
        <div class="head-title">
          <slot></slot>
        </div>
        <img
          class="close-icon"
          src="../../assets/preview_icon_close2.png"
          @click="$emit('close')"
        />
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
        <div class="footer-btn">
          <Button type="ok" @click="$emit('ok')">OK</Button>
          <!-- <Button type="cancel">Cancel</Button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PopupModal",
  props: {
    width: String,
    height: String,
  },
  computed: {
    computedStyle() {
      let style = {};
      if (this.width) style.width = this.width + "px";
      if (this.height) style.height = this.height + "px";
      return style;
    },
  },
};
</script>

<style lang="scss" scoped>
.popup-mask {
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  display: table;
  transition: opacity 0.3s ease;
  background-color: rgba(0, 0, 0, 0.5);
  .popup-wrapper {
    display: table-cell;
    vertical-align: middle;
    .popup-container {
      width: 624px;
      height: 210px;
      background: #ffffff;
      border-radius: 7px;
      margin: 0px auto;
      transition: all 0.3s ease;
      position: relative;
      .head-title {
        text-align: center;
        height: 58px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 58px;
        margin-bottom: 8px;
      }
      .close-icon {
        width: 12px;
        height: 12px;
        position: absolute;
        top: 23px;
        right: 20px;
        cursor: pointer;
      }
      .modal-body {
        // display: flex;
        // justify-content: space-between;
        padding: 0 28px;
      }
      .footer-btn {
        position: absolute;
        bottom: 30px;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-around;
      }
    }
  }
}
</style>