<template>
  <div
    class="button-box"
    :style="computedStyle"
    @click="handleClick"
    :class="computedClass"
    id="component-button-box-id"
  >
    <div class="button-text" :class="computedTextClass">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Button",
  props: {
    width: String,
    height: String,
    type: {
      type: String,
      default: "default",
    },
  },
  methods: {
    handleClick() {
      this.$emit("click");
    },
  },
  computed: {
    computedStyle() {
      let style = {};
      if (this.width) style.width = this.width + "px";
      if (this.height) style.height = this.height + "px";
      if (this.type === "ok") {
        style["--nft-button-font-color"] = "#7A4CFF";
      }
      if (this.type === "sort") {
        style["--nft-button-font-size"] = "11px";
        style["--nft-button-background"] = "#7E52FF";
        style["--nft-button-padding-top"] = "6px";
      }
      return style;
    },
    computedClass() {
      let clazzes = [];
      clazzes.push("button-box-" + this.type);
      return clazzes;
    },
    computedTextClass() {
      let clazzes = [];
      clazzes.push("button-text-" + this.type);
      return clazzes;
    },
  },
};
</script>

<style lang="scss" scoped>
.button-box {
  width: 142px;
  height: 38px;
  background: #ffffff;
  border-radius: 11px;
  border: 2px solid #b082fa;
  cursor: pointer;
  .button-text {
    text-align: center;
    font-size: 18px;
    font-family: Arial-BoldMT, Arial;
    font-weight: 400;
    line-height: 38px;
    color: #6530ff;
  }
}
.button-box-ok {
  width: 264px;
  height: 36px;
  background: #b8a0fd;
  border-radius: 7px;
  border: 0px;
  .button-text-ok {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #ffffff;
    line-height: 36px;
  }
}
</style>
