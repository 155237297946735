<template>
  <div>
    <HomePageTop :defaultKey="3" top_type="browse"></HomePageTop>
    <SyntheticTable class="wallet-content" />
  </div>
</template>

<script>
import { provide } from "vue";
export default {
  setup(props, context) {
    provide("isPriority", true);
  },
};
</script>

<style lang="scss" scoped>
</style>