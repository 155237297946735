<template>
  <div class="create-item-box">
    <div>
      <div class="title-text">
        <span>Upload File</span>
        <span class="require-star-logo">&nbsp;*</span>
      </div>
      <StarUploads
        class="upload"
        :limit="1"
        :type="'image'"
        :data="{}"
        :action="''"
        :autoName="1"
        :size="5 * 1024 * 1024"
        :multiple="0"
        width="558"
        height="160"
        uploadType="collection"
        :url="nftData.item_url"
        :modalType="type"
      >
      </StarUploads>
      <div class="title-text">
        <span>Name</span>
        <span class="require-star-logo">&nbsp;*</span>
      </div>
      <input
        type="text"
        v-model="nftData.item_name"
        class="input require-input-border"
        placeholder="Item name"
        required="required"
      />
      <div class="title-text">
        <span>External link</span>
      </div>
      <input
        type="text"
        v-model="nftData.external_link"
        class="input other-input-border"
        placeholder="https://yoursite.io/item/123"
      />
    </div>
    <div class="right-info-modal" v-if="!getImageUrl">
      <div class="info-text">
        <div>Upload file to</div>
        <div>preview</div>
        <div>your brand new NFT</div>
      </div>
    </div>
    <div class="right-preview-modal" v-else>
      <div class="preview-image"><img :src="getImageUrl" /></div>
      <div class="preview-text">
        <span class="preview-name-text">{{
          nftData.item_name?.length > 19
            ? nftData.item_name.slice(0, 20) + "..."
            : nftData.item_name
        }}</span
        ><span class="preview-id-text"> #{{ this.nftData.token_id }}</span>
      </div>
      <div class="preview-icon">
        <div class="preview-icon-chain">
          <img :src="chainIcon" />
        </div>
        <div class="preview-icon-more">
          <img src="../../assets/collection_icon_more.png" />
        </div>
      </div>
    </div>
  </div>
  <div class="title-text">
    <span>Description</span>
    <div class="description-text">
      The description will be included on the item's detail page underneath its
      image. Markdown syntax is supported.
    </div>
  </div>
  <textarea
    v-model="nftData.description"
    class="description-textarea"
    placeholder="Provide a detailed description of your item."
  ></textarea>
  <div class="title-text">
    <span>Collection</span>
    <div class="description-text">
      This is the collection where your item will appear.
    </div>
  </div>
  <input
    type="text"
    class="input other-input-border"
    v-model="getNewItemCollectionData.collection_name"
    disabled
  />
  <img class="collection-down-icon" src="../../assets/icon_down@2x.png" />
  <!-- <SelectModal width="558" :height="54" type="collection" /> -->
  <div class="title-text">
    <span>Supply</span>
    <div class="description-text">
      The number of copies that can be minted. No gas cost to you! Quantities
      above one coming soon.
    </div>
  </div>
  <div class="supply-input-box">
    <input
      type="text"
      value="1"
      class="supply-input other-input-border"
      placeholder="Item name"
      required="required"
      readonly="readonly"
    />
    <DropDownBox
      width="152"
      height="56"
      type="tokenType"
      class="supply-dropdown-box"
    />
  </div>
  <div>
    <div class="blockchain-title-text">
      <span>Blockchain</span>
    </div>
    <DropDownBox width="558" :height="66" type="blockchain" disabled="true" />
  </div>
  <div class="bottom-button">
    <input
      class="create-button"
      type="submit"
      value="Create"
      @click="createNFT"
      id="submit-button"
    />
    <input
      class="sell-button"
      type="submit"
      value="Create and Sell"
      @click="remove"
    />
  </div>
</template>

<script>
import axios from "axios";
import metadata from "../../contracts/metadata/mynft.json";
import { signMessage } from "../../util/sign";
import { mapGetters } from "vuex";

export default {
  name: "AssetModal",
  data() {
    return {
      nftData: {},
      chainIcon: "",
      chains: {
        80001: {
          icon: require("../../assets/matic.jpg"),
        },
        43113: {
          icon: require("../../assets/avax.jpg"),
        },
        97: {
          icon: require("../../assets/bnb.jpg"),
        },
        5: {
          icon: require("../../assets/logo_eth19x19@2x.png"),
        },
      },
    };
  },
  props: {
    type: {
      type: String,
      default: "create",
    },
  },
  methods: {
    async mint(tokenURI) {
      if (!this.getWeb3Value.web3) return;
      const contract = new this.getWeb3Value.web3.eth.Contract(
        metadata.abi,
        this.nftData.token_address
      );
      const accounts = await this.getWeb3Value.web3.eth.getAccounts();
      const chainId = await this.getWeb3Value.web3.eth.getChainId();
      console.log("contract", contract);
      await contract.methods
        .mintNFT(accounts[0], tokenURI)
        .send({ from: accounts[0] })
        .then((res, error) => {
          if (error) console.log(error);
          else if (res) {
            if (res.status) {
              this.nftData.transactionHash = res.transactionHash;
              this.nftData.owner = accounts[0];
              this.nftData.block_chain = chainId;
              this.nftData.supply = 1;
              this.nftData.token_type = "ERC21";
              this.nftData.collection_id =
                this.getNewItemCollectionData.collection_id;
              // "3b0b2627-0f32-4722-8508-9a4dee8692fb";
            }
            console.log(res);
          }
        });
    },
    async saveData(url) {
      const signature = await signMessage(JSON.stringify(this.nftData));
      console.log(signature);
      if (!signature) return;
      axios.post(url, { ...this.nftData }).then(
        (res) => {
          if (!res.data.statusCode) {
            alert(res.data.errorMessage);
          }
          if (res.data.statusCode !== 200) {
            alert(res.data.msg);
          } else {
            alert("success");
            this.$router.push({ name: "collections" });
            // location.replace(location);
          }
          console.log(res);
        },
        (err) => alert(err)
      );
    },
    async createNFT() {
      this.nftData.item_url = this.$store.getters.getImageUrl;
      let tokenURI = {};
      this.nftData.token_address =
        this.getNewItemCollectionData.contract_address;
      let url;
      if (!this.nftData.item_url) {
        // alert("nft image cannot be empty！");
        // return;
        this.nftData.item_url =
          "https://ipfs.moralis.io:2053/ipfs/QmVC6JSidCGZsJuA7QX3BFJeaSmjVkXtAZ6whaudoatDZw";
      }
      if (!this.nftData.item_name) {
        alert("nft name cannot be empty！");
        return;
      }
      if (this.type == "create") {
        tokenURI.item_url = this.nftData.item_url;
        tokenURI.item_name = this.nftData.item_name;
        tokenURI.description = this.nftData.description;
        var obj = new FormData();
        obj.append(0, JSON.stringify(this.tokenURI));
        let config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        console.log(obj);
        const ipfsUrl = "https://ipfs.infura.io:5001/api/v0/add";
        await axios
          .post(ipfsUrl, obj, config)
          .then(
            (res) => {
              if (res.status == 200) {
                this.nftData.token_url =
                  "http://ipfs.infura.io/ipfs/" + res.data.Hash;
              }
              console.log(res);
            },
            (err) => console.log(err)
          )
          .catch((error) => {
            console.log(error);
          });

        await this.mint(tokenURI.item_url);
        if (this.nftData.transactionHash) {
          let receipt = await this.getWeb3Value.web3.eth.getTransactionReceipt(
            this.nftData.transactionHash
          );
          let log = receipt.logs[0];
          let topics = log?.topics;
          // "0x0000000000000000000000000000000000000000000000000000000000000006"
          // "0x000000000000000000000000000000000000000000000000000000000000000a"
          // this.nftData.token_id = parseInt(topics[topics.length], 10);
          if (topics) {
            this.nftData.token_id =
              this.getWeb3Value.web3.utils.hexToNumberString(
                topics[topics.length - 1]
              );
          }
          // for (let i = 0; i < receipt.logs.length; i++) {
          url =
            "https://nd9gajq3lj.execute-api.us-east-1.amazonaws.com/star/collectionItems/create";
          this.saveData(url);
        }
      } else if ((this.type = "import")) {
        url =
          "https://nd9gajq3lj.execute-api.us-east-1.amazonaws.com/star/collectionItems/create";
        this.saveData(url);
      }
    },
    async verifyContractAddress(contractAddress, tokenId) {
      let contract;
      let address;
      try {
        contract = new this.getWeb3Value.web3.eth.Contract(
          metadata.abi,
          contractAddress
        );
        address = await contract.methods.ownerOf(tokenId).call();
        console.log("contract", contract, address);
      } catch (err) {
        this.$router.push({ name: "nFound" });
      }
      const accounts = await this.getWeb3Value.web3.eth.getAccounts();
      const chainId = await this.getWeb3Value.web3.eth.getChainId();
      if (accounts[0].toLowerCase() !== address.toLowerCase()) {
        this.$router.push({ name: "nFound" });
      }
      this.nftData.item_name = await contract.methods.name().call();
      const url = await contract.methods.tokenURI(tokenId).call();
      debugger;
      // const url =
      //   "https://ipfs.infura.io/ipfs/QmeHSpyg39JPJkqCLoAJPAVGJ9gGZ8Bjo4W8Msaa47iPKR";
      axios.get(url).then(
        (res) => {
          if (res.status == 200) {
            if (!res.data.name || res.data.name == "") {
              this.nftData.item_url = url;
              return;
            }
            this.nftData.item_url = res.data.item_url;
            this.nftData.description = res.data;
          }
          console.log(res);
        },
        (err) => {
          console.log(err);
        }
      );
    },
  },
  computed: {
    ...mapGetters([
      "getNewItemCollectionData",
      "getImageUrl",
      "getWeb3Value",
      "getCollectionBlockChain",
      "getImportNFTBlockChain",
    ]),
  },
  watch: {
    getWeb3Value() {
      if (this.type !== "create") {
        this.verifyContractAddress(
          this.$route.params.address,
          this.$route.params.id
        );
      }
    },
  },
  created() {
    if (this.type !== "create") {
      this.verifyContractAddress(
        this.$route.params.address,
        this.$route.params.id
      );
    }
    const chainId = this.getNewItemCollectionData.block_chain
      ? this.getNewItemCollectionData.block_chain
      : "97";

    this.chainIcon = this.chains[chainId].icon;
  },
};
</script>

<style lang="scss" scoped>
.create-item-box {
  display: flex;
  width: 928px;
  justify-content: space-between;
  .right-info-modal {
    width: 339px;
    height: 484px;
    border-radius: 10px;
    border: 1px solid #dedede;
    .info-text {
      width: 204px;
      height: 90px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 30px;
      text-align: center;
      position: relative;
      margin: auto;
      top: 200px;
    }
  }
  .right-preview-modal {
    width: 272px;
    height: 370px;
    border-radius: 8px;
    border: 1px solid #dedede;
    .preview-image {
      width: 272px;
      height: 272px;
      border-radius: 8px;
      img {
        width: 272px;
        height: 272px;
        border-radius: 8px;
        object-fit: contain;
      }
    }
    .preview-text {
      width: 248px;
      text-align: center;
      margin: 5px 12px 12px 12px;
      height: 51px;
      font-size: 17px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 26px;
      // border: 1px solid rgba(227, 39, 39, 0.32);
      .preview-name-text {
      }
      .preview-id-text {
        color: #666666;
      }
    }
    .preview-text:after {
      content: "";
      height: 1px;
      width: 272px;
      display: block;
      margin-left: -12px;
      margin-top: 25px;
      background: rgba(222, 222, 222, 0.32);
    }
    .preview-icon {
      display: flex;
      justify-content: space-between;
      width: 248px;
      margin: 0 auto;
      // border: 1px solid red;
      .preview-icon-chain {
        img {
          width: 19px;
          height: 19px;
        }
      }
      .preview-icon-more {
        margin-top: -6px;
        img {
          width: 20px;
          height: 5px;
        }
      }
    }
  }
}
.input {
  position: relative;
  outline: 0;
  width: 526px;
  height: 54px;
  border-radius: 10px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 28px;
  padding-left: 32px;
}
.supply-input-box {
  display: flex;
  .supply-input {
    outline: 0;
    width: 390px;
    height: 54px;
    border-radius: 10px 0 0px 10px;
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 28px;
    padding-left: 16px;
    border-right: none;
    // border: 1px solid red;
  }
  .supply-dropdown-box {
    // left: 411px;
  }
}

.collection-down-icon {
  position: relative;
  bottom: 2px;
  right: 32px;
  width: 12px;
  height: 8px;
}
.other-input-border {
  border: 1px solid #dedede;
}

.require-input-border {
  border: 1px solid #999999;
}
.description-textarea {
  outline: 0;
  width: 484px;
  height: 100px;
  border-radius: 10px;
  border: 1px solid #dedede;
  padding: 14px 42px 0 32px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.title-text {
  margin: 25px 0 15px 0;
  .require-star-logo {
    color: rgba(217, 70, 70, 1);
  }
  .description-text {
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #999999;
    margin: 3px 0 5px 0;
  }
}
.blockchain-title-text {
  margin: 25px 0 15px 0;
}
.bottom-button {
  margin-top: 30px;
  display: flex;
  width: 518px;
  justify-content: space-between;
  padding-bottom: 100px;
  // border-radius: 10px;
  .create-button {
    cursor: pointer;
    width: 244px;
    height: 52px;
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    background: linear-gradient(315deg, #7c42fc 0%, #6159fc 100%, #6159fc 100%);
    // background: #b8a0fd;
    border-radius: 10px;
    border: 2px solid #6159fc;
    //   border-color: #6159fc;
  }
  .sell-button {
    width: 244px;
    height: 52px;
    border-radius: 10px;
    border: 2px solid rgba(176, 130, 250, 1);
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #7449fc;
    background: #fff;
  }
}
</style>