<template>
  <div class="modal" :style="computedStyle" :class="computedClass">
    <div class="modal-head" v-show="showModalHead">
      <div class="modal-title">Preview</div>
      <div class="modal-close-icon" @click="$emit('close')">
        <img src="../assets/preview_icon_close.png" />
      </div>
    </div>
    <SearchBox
      :heigth="24"
      class="search-box"
      v-show="showSearchBox"
    ></SearchBox>
    <div class="modal-content">
      <div class="content-top">
        <div class="content-title">
          <slot name="content-title"></slot>
        </div>
        <div class="">
          <slot name="content-icon"></slot>
        </div>
      </div>
      <div class="content-container" :class="computedContainerClass">
        <slot name="content-container"></slot>
      </div>
      <div
        class="left-move-btn"
        :style="computedLeftBtnStyle"
        v-show="showLeftBtn"
        @click="$emit('leftMove')"
      >
        <img src="../assets/preview_btn_left.png" />
      </div>
      <div class="drag-info" v-show="showDragInfo">
        <div class="drag-text" v-show="showDragText">
          <img src="../assets/preview_icon_sort.png" class="sort-img" />
          <span>You can drag to sort</span>
        </div>
      </div>
    </div>
    <div class="modal-footer" v-show="showFooterBtn" @click="$emit('save')">
      <div class="modal-footer-btn" :style="computedFooterBtnStyle">
        <div class="footer-btn">Save</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShowcaseModal",
  props: {
    width: String,
    height: String,
    showModalHead: {
      type: Boolean,
      default: true,
    },
    showSearchBox: {
      type: Boolean,
      default: false,
    },
    showDragInfo: {
      type: Boolean,
      default: true,
    },
    classType: {
      type: String,
      default: "default",
    },
    isOverflow: {
      type: Number,
      default: 0,
    },
    showLeftBtn: {
      type: Boolean,
      default: false,
    },
    showFooterBtn: {
      type: Boolean,
      default: true,
    },
    enableSaveBtn: {
      type: Boolean,
      default: false,
    },
    showDragText: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    computedStyle() {
      let style = {};
      if (this.width) {
        style.width = this.width + "px";
      }
      if (this.height) {
        style.height = this.height + "px";
      }
      if (this.classType === "collected") {
        style["--modal-background-color"] = "#FBFAFD";
        style["--modal-content-height"] = "0px";
        if (this.isOverflow >= 25) {
          style["--modal-content-height"] = "283px";
        }
        style["--modal-content-width"] = "312px";
        style["--modal-content-top-height"] = "27px";
      }
      return style;
    },
    computedLeftBtnStyle() {
      let style = {};
      if (this.classType === "collected" && this.isOverflow > 0) {
        if (this.isOverflow <= 6) {
          // style["--left-margin-top"] = 162 + "px";
          style["--left-margin-top"] = 77 + "px";
        } else if (this.isOverflow <= 12) {
          style["--left-margin-top"] = 134 + "px";
        } else if (this.isOverflow <= 18) {
          style["--left-margin-top"] = 191 + "px";
        } else if (this.isOverflow <= 24) {
          style["--left-margin-top"] = 248 + "px";
          // style["--left-margin-top"] = 305 + "px";
        }
      }
      return style;
    },
    computedFooterBtnStyle() {
      let style = {};
      if (this.enableSaveBtn) {
        style["--modal-footer-btn-bgcolor"] = "#6530ff";
      }
      return style;
    },
    computedClass() {
      let clazzes = [];
      if (this.classType === "collected") {
        clazzes.push("modal-" + this.classType);
      }
      return clazzes;
    },
    computedContainerClass() {
      let clazzes = [];
      // debugger;
      if (this.classType === "collected" && this.isOverflow >= 25) {
        clazzes.push("content-container-" + this.classType);
      }
      return clazzes;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  width: var(--modal-width);
  border-radius: 7px;
  background: var(--modal-background-color);
  .modal-head {
    height: 42px;
    // height: 8.5%;
    border-bottom: solid 1px #dedede;
    display: flex;
    .modal-title {
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 42px;
      // margin-left: 163px;
      margin-left: 41%;
    }
    .modal-close-icon {
      margin: 15px 0 0px 129px;
      cursor: pointer;
      img {
        width: 11.9px;
        height: 11.9px;
      }
    }
  }
  .search-box {
    margin: 15px 0 0 21px;
  }
  .modal-content {
    text-align: left;
    // height: 582px;
    .content-top {
      margin: 3px 0 0 21px;
      display: flex;
      justify-content: space-between;
      width: var(--modal-content-width);
      // border: 1px solid green;
      height: var(--modal-content-top-height);
      .content-title {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 31px;
      }
    }
    .content-container {
      height: var(--modal-content-height);
      margin: 3px 19.5px 0px 19.5px;
      // border: 1px solid red;
      max-height: 344px;
      // overflow-y: auto;
    }
    .content-container-collected {
      overflow-y: auto;
    }
    .content-container::-webkit-scrollbar {
      width: 4px;
      height: 63px;
      background-color: #f5f5f5;
      // display: none;
      /* Chrome Safari */
    }
    .content-container::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      background-color: #f5f5f5;
      border-radius: 3px;
    }
    .content-container::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #fbfafd;
    }
    .left-move-btn {
      margin-top: var(--left-margin-top);
      margin-left: 13px;
      cursor: pointer;
      img {
        height: 63px;
        width: 63px;
      }
    }
    .drag-info {
      height: 20px;
      padding: 0px 0 0px 18px;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #666666;
      border-bottom: 1px solid #dedede;
      background: #fbfafd;
      .drag-text {
        line-height: 20px;
      }
      .drag-text img {
        width: 10px;
        height: 10px;
        margin: 5px 4px 0 0;
      }
    }
  }
  .modal-footer {
    display: flex;
    justify-content: center;
    margin-top: 9px;
    .modal-footer-btn {
      text-align: center;
      width: 252px;
      height: 28px;
      background: var(--modal-footer-btn-bgcolor);
      border-radius: 7px;
      cursor: pointer;
      .footer-btn {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
      }
    }
  }
}
.modal-collected {
  border: 1px solid #dedede;
}
</style>